import { Instrument, InstrumentZone } from 'midi-city-api'

function zonesForMidiNote(
  midiNote: number,
  instrument: Instrument
): InstrumentZone[] {
  return instrument.zones.filter(
    (zone): boolean =>
      zone.sampleId !== undefined &&
      (zone.keyRange === undefined ||
        (midiNote >= zone.keyRange.lo && midiNote <= zone.keyRange.hi))
  )
}

export default zonesForMidiNote
