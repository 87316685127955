import { LayoutRectangle, KeyboardPosition } from 'midi-city-shared-types'

import {
  PhysicalKeyboardLayout as PhysicalKeyboardLayoutT,
  PhysicalKeyboardKeyMapping as PhysicalKeyboardKeyMappingT
} from './physical-keyboard-layout'

import { getKeyWidth } from './keyboard/key'

export const KEYBOARD_LAYOUT_PHYSICAL_ROW_COUNT = new Map<
  KeyboardLayoutStyle,
  number
>([
  [0, 1],
  [1, 2],
  [2, 4]
])

export const KEYBOARD_LAYOUT_VIRTUAL_ROW_COUNT = new Map<
  KeyboardLayoutStyle,
  number
>([
  [0, 1],
  [1, 2],
  [2, 2]
])

export function getKeyWidthUniversal(
  layout: LayoutRectangle,
  layoutStyle: KeyboardLayoutStyle
): number {
  return getKeyWidth(0, layout, layoutStyle)
}

export type PhysicalKeyboardKeyMapping = PhysicalKeyboardKeyMappingT
export type PhysicalKeyboardLayout = PhysicalKeyboardLayoutT

export type KeyboardLayoutMapping = Record<
  KeyboardPosition | string,
  KeyboardLayoutStyle
>

export enum KeyboardLayoutStyle {
  Single,
  Double,
  Quad
}

export {
  getKeyRowSlot,
  getKeyWidth,
  getKeyRow,
  keyGutterWidth,
  getKeyLayout
} from './keyboard/key'

export { PHYSICAL_LAYOUT } from './physical-keyboard-layout'

export { default as getKeyboardPhysicalKeyRows } from './get-keyboard-physical-key-rows'

export { default as getKeyboardKeyShortcut } from './get-key-shortcut'

export { default as getPhysicalRowsToBeUsed } from './get-physical-rows-to-be-used'

export * from './keyboard'
