export interface PhysicalKeyboardLayout
  extends Array<PhysicalKeyboardKeyMapping[]> {
  [row: number]: PhysicalKeyboardKeyMapping[]
}

export interface PhysicalKeyboardKeyMapping {
  label: string
  code: string
}

export const PHYSICAL_LAYOUT: PhysicalKeyboardLayout = [
  [
    { label: '1', code: 'Digit1' },
    { label: '2', code: 'Digit2' },
    { label: '3', code: 'Digit3' },
    { label: '4', code: 'Digit4' },
    { label: '5', code: 'Digit5' },
    { label: '6', code: 'Digit6' },
    { label: '7', code: 'Digit7' },
    { label: '8', code: 'Digit8' },
    { label: '9', code: 'Digit9' },
    { label: '0', code: 'Digit0' }
  ],
  [
    { label: 'q', code: 'KeyQ' },
    { label: 'w', code: 'KeyW' },
    { label: 'e', code: 'KeyE' },
    { label: 'r', code: 'KeyR' },
    { label: 't', code: 'KeyT' },
    { label: 'y', code: 'KeyY' },
    { label: 'u', code: 'KeyU' },
    { label: 'i', code: 'KeyI' },
    { label: 'o', code: 'KeyO' },
    { label: 'p', code: 'KeyP' }
  ],
  [
    { label: 'a', code: 'KeyA' },
    { label: 's', code: 'KeyS' },
    { label: 'd', code: 'KeyD' },
    { label: 'f', code: 'KeyF' },
    { label: 'g', code: 'KeyG' },
    { label: 'h', code: 'KeyH' },
    { label: 'j', code: 'KeyJ' },
    { label: 'k', code: 'KeyK' },
    { label: 'l', code: 'KeyL' },
    { label: ';', code: 'Semicolon' }
  ],
  [
    { label: 'z', code: 'KeyZ' },
    { label: 'x', code: 'KeyX' },
    { label: 'c', code: 'KeyC' },
    { label: 'v', code: 'KeyV' },
    { label: 'b', code: 'KeyB' },
    { label: 'n', code: 'KeyN' },
    { label: 'm', code: 'KeyM' },
    { label: ',', code: 'Comma' },
    { label: '.', code: 'Period' },
    { label: '/', code: 'Slash' }
  ]
]
