import { assertIsObject } from 'assertate'
import { ActionFunction } from 'xstate'
import { choose, pure, sendParent } from 'xstate/lib/actions'

import { Context, Events } from '../types'
import { Tick } from 'midi-city-shared-types'
import { getEventMatchingTrack } from '../utils'

type EventsMatching =
  | Events.ChannelNoteStartScheduled
  | Events.ChannelNoteReleaseScheduled

const forwardNoteEventToTrack: ActionFunction<Context, Events.All> = (
  context,
  event
): void => {
  event = event as EventsMatching
  const {
    midiNote,
    audioContext,
    timeContextScheduled,
    type: eventType
  } = event

  const track = getEventMatchingTrack(context, event)

  assertIsObject(track)

  const tick = audioContext.transport.getTicksAtTime(
    timeContextScheduled
  ) as Tick

  // FIXME use velocity
  track.trackSource.send({
    type:
      eventType === 'GLOBAL_CHANNEL_NOTE_START_SCHEDULED'
        ? 'SCHEDULE_NOTE_START'
        : 'SCHEDULE_NOTE_RELEASE',
    midiNote,
    velocity: 1,
    tick
  })
}

const emitRecordingEvent = pure<Context, Events.All>((_context, event) => {
  const {
    type,
    midiNote,
    timeContextScheduled,
    channelNumber
  } = event as EventsMatching

  const noteRecordingEvent:
    | Events.EmittedChannelNoteRecordingStarted
    | Events.EmittedChannelNoteRecordingReleased = {
    type:
      type === 'GLOBAL_CHANNEL_NOTE_START_SCHEDULED'
        ? 'CHANNEL_NOTE_RECORDING_STARTED'
        : 'CHANNEL_NOTE_RECORDING_RELEASED',
    midiNote,
    channelNumber,
    timeContext: timeContextScheduled
  }

  return sendParent(noteRecordingEvent)
})

const processNoteScheduledEvent = choose<Context, Events.All>([
  {
    cond: 'isNonScheduledEvent',
    actions: [forwardNoteEventToTrack, emitRecordingEvent]
  }
])

export { processNoteScheduledEvent }
