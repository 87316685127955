import moize from 'moize'
import { MidiResponse } from 'midi-city-api'
import fetch from 'isomorphic-fetch'

async function getMidis(urlBase: string): Promise<MidiResponse[]> {
  const response = await fetch(`${urlBase}/midis`)
  const json: MidiResponse[] = await response.json()
  return json
}

export default moize(getMidis, { isPromise: true })
