import { retry } from 'ts-retry-promise'
import { context } from 'tone'

import { Sample } from 'midi-city-api'
import { BufferFetchError } from '../../../errors'

export async function getSampleAudioBuffer(
  sample: Sample,
  urlBase: string
): Promise<AudioBuffer> {
  const url = `${urlBase}${sample.sampleUrl}`
  try {
    const response = await retry(
      async (): Promise<Response> => await fetch(url),
      {
        retries: 3,
        backoff: 'EXPONENTIAL'
      }
    )
    const arrayBuffer = await response.arrayBuffer()
    const audioBuffer = await context.decodeAudioData(arrayBuffer)
    return audioBuffer
  } catch (e) {
    throw new BufferFetchError(url, e)
  }
}
