import { InstrumentZone, PresetGeneratorMap } from 'midi-city-api'
import { Gain, Context as AudioContext } from 'tone'

import stackValues from '../../../stack-values'

export function getPresetZoneReverbGain(
  presetZone: PresetGeneratorMap,
  instrumentZone: InstrumentZone,
  context: AudioContext
): Gain {
  const reverbValue = stackValues(
    'reverbEffectsSend',
    presetZone,
    instrumentZone
  )

  const wet = reverbValue / 1000
  const gain = new Gain({ gain: wet, context })
  return gain
}
