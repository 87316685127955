import { assertIsObject } from 'assertate'
import { ChannelT } from 'packages/shared-types'
import { Context as ToneAudioContext } from 'tone'
import {
  Context,
  RecordingChannelLoadState,
  RecordingChannelState
} from '../types'

export function getEventMatchingTrack<
  T extends {
    audioContext: ToneAudioContext
    channelNumber: ChannelT
  }
>(
  { audioContextChannelMap }: Context,
  event: T
): RecordingChannelState | undefined {
  const { audioContext, channelNumber } = event

  assertIsObject(audioContextChannelMap)

  const channelMap = audioContextChannelMap.get(audioContext)

  assertIsObject(channelMap)

  const track = channelMap.get(channelNumber)

  return track
}

export function getIsAudioContextLoadingAnyTrack(
  ctx: Context,
  audioContext: ToneAudioContext
): boolean {
  const { audioContextChannelMap } = ctx
  assertIsObject(audioContextChannelMap)

  const channelMap = audioContextChannelMap.get(audioContext)
  assertIsObject(channelMap)

  return Array.from(channelMap).some(([_channel, channelState]) => {
    return channelState.loadState === RecordingChannelLoadState.Loading
  })
}

export function getIsLoadingAnyTrack(ctx: Context): boolean {
  const { audioContextChannelMap } = ctx
  assertIsObject(audioContextChannelMap)

  return Array.from(audioContextChannelMap).some(([audioContext]) => {
    return getIsAudioContextLoadingAnyTrack(ctx, audioContext)
  })
}
