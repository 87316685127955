import type { Events as GlobalEvents } from 'midi-city-sound-engine'

import type { Events as TrackManagerEvents } from '../../midi-track-manager'
import type { MidiNote, ChannelT } from 'midi-city-shared-types'
import { Context as AudioContext } from 'tone'

export type ChannelNoteStartScheduled = GlobalEvents.EmittedChannelNoteStartScheduled

export type ChannelNoteReleaseScheduled = GlobalEvents.EmittedChannelNoteReleaseScheduled

export interface Initialize {
  type: 'INITIALIZE'
  audioContext: AudioContext
}

export interface FetchAllRequest {
  type: 'FETCH_ALL_REQUEST'
}

export enum LoadRequestType {
  Index,
  HeaderName
}
export type LoadRequest = { type: 'LOAD_SOURCE_REQUEST' } & (
  | {
      identifier: LoadRequestType.Index
      id: number
    }
  | {
      identifier?: LoadRequestType.HeaderName
      id: string
    }
)

export interface StartRequest {
  type: 'START_REQUEST'
}

export interface StopRequest {
  type: 'STOP_REQUEST'
}

export interface RecordRequest {
  type: 'RECORD_REQUEST'
}

export interface BpmChange {
  type: 'BPM_CHANGE_REQUEST'
  value: number
}

export interface DisposeRequest {
  type: 'DISPOSE_REQUEST'
}

export type All =
  | Initialize
  | FetchAllRequest
  | BpmChange
  | LoadRequest
  | StartRequest
  | StopRequest
  | RecordRequest
  | GlobalEvents.EmittedChannelInitialized
  | GlobalEvents.EmittedChannelLoadSuccess
  | GlobalEvents.EmittedChannelLoading
  | GlobalEvents.EmittedChannelNoteStartScheduled
  | GlobalEvents.EmittedChannelNoteReleaseScheduled
  | GlobalEvents.ChannelNoteStart
  | GlobalEvents.ChannelNoteRelease
  | TrackManagerEvents.EmittedEvent
  | DisposeRequest

export interface EmittedFetched {
  type: 'MIDIS_FETCHED'
}

export interface EmittedStarted {
  type: 'TRACKS_MANAGER_STARTED'
}

export interface EmittedStopped {
  type: 'TRACKS_MANAGER_STOPPED'
}

export interface EmittedChannelNoteRecordingStarted {
  type: 'CHANNEL_NOTE_RECORDING_STARTED'
  midiNote: MidiNote
  timeContext: number
  channelNumber: ChannelT
}

export interface EmittedChannelNoteRecordingReleased {
  type: 'CHANNEL_NOTE_RECORDING_RELEASED'
  midiNote: MidiNote
  timeContext: number
  channelNumber: ChannelT
}

export type EmittedPartLoopEnd = TrackManagerEvents.EmittedPartLoopEnd

export type Emitted =
  | EmittedFetched
  | EmittedChannelNoteRecordingStarted
  | EmittedChannelNoteRecordingReleased
  | TrackManagerEvents.EmittedEvent
  | EmittedStarted
  | EmittedStopped
