import { ShortcutManagerState } from '../shortcut-manager-machine'
import { KeyboardKeyMachineActor } from '../keyboard-key-machine'
import * as selectors from '../selectors'

export function getKeys(
  state: ShortcutManagerState
): KeyboardKeyMachineActor[] {
  let keys: KeyboardKeyMachineActor[] = []
  state.context.keyboards.forEach(
    keyboard => (keys = keys.concat(selectors.getKeyboardKeys(keyboard.state)))
  )
  return keys
}

export function getShortcutManagerCodeKey(
  code: string,
  state: ShortcutManagerState
): KeyboardKeyMachineActor | undefined {
  return getKeys(state).find(key => key.state.context.shortcut?.code === code)
}
