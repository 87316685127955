import {
  ActionFunctionMap,
  assign,
  send,
  spawn,
  sendParent,
  SendAction
} from 'xstate'
import { pure } from 'xstate/lib/actions'
import { assign as assignImmer } from '@xstate/immer'
import { Midi } from '@tonejs/midi'

import { Events as GlobalEvents } from 'midi-city-sound-engine'
import { ChannelT, MIDI_CHANNELS } from 'midi-city-shared-types'

import { assertIsArray, assertIsObject } from 'assertate'

import {
  Events,
  Context,
  RecordingChannelLoadState,
  ChannelMap
} from '../types'

import * as TrackManager from '../../midi-track-manager'
import { processNoteScheduledEvent } from './process-note-scheduled-event'
import { getEventMatchingTrack } from '../utils'
import { MIDI_FILE_INDEX_DEFAULT, PPQ } from '..'
import { LoadRequestType } from '../types/events'

const getSelectedMidi = ({ idSelected, midis }: Context): Midi | undefined => {
  if (idSelected === undefined || midis === undefined) {
    return undefined
  }
  const midiJson = midis.find(({ header }) => header.name === idSelected)
  assertIsObject(midiJson)

  const midiFile = new Midi()
  midiFile.fromJSON(midiJson)
  return midiFile
}

const actions: ActionFunctionMap<Context, Events.All> = {
  initialize: assignImmer((ctx, event) => {
    const { audioContext } = event as Events.Initialize

    ctx.audioContextChannelMap = new Map()

    const audioContextChannelMapping: ChannelMap = new Map()

    ctx.audioContextChannelMap.set(audioContext, audioContextChannelMapping)

    MIDI_CHANNELS.forEach(channelNumber => {
      audioContextChannelMapping.set(channelNumber, {
        loadState: RecordingChannelLoadState.Unloaded,
        trackSource: spawn(
          TrackManager.machine.withContext({
            channelNumber,
            audioContext,
            trackType: TrackManager.TrackType.Source,
            tickMultiplier: 1,
            quarterNotes: 8
          })
        ) as TrackManager.Actor,
        trackMirror: spawn(
          TrackManager.machine.withContext({
            channelNumber,
            audioContext,
            trackType: TrackManager.TrackType.Mirror,
            tickMultiplier: 1,
            quarterNotes: 8
          })
        ) as TrackManager.Actor
      })
    })
  }),

  transportStart: ({ audioContextChannelMap }): void => {
    assertIsObject(audioContextChannelMap)
    Array.from(audioContextChannelMap.keys()).forEach(audioContext => {
      if (audioContext.state === 'suspended') {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        audioContext.resume()
      }
      audioContext.transport.start()
    })
  },

  transportStop: ({ audioContextChannelMap }): void => {
    assertIsObject(audioContextChannelMap)
    Array.from(audioContextChannelMap.keys()).forEach(audioContext => {
      audioContext.transport.stop()
    })
  },

  autoplayEnable: assign({
    autoplay: _ctx => true
  }),

  autoplayDisable: assign({
    autoplay: _ctx => false
  }),

  emitStarted: sendParent(
    (): Events.EmittedStarted => ({ type: 'TRACKS_MANAGER_STARTED' })
  ),

  emitStopped: sendParent(
    (): Events.EmittedStopped => ({ type: 'TRACKS_MANAGER_STOPPED' })
  ),

  processNoteScheduledEvent,

  channelLoadedStateUpdate: assignImmer((ctx, event) => {
    if (
      event.type !== 'TRACK_MANAGER_LOADING' &&
      event.type !== 'TRACK_MANAGER_LOAD_SUCCESS'
    ) {
      throw new Error('channelLoadedState called for bad event')
    }
    const { channelNumber, audioContext } = event

    const channelMap = ctx.audioContextChannelMap?.get(audioContext)
    assertIsObject(channelMap)

    const channelState = channelMap.get(channelNumber)
    assertIsObject(channelState)

    channelState.loadState =
      event.type === 'TRACK_MANAGER_LOADING'
        ? RecordingChannelLoadState.Loading
        : RecordingChannelLoadState.Loaded
  }),

  channelsReleaseAll: pure((context, event) => {
    const { audioContextChannelMap } = context
    assertIsObject(audioContextChannelMap)

    const events: Array<
      SendAction<typeof context, typeof event, GlobalEvents.ChannelReleaseAll>
    > = []

    audioContextChannelMap.forEach((channelMapping, audioContext) => {
      channelMapping.forEach((_trackState, channelNumber) => {
        events.push(
          sendParent({
            type: 'CHANNEL_RELEASE_ALL_REQUEST',
            channelNumber: channelNumber,
            audioContext
          })
        )
      })
    })

    return events
  }),

  audioContextsSetup: (context): void => {
    const { audioContextChannelMap, bpm, duration } = context
    assertIsObject(audioContextChannelMap)

    audioContextChannelMap.forEach((_key, audioContext) => {
      const { transport } = audioContext
      transport.PPQ = PPQ
      transport.bpm.value = bpm
      transport.loopEnd = duration
      transport.loop = true
    })
  },

  trackManagersLoadSource: (context): void => {
    const midiFile = getSelectedMidi(context)
    const { audioContextChannelMap } = context

    assertIsObject(audioContextChannelMap)
    assertIsObject(midiFile)

    const filePPQ = midiFile.header.ppq

    const ppqMultiplier = PPQ / filePPQ
    const tickMultiplier = ppqMultiplier

    const transportLoopEndTicks = Math.max(
      ...midiFile.tracks.map(track => track.endOfTrackTicks ?? 0)
    )

    const transportLoopEnd = `${transportLoopEndTicks}i`

    midiFile.tracks.forEach(track => {
      const channel = (track.channel + 1) as ChannelT

      // TODO this should be checking offline vs online
      audioContextChannelMap.forEach((channelMap, audioContext) => {
        audioContext.transport.loopEnd = transportLoopEnd
        const trackState = channelMap.get(channel)
        assertIsObject(trackState)

        trackState.trackSource.send({
          type: 'LOAD_SOURCE_REQUEST',
          track,
          tickMultiplier
        })
      })
    })
  },

  midisAssign: assign({
    midis: (_ctx, event) => ((event as unknown) as { data: Midi[] }).data
  }),

  idSelectedAssign: assign({
    idSelected: ({ midis }, event) => {
      const { id, identifier } = event as Events.LoadRequest

      let headerName: string

      if (identifier === LoadRequestType.Index) {
        assertIsArray(midis)
        headerName = midis[id as number].header.name
      } else {
        headerName = (id as unknown) as string
      }

      return headerName
    }
  }),

  // self-loading a default file
  loadInitial: send(({ midis, midiFileIndexInitial }, _event) => {
    assertIsArray(midis)
    return {
      type: 'LOAD_SOURCE_REQUEST',
      id: midis[midiFileIndexInitial ?? MIDI_FILE_INDEX_DEFAULT].header.name
    }
  }),

  tracksStart: ({ audioContextChannelMap }): void => {
    audioContextChannelMap?.forEach(channelMap => {
      channelMap.forEach(({ trackSource: manager }) => {
        manager.send({ type: 'START_REQUEST' })
      })
    })
  },

  tracksStop: ({ audioContextChannelMap }): void => {
    audioContextChannelMap?.forEach(channelMap => {
      channelMap.forEach(({ trackSource: manager }) => {
        manager.send({ type: 'STOP_REQUEST' })
      })
    })
  },

  fwdChannelEvent: (context, event): void => {
    if (
      event.type !== 'GLOBAL_CHANNEL_LOADING' &&
      event.type !== 'GLOBAL_CHANNEL_LOADED'
    ) {
      throw new Error('Unsupported Channel Event')
    }
    const { keyRange, velocityRange } = event

    const track = getEventMatchingTrack(context, event)

    assertIsObject(track)

    track.trackSource.send({
      type:
        event.type === 'GLOBAL_CHANNEL_LOADED'
          ? 'CHANNEL_LOAD_SUCCESS'
          : 'CHANNEL_LOADING',
      keyRange,
      velocityRange
    })
  }
}

export default actions
