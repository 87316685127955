import moize from 'moize'
import { DefaultContext, EventObject, ActionMeta, State } from 'xstate'
import log, { Logger } from 'loglevel'
import { assertIsObject, assertIsString } from 'assertate'
import * as Sentry from '@sentry/browser'
import logLevelPrefix from 'loglevel-plugin-prefix'

logLevelPrefix.reg(log)
logLevelPrefix.apply(log, {
  format: (_level, name, _timestamp) => {
    return `${name ?? ''}: `
  }
})

log.setLevel('info')

function getNamespaceId<
  StateT extends State<any, any, any>,
  MetaT extends { state: StateT }
>(meta: MetaT): string {
  const parentState = meta.state.configuration.find(
    node => node.parent === undefined
  )

  assertIsObject(parentState)

  const { config } = parentState

  assertIsObject(config)

  const { id } = config

  assertIsString(id)
  return id
}

const getNamespaceFromId = moize(
  (id: string): Logger => {
    return log.getLogger(id)
  }
)

export function getNamespace<
  StateT extends State<any, any, any>,
  MetaT extends { state: StateT }
>(meta: MetaT): Logger {
  return getNamespaceFromId(getNamespaceId(meta))
}

export function logAction<
  ContextT extends DefaultContext,
  EventT extends EventObject,
  MetaT extends ActionMeta<ContextT, EventT>
>(_context: ContextT, event: EventT, meta: MetaT): void {
  const namespace = getNamespace(meta)
  namespace.debug(`event=${event.type}`)
}

export function logState<
  ContextT extends DefaultContext,
  EventT extends EventObject,
  MetaT extends ActionMeta<ContextT, EventT>
>(_context: ContextT, _event: EventT, meta: MetaT): void {
  const namespace = getNamespace(meta)
  namespace.debug('state', meta.state.value)
}

export function unexpectedAction<
  ContextT extends DefaultContext,
  EventT extends EventObject,
  MetaT extends ActionMeta<ContextT, EventT>
>(_context: ContextT, event: EventT, meta: MetaT): void {
  // ignore missed done.invoke event, especially spawned ones
  if (/^done\.invoke\./.test(event.type)) {
    return
  }

  const namespaceId = getNamespaceId(meta)
  const namespace = getNamespace(meta)

  const error = new Error(
    `Unexpected event: machine=${namespaceId} state=${JSON.stringify(
      meta.state.value
    )} event=${event.type}`
  )

  // @ts-expect-error
  error.state = meta.state.value

  Sentry.withScope(scope => {
    scope.setLevel(Sentry.Severity.Warning)
    Sentry.captureException(error)
  })

  if (process.env.NODE_ENV !== 'production') {
    throw error
  }

  namespace.warn(error, { state: meta.state.value })
}

export function ignoredAction<
  ContextT extends DefaultContext,
  EventT extends EventObject,
  MetaT extends ActionMeta<ContextT, EventT>
>(_context: ContextT, event: EventT, meta: MetaT): void {
  const namespace = getNamespace(meta)
  namespace.debug('ignored', `event=${event.type}`)
}
