import { Machine } from 'xstate'
import { assign } from '@xstate/immer'
import {
  ChannelT,
  CHANNEL_NUMBER_PERCUSSION_DEFAULT,
  CHANNEL_NUMBER_MELODIC_DEFAULT
} from 'midi-city-shared-types'

import { ChannelLoadingState, Schema, Events, AppMachineContext } from './types'

import states from './states'
import actions from './actions'
import { ignoredAction, unexpectedAction } from 'midi-city-xstate-utils'
import { every } from 'lodash-es'
import { Events as GlobalEvents } from 'midi-city-sound-engine'
import { assertIsObject } from 'assertate'
import { choose, forwardTo } from 'xstate/lib/actions'
import type { PageChangeExternal } from './events'
import { castDraft } from 'immer'

export * from '../selectors'
export * from './types'

export const CHANNEL_NUMBERS = [
  CHANNEL_NUMBER_PERCUSSION_DEFAULT,
  CHANNEL_NUMBER_MELODIC_DEFAULT
] as ChannelT[]

export const CHANNEL_NUMBERS_MOBILE = [
  CHANNEL_NUMBER_MELODIC_DEFAULT
] as ChannelT[]

export const TRANSACTION_INIT_ID = 'app-manager-init'

const machine = Machine<AppMachineContext, Schema, Events.All>(
  {
    id: 'app-manager',

    strict: true,

    initial: 'uninitialized',

    context: {
      recordingAvailable: false,
      menuOpen: undefined,
      modalOpen: undefined,
      page: '/'
    },
    on: {
      DISPOSE_REQUEST: {
        target: 'disposed'
      },

      CHANNEL_INITIALIZED: {
        actions: ignoredAction
      },

      TRACK_MANAGER_INITIALIZED: {
        actions: [forwardTo('midi-device-manager')]
      },

      // this should probably be put directly in states that we expect it in
      // e.g. midi-device-manager isn't guaranteed to exist
      TRACK_MANAGER_DURATION_UPDATED: {
        actions: [forwardTo('midi-device-manager')]
      },

      ADS_UPDATE: {
        actions: assign((ctx, event) => {
          ctx.ads = castDraft(Object.freeze(event.ads))
        })
      },

      GLOBAL_SETTINGS_UPDATE: {
        actions: assign((ctx, event) => {
          ctx.globalSettings = Object.freeze(event.globalSettings)
        })
      },

      PAGE_CHANGE: {
        actions: ignoredAction
      },

      PAGE_CHANGE_EXTERNAL: {
        actions: choose<AppMachineContext, PageChangeExternal>([
          {
            actions: [
              assign((ctx, { page }) => {
                ctx.modalOpen = page === '/about' ? 'About' : undefined
                ctx.page = page
              })
            ],

            cond: (ctx, { page }): boolean => ctx.page !== page
          }
        ])
      },

      MODAL_CHANGE: {
        actions: assign((ctx, { modal }) => {
          ctx.modalOpen = modal
        })
      },

      // @ts-expect-error
      'xstate.error': {
        actions: (_ctx: AppMachineContext, { data }: { data: Error }): void => {
          throw data
        }
      },
      '*': {
        actions: [unexpectedAction]
      }
    },
    states
  },
  {
    actions,
    guards: {
      isNotMobile: ({ isMobile }): boolean => isMobile === false,

      hasAddedAllChannels: (
        { global, channels },
        event: GlobalEvents.EmittedChannelInitialized
      ): boolean => {
        if (global === undefined) {
          return false
        }

        assertIsObject(channels)

        const channelsInititializedState = Array.from(channels).map(
          ([channelNumber, channelState]) => {
            return (
              channelState.state === ChannelLoadingState.Initialized ||
              channelNumber === event.channelNumber
            )
          }
        )
        const channelsAreAllLoaded = every(channelsInititializedState)
        return channelsAreAllLoaded
      }
    }
  }
)

export { machine }
