import deepFreeze from 'deep-freeze-es6'
import { Machine, assign, spawn, sendParent, forwardTo } from 'xstate'
import { assign as assignImmer } from '@xstate/immer'
import { assertIsObject, assertIsString } from 'assertate'
import { RequiredKeys } from 'utility-types'
import { unexpectedAction } from 'midi-city-xstate-utils'
import { SupportType } from 'midi-city-shared-types'

import audioEnvironment, {
  Events as AudioEnvironmentEvents,
  Actor as AudioEnvironmentActor
} from '../audio-environment'

import supportTypeDetect from '../../client/support-type-detect'
import { Context, Schema, Events } from './types'

export * from './types'

export const CONTEXT_DEFAULT: Pick<Context, RequiredKeys<Context>> = { bpm: 80 }

const globalMachine = Machine<Context, Schema, Events.All>(
  {
    id: 'sound-engine-global',
    context: CONTEXT_DEFAULT,
    initial: 'uninitialized',
    strict: true,
    on: {
      // @ts-expect-error
      'xstate.error': {
        actions: (_ctx: Context, { data }: { data: Error }): void => {
          throw data
        }
      },
      '*': {
        actions: [unexpectedAction]
      }
    },
    states: {
      uninitialized: {
        on: {
          INITIALIZE: {
            target: 'initializing',
            actions: ['initialize']
          },
          '*': {
            actions: (): void => {
              throw new Error('Sound Engine was not initialized')
            }
          }
        }
      },

      initializing: {
        initial: 'supportTypeDetecting',

        on: {
          DISPOSE_REQUEST: {
            target: 'disposed'
          }
        },

        states: {
          supportTypeDetecting: {
            invoke: {
              id: 'supportTypeDetect',
              src: supportTypeDetect,
              onDone: [
                {
                  target: 'waitingForAudioEnvironments',
                  actions: [
                    assign({ supportType: (_ctx, event) => event.data })
                  ],

                  cond: (_ctx, event): boolean =>
                    event.data !== SupportType.None
                },
                {
                  target: '#sound-engine-global.failed',
                  actions: [
                    assign({
                      supportType: _ctx => SupportType.None
                    })
                  ]
                }
              ]
            }
          },
          waitingForAudioEnvironments: {
            entry: ['audioEnvironmentsInitialize'],
            on: {
              AUDIO_ENVIRONMENT_INITIALIZED: {
                target: '#sound-engine-global.initialized'
              }
            }
          }
        }
      },

      initialized: {
        entry: [
          sendParent(
            (): Events.EmittedInitialized => ({
              type: 'GLOBAL_INITIALIZED'
            })
          )
        ],

        on: {
          CHANNEL_PRESET_CHANGE_REQUEST: {
            actions: [forwardTo('audio-environment-main')]
          },

          AUDIO_ENVIRONMENT_CHANNEL_PRESET_LOADED: {
            actions: [
              sendParent(
                (
                  _ctx: Context,
                  event: AudioEnvironmentEvents.EmittedChannelPresetLoaded
                ): Events.EmittedChannelLoadSuccess => ({
                  ...event,
                  type: 'GLOBAL_CHANNEL_LOADED'
                })
              )
            ]
          },

          AUDIO_ENVIRONMENT_CHANNEL_NOTE_START_SCHEDULED: {
            actions: [
              sendParent(
                (
                  _ctx: Context,
                  event: AudioEnvironmentEvents.EmittedChannelNoteStartScheduled
                ): Events.EmittedChannelNoteStartScheduled => ({
                  ...event,
                  type: 'GLOBAL_CHANNEL_NOTE_START_SCHEDULED'
                })
              )
            ]
          },

          AUDIO_ENVIRONMENT_CHANNEL_NOTE_RELEASE_SCHEDULED: {
            actions: [
              sendParent(
                (
                  _ctx: Context,
                  event: AudioEnvironmentEvents.EmittedChannelNoteReleaseScheduled
                ): Events.EmittedChannelNoteReleaseScheduled => ({
                  ...event,
                  type: 'GLOBAL_CHANNEL_NOTE_RELEASE_SCHEDULED'
                })
              )
            ]
          },

          CHANNEL_INITIALIZED: {
            actions: forwardTo('#_parent')
          },

          CHANNEL_LOAD_REQUEST: {
            actions: forwardTo('audio-environment-main')
          },

          CHANNEL_NOTE_START_REQUEST: {
            actions: forwardTo('audio-environment-main')
          },

          CHANNEL_NOTE_RELEASE_REQUEST: {
            actions: forwardTo('audio-environment-main')
          },

          CHANNEL_RELEASE_ALL_REQUEST: {
            actions: forwardTo('audio-environment-main')
          },

          AUDIO_ENVIRONMENT_CHANNEL_LOADING: {
            actions: ['channelLoadingReport']
          },

          DISPOSE_REQUEST: {
            target: 'disposed'
          }
        }
      },

      failed: {
        entry: [sendParent({ type: 'GLOBAL_FAILED' })]
      },

      disposed: {
        type: 'final',
        entry: ['audioEnvironmentsDispose']
      }
    }
  },
  {
    actions: {
      initialize: assignImmer((ctx, event) => {
        // deep freeze gives perf. benefit to immer
        ctx.api = deepFreeze((event as Events.Initialize).api)
        ctx.urlBase = (event as Events.Initialize).urlBase
        ctx.audioEnvironmentMain = spawn(audioEnvironment, {
          name: 'audio-environment-main'
        }) as AudioEnvironmentActor
      }),

      audioEnvironmentsInitialize: ({
        audioEnvironmentMain,
        urlBase,
        api
      }): void => {
        assertIsObject(audioEnvironmentMain)
        assertIsString(urlBase)
        assertIsObject(api)
        audioEnvironmentMain.send({ type: 'INITIALIZE', urlBase, api })
      },

      audioEnvironmentsDispose: ({ audioEnvironmentMain }): void => {
        audioEnvironmentMain?.send({ type: 'DISPOSE_REQUEST' })
      },

      channelLoadingReport: sendParent(
        (
          _ctx: Context,
          event: AudioEnvironmentEvents.EmittedChannelLoading
        ): Events.EmittedChannelLoading => ({
          ...event,
          type: 'GLOBAL_CHANNEL_LOADING'
        })
      )
    },

    guards: {},

    services: {}
  }
)

export default globalMachine
