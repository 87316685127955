import { isSupported as isSupportedAudioContext } from 'standardized-audio-context'
import { detect } from 'detect-browser'
import { SupportType } from 'midi-city-shared-types'

async function supportTypeDetect(): Promise<SupportType> {
  const browser = detect()

  if (browser === null) {
    return await Promise.resolve(SupportType.Full)
  }

  const { name, version } = browser

  const isSupportedBrowser = [
    'chrome',
    'edge-chromium',
    'chromium-webview',
    'ios',
    'safari',
    'crios',
    'ios-webview'
  ].includes(name)

  const forceSupportBrowser = ['edge-chromium'].includes(name)

  if (forceSupportBrowser) {
    return await Promise.resolve(SupportType.Full)
  }

  const majorVersion =
    version !== null ? parseInt(version.split('.')[0]) : false

  const isSafari = ['safari', 'ios-webview', 'ios'].includes(name)
  const isSupportedVersion = isSafari
    ? majorVersion !== false && majorVersion >= 13
    : name === 'chrome'
    ? majorVersion !== false && majorVersion >= 75
    : true

  try {
    const audioIsSupported = await isSupportedAudioContext()
    if (!audioIsSupported && !isSupportedVersion) {
      return SupportType.None
    }

    return isSupportedBrowser && isSupportedVersion
      ? SupportType.Full
      : SupportType.Partial
  } catch {
    // eslint-disable-next-line no-console
    console.warn('Error throw when retrieving audio support')
    return await Promise.resolve(SupportType.None)
  }
}

export default supportTypeDetect
