import { Machine, sendParent, spawn, send } from 'xstate'
import { original } from 'immer'
import { assign } from '@xstate/immer'
import { assertIsObject, assertIsNumber } from 'assertate'
import ga, { isInitialized } from '../ga'
import { omit, range } from 'lodash-es'
import { raf } from '@react-spring/rafz'

import { MidiNoteEventSource, MidiNote } from 'midi-city-shared-types'

import { unexpectedAction, ignoredAction } from 'midi-city-xstate-utils'

import * as utils from './utils'
import actions from './actions'

import {
  VirtualControllerMachineSchema,
  VirtualControllerMachineContext,
  Events
} from './types'

import { KeyboardMachineEmittedEventLayoutStyleChanged } from '../keyboard-machine/events'
import { getBankIdIsMelodic } from '../selectors'
import visualizerMachine, {
  Actor as VisualizerActor,
  LayoutUpdateEvent as VisualizerLayoutUpdate,
  PartProgressUpdated
} from '../visualizer'

export * from './types'

export const DEFAULT_CONTEXT = Object.freeze({
  visualizerEnabled: false,
  isPlaying: false,
  layout: {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }
})

export const PADDING = 16

// WARNING: side-effects
const START_REPORT_INTERVAL = 25
let startReportCount = 0

const machine = Machine<
  VirtualControllerMachineContext,
  VirtualControllerMachineSchema,
  Events.All
>(
  {
    id: 'virtual-controller',
    strict: true,
    initial: 'uninitialized',

    on: {
      DISPOSE_REQUEST: {
        target: 'disposed'
      },
      'xstate.error': {
        actions: (
          _ctx: VirtualControllerMachineContext,
          { data }: { data: Error }
        ): never => {
          throw data
        }
      },
      '*': {
        actions: unexpectedAction
      }
    },

    states: {
      uninitialized: {
        always: {
          target: 'initializing',
          actions: [
            assign((ctx, event) => {
              const { part, recordingAvailable } = original(
                ctx
              ) as VirtualControllerMachineContext
              Object.assign(ctx, omit(event, 'type'))

              ctx.visualizer = spawn(
                visualizerMachine.withContext({
                  part,
                  scrubberEnabled:
                    recordingAvailable || !getBankIdIsMelodic(ctx.bankId),
                  scrubberPositionX: 0,
                  layout: { x: 0, y: 0, width: 0, height: 0 },
                  isPlaying: false,
                  progress: 0
                }),
                {
                  name: 'visualizer'
                }
              ) as VisualizerActor

              ctx.midiNoteStates = new Map()
            }),
            'contentLayoutUpdate',
            'navLayoutUpdate',
            'keyboardLayoutUpdate'
          ]
        }
      },

      initializing: {
        entry: ['keyboardInit', 'othersInit'],
        always: {
          target: 'initialized'
        }
      },
      initialized: {
        initial: 'open',

        invoke: {
          id: 'animation-observer',
          src: () => (callback): void => {
            const frame = (): boolean => {
              // eslint-disable-next-line standard/no-callback-literal
              callback({ type: 'FRAME_UPDATE' })
              return true
            }
            raf(frame)
          }
        },

        on: {
          VIRTUAL_CONTROLLER_CHANGE_PRESET: {
            actions: ['presetChangeFwdMidiDevice', 'presetUpdate']
          },

          TRACK_MANAGER_DURATION_UPDATED: {
            actions: assign((ctx, event) => {
              ctx.quarterNotes = event.quarterNotes
            })
          },

          VISUALIZER_LAYOUT_UPDATE: {
            actions: [
              send(
                (_context, { layout }): VisualizerLayoutUpdate => ({
                  type: 'LAYOUT_UPDATE',
                  layout
                }),
                {
                  to: 'visualizer'
                }
              )
            ]
          },

          PART_RESET: {
            actions: ['forceAllKeysOff', 'visualizerSendPartUpdated']
          },

          FRAME_UPDATE: {
            actions: [
              'partProgressUpdate',
              'frameUpdate',
              send(
                ({ progress }): PartProgressUpdated => ({
                  type: 'PART_PROGRESS_UPDATED',
                  progress: progress as number
                }),
                { to: 'visualizer' }
              )
            ]
          },

          CHANNEL_PRESET_LOADED: {
            actions: [
              assign((ctx, event) => {
                const { keyRange } = event
                const { midiNoteStates } = ctx
                assertIsObject(midiNoteStates)
                range(keyRange.lo, keyRange.hi + 1).forEach(midiNote => {
                  const midiNoteState = midiNoteStates.get(midiNote as MidiNote)
                  assertIsObject(midiNoteState)
                  midiNoteState.isLoaded = true
                })

                ctx.visualizerEnabled = true
              })
            ]
          },

          CHANNEL_PRESET_LOADING: {
            actions: [
              assign((ctx, event) => {
                const { keyRange } = event
                const { midiNoteStates } = ctx
                assertIsObject(midiNoteStates)
                range(keyRange.lo, keyRange.hi + 1).forEach(midiNote => {
                  midiNoteStates.set(midiNote as MidiNote, {
                    isActive: false,
                    isLoaded: false,
                    schedule: new Map()
                  })
                })
              })
            ]
          },

          KEYBOARD_KEY_ON: {
            actions: (ctx, event): void => {
              const { midiDevice, channel, bankId } = ctx
              const { keyNumber } = event

              assertIsNumber(bankId)

              const noteNumber = utils.getKeyMidiNote(keyNumber, bankId)

              assertIsObject(midiDevice)
              assertIsNumber(channel)

              startReportCount += 1

              if (
                startReportCount % START_REPORT_INTERVAL === 0 &&
                isInitialized()
              ) {
                ga?.gtag('event', 'Played Keys', {
                  value: START_REPORT_INTERVAL
                })
              }

              midiDevice.send({
                type: 'MIDI_DEVICE_NOTE_START_REQUEST',
                note: noteNumber,
                channelNumber: channel,
                source: MidiNoteEventSource.Hardware
              })
            }
          },

          KEYBOARD_KEY_OFF: {
            actions: (ctx, event): void => {
              const { midiDevice, channel, bankId } = ctx
              assertIsNumber(bankId)
              const noteNumber = utils.getKeyMidiNote(event.keyNumber, bankId)

              assertIsObject(midiDevice)
              assertIsNumber(channel)

              midiDevice.send({
                type: 'MIDI_DEVICE_NOTE_RELEASE_REQUEST',
                note: noteNumber,
                channelNumber: channel,
                source: MidiNoteEventSource.Hardware
              })
            }
          },

          LAYOUT_UPDATE: {
            actions: [
              assign(
                (ctx, { layout }) =>
                  (ctx.layout = {
                    ...layout
                  })
              ),
              'contentLayoutUpdate',
              'navLayoutUpdate',
              'keyboardLayoutUpdate',
              'keyboardLayoutSend'
            ]
          },

          NOTE_START_SCHEDULED: [
            {
              actions: ['addToScheduled'],
              cond: 'isNotEventFromVirtualController'
            },
            {
              actions: ignoredAction
            }
          ],

          NOTE_RELEASE_SCHEDULED: [
            {
              actions: ['addToScheduled'],
              cond: 'isNotEventFromVirtualController'
            },
            { actions: ignoredAction }
          ],

          CHANNEL_NOTE_RECORDING_STARTED: {
            actions: ignoredAction
          },

          CHANNEL_NOTE_RECORDING_RELEASED: {
            actions: 'visualizerSendPartUpdated'
          },

          TRACK_STARTED: {
            actions: [
              assign(ctx => (ctx.isPlaying = true)),
              send({ type: 'PART_STARTED' }, { to: 'visualizer' })
            ]
          },

          TRACK_STOPPED: {
            actions: [
              assign(ctx => (ctx.isPlaying = false)),
              send({ type: 'PART_STOPPED' }, { to: 'visualizer' })
            ]
          },

          KEYBOARD_LAYOUT_STYLE_CHANGED: {
            actions: [
              sendParent(
                (
                  _ctx: VirtualControllerMachineContext,
                  event: KeyboardMachineEmittedEventLayoutStyleChanged
                ) => event
              )
            ]
          }
        },
        states: {
          open: {},
          collapsed: {}
        }
      },

      disposed: {
        type: 'final'
      }
    }
  },
  {
    actions,
    guards: {
      isNotEventFromVirtualController: (_ctx, event): boolean => {
        const { source } = event as
          | Events.ChannelNoteStartScheduled
          | Events.ChannelNoteReleaseScheduled
        return source !== MidiNoteEventSource.VirtualController
      }
    }
  }
)

export { machine }
