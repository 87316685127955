import { Brand } from 'utility-types'
import { RangeGeneratorValue } from 'sf2-parser'
import { times } from 'lodash-es'

export type ChannelT = Brand<number, 'CHANNEL'>

export type BankIdT = Brand<number, 'BANK_ID'>
export type PresetNumber = Brand<number, 'PRESET_NUMBER'>

export type KeyboardKeyNumber = Brand<number, 'KEYBOARD_KEY_NUMBER'>
export type KeyboardKeyId = Brand<string, 'KEYBOARD_KEY_ID'>
export type KeyboardId = Brand<string, 'KEYBOARD_ID'>
export type KeyboardPosition = Brand<number, 'KEYBOARD_POSITION'>

export type Range = RangeGeneratorValue

export type KeyRange = Brand<Range, 'KEY_RANGE'>
export type VelocityRange = Brand<Range, 'VELOCITY_RANGE'>

export type MidiNote = Brand<number, 'MIDI_NOTE'>

export type Tick = Brand<number, 'TICK'>

export interface LayoutRectangle {
  x: number
  y: number
  width: number
  height: number
}

export enum KeyboardLayoutStyle {
  Single,
  Double,
  Quad
}

export enum SupportType {
  Full,
  Partial,
  None
}

export enum MidiNoteEventSource {
  VirtualController,
  Hardware,
  Scheduled,
  TrackManager
}

export const BANK_ID_PERCUSSION_DEFAULT = 128 as BankIdT
export const BANK_ID_MELODIC_DEFAULT = 0 as BankIdT

export const CHANNEL_NUMBER_PERCUSSION_DEFAULT = 10 as ChannelT
export const CHANNEL_NUMBER_MELODIC_DEFAULT = 1 as ChannelT

export const PRESET_NUMBER_MELODIC_DEFAULT = 90 as PresetNumber
export const PRESET_NUMBER_PERCUSSION_DEFAULT = 25 as PresetNumber

export const VELOCITY_SUPPORTED = 127

export const MIDI_CHANNEL_COUNT = 16
export const MIDI_CHANNELS = new Set(
  times(MIDI_CHANNEL_COUNT)
    .map(index => (index + 1) as ChannelT)
    .reverse()
)
export function getChannelPresetNumberDefault(channel: ChannelT): PresetNumber {
  return channel === CHANNEL_NUMBER_PERCUSSION_DEFAULT
    ? PRESET_NUMBER_PERCUSSION_DEFAULT
    : PRESET_NUMBER_MELODIC_DEFAULT
}

export function getChannelBankNumberDefault(channel: ChannelT): BankIdT {
  return channel === CHANNEL_NUMBER_PERCUSSION_DEFAULT
    ? BANK_ID_PERCUSSION_DEFAULT
    : BANK_ID_MELODIC_DEFAULT
}
export const CHANNELS_SUPPORTED = new Set<ChannelT>([
  10 as ChannelT,
  1 as ChannelT
])

export function rangeCreate<T extends Range>(lo: number, hi: number): T {
  return ({ lo, hi } as unknown) as T
}

export function velocityRangeCreate(lo: number, hi: number): VelocityRange {
  return rangeCreate(lo, hi)
}

export function keyRangeCreate(lo: number, hi: number): KeyRange {
  return rangeCreate(lo, hi)
}

export function rangeIntersection<T extends Range>(range1: T, range2: T): T {
  const lo = Math.max(range1.lo, range2.lo)
  const hi = Math.min(range1.hi, range2.hi)
  return rangeCreate(lo, hi)
}

export function isValidRange(range: Range): boolean {
  return range.lo <= range.hi
}

export function rangeExceeds(rangeOther: Range, rangeBase: Range): boolean {
  return rangeOther.lo < rangeBase.lo || rangeOther.hi > rangeBase.hi
}

export function getItemsMatchingRanges<
  T extends {
    keyRange?: KeyRange | RangeGeneratorValue
    velRange?: VelocityRange | RangeGeneratorValue
  }
>(items: T[], keyRange: KeyRange, velocityRange: VelocityRange): T[] {
  return items.filter(item => {
    const keyRangeIntersection = rangeIntersection(
      keyRange,
      item.keyRange ?? KEY_RANGE_FULL
    )

    if (!isValidRange(keyRangeIntersection)) {
      return false
    }

    const velocityRangeIntersection = rangeIntersection(
      velocityRange,
      item.velRange ?? VELOCITY_RANGE_FULL
    )

    return isValidRange(velocityRangeIntersection)
  })
}

export const KEY_RANGE_FULL = keyRangeCreate(0, 127)
export const VELOCITY_RANGE_FULL = velocityRangeCreate(0, 127)

export function isToneTimeError(error: Error): boolean {
  return (
    error instanceof Error &&
    (error.message.includes('The time must be greater than') ||
      error.message.includes(
        'Start time must be strictly greater than previous start '
      ))
  )
}

export function isNonNull<T>(value: T): value is NonNullable<T> {
  return value != null
}
