import { Machine, sendParent, assign } from 'xstate'

import {
  logAction,
  unexpectedAction,
  ignoredAction
} from 'midi-city-xstate-utils'

import {
  KeyboardKeyMachineContext,
  KeyboardKeyMachineSchema,
  KeyboardKeyMachineEvent,
  KeyboardKeyMachineEventShortcutUpdate,
  KeyboardKeyMachineEventKeyboardLayoutChanged
} from './types'

export * from './types'

const keyboardKeyMachine = Machine<
  KeyboardKeyMachineContext,
  KeyboardKeyMachineSchema,
  KeyboardKeyMachineEvent
>({
  id: 'keyboard-key',

  strict: true,

  on: {
    KEYBOARD_KEY_SHORTCUT_UPDATE: {
      actions: [
        assign<
          KeyboardKeyMachineContext,
          KeyboardKeyMachineEventShortcutUpdate
        >({
          shortcut: (_ctx, event) => event.shortcut
        })
      ]
    },

    KEYBOARD_LAYOUT_CHANGED: {
      actions: [
        assign<
          KeyboardKeyMachineContext,
          KeyboardKeyMachineEventKeyboardLayoutChanged
        >({
          keyboardLayout: (_ctx, event) => event.value
        })
      ]
    },

    KEYBOARD_LAYOUT_STYLE_CHANGED: {
      actions: [
        assign({
          keyboardLayoutStyle: (_ctx, { value }) => value
        }),
        logAction
      ]
    },

    '*': {
      actions: [unexpectedAction]
    }
  },

  type: 'parallel',

  states: {
    actual: {
      initial: 'off',
      states: {
        off: {
          on: {
            KEYBOARD_KEY_OFF: {
              // unclear how this happens
              actions: ignoredAction
            },
            KEYBOARD_KEY_ON: {
              target: 'on',
              actions: [
                sendParent((ctx: KeyboardKeyMachineContext) => ({
                  keyNumber: ctx.number,
                  type: 'KEYBOARD_KEY_ON'
                })),
                logAction
              ]
            }
          }
        },
        on: {
          on: {
            KEYBOARD_KEY_ON: {
              // unclear how this happens
              actions: ignoredAction
            },
            KEYBOARD_KEY_OFF: {
              target: 'off',
              actions: [
                sendParent((ctx: KeyboardKeyMachineContext) => ({
                  keyNumber: ctx.number,
                  type: 'KEYBOARD_KEY_OFF'
                })),
                logAction
              ]
            }
          }
        }
      }
    },
    forced: {
      initial: 'off',
      states: {
        off: {
          on: {
            FORCE_ON: {
              target: 'on',
              actions: [logAction]
            },
            FORCE_OFF: {
              actions: ignoredAction
            }
          }
        },
        on: {
          on: {
            FORCE_OFF: {
              target: 'off',
              actions: [logAction]
            },
            FORCE_ON: {
              actions: ignoredAction
            }
          }
        }
      }
    }
  }
})

export default keyboardKeyMachine
