import moize from 'moize'
import { range } from 'lodash-es'
import { note } from '@tonaljs/tonal'
import { midiToNoteName } from '@tonaljs/midi'
import {
  BankIdT,
  KeyboardKeyNumber,
  BANK_ID_PERCUSSION_DEFAULT,
  BANK_ID_MELODIC_DEFAULT,
  MidiNote
} from 'midi-city-shared-types'
import { assertIsNumber } from 'assertate'

const MELODIC_MIDI_NOTE_MIN = 36 as MidiNote
const MELODIC_MIDI_NOTE_MAX = 96 as MidiNote

const PERCUSSION_MIDI_NOTE_MIN = 35 as MidiNote
const PERCUSSION_MIDI_NOTE_MAX = 81 as MidiNote

export function getMidiNotes(bankId: BankIdT): MidiNote[] {
  if (bankId === BANK_ID_MELODIC_DEFAULT) {
    return range(MELODIC_MIDI_NOTE_MIN, MELODIC_MIDI_NOTE_MAX) as MidiNote[]
  } else {
    return range(
      PERCUSSION_MIDI_NOTE_MIN,
      PERCUSSION_MIDI_NOTE_MAX
    ) as MidiNote[]
  }
}

export function getKeyMidiNote(
  key: KeyboardKeyNumber,
  bankId: BankIdT
): MidiNote {
  return getMidiNotes(bankId)[key]
}

export const getMidiNoteKey = moize((midiNote: MidiNote, bankId: BankIdT):
  | KeyboardKeyNumber
  | undefined => {
  const keyNumber = getMidiNotes(bankId).indexOf(midiNote) as KeyboardKeyNumber
  return keyNumber >= 0 ? keyNumber : undefined
})

export function getSnapToKeys(
  midiNotes: MidiNote[],
  scaleKey: string,
  bankId: BankIdT
): number[] {
  return midiNotes
    .filter(midiNote => {
      const midiNoteName = midiToNoteName(midiNote)
      const noteObject = note(midiNoteName)
      return noteObject.letter === scaleKey
    })
    .map(midiNote => {
      const keyNumber = getMidiNoteKey(midiNote, bankId)
      assertIsNumber(keyNumber)
      return keyNumber
    })
}

export const getKeyVisibleStart = moize(
  (
    _scaleName: string,
    scaleKey: string,
    octave: number,
    bankId: BankIdT
  ): number => {
    let midiNote: MidiNote
    if (bankId === BANK_ID_PERCUSSION_DEFAULT) {
      midiNote = PERCUSSION_MIDI_NOTE_MIN
    } else {
      const midiParsed = note(`${scaleKey}${octave}`).midi
      if (typeof midiParsed !== 'number') {
        throw new Error('missing midiNote')
      }
      midiNote = midiParsed as MidiNote
    }
    return getMidiNotes(bankId).indexOf(midiNote)
  }
)
