import { ActionFunctionMap, assign, spawn } from 'xstate'
import { assertIsObject } from 'assertate'
import { assign as assignImmer } from '@xstate/immer'
import { original } from 'immer'
import { NonUndefined } from 'utility-types'

import * as SoundEngine from 'midi-city-sound-engine'
import {
  getChannelBankNumberDefault,
  getChannelPresetNumberDefault,
  MidiNoteEventSource,
  MIDI_CHANNELS
} from 'midi-city-shared-types'

import * as MidiTrackManager from '../midi-track-manager'
import { MessageIdentifier } from '../notification-manager'
import * as VirtualController from '../virtual-controller'
import * as MidiDeviceMachine from '../midi-device-machine'

import { Context, Events } from './types'

const MIDI_INPUT_NAME_SKIP_PATTERN = /Midi Through Port-[0-100]/

const actions: ActionFunctionMap<Context, Events.All> = {
  initialize: assignImmer((ctx, event) => {
    const {
      shortcutManager,
      layoutManager,
      notificationManager
    } = event as Events.Initialize
    ctx.shortcutManager = shortcutManager
    ctx.layoutManager = layoutManager
    ctx.notificationManager = notificationManager

    ctx.inputDevice = spawn(
      MidiDeviceMachine.machine.withContext({
        channels: new Set(),
        source: MidiNoteEventSource.Hardware
      })
    ) as MidiDeviceMachine.Actor

    ctx.virtualDevice = spawn(
      MidiDeviceMachine.machine.withContext({
        channels: new Set(),
        source: MidiNoteEventSource.VirtualController
      }),
      { name: 'virtual-device' }
    ) as MidiDeviceMachine.Actor

    const channels: NonUndefined<Context['channels']> = new Map()

    MIDI_CHANNELS.forEach(channel => {
      channels.set(channel, {
        presetNumber: getChannelPresetNumberDefault(channel),
        bankId: getChannelBankNumberDefault(channel)
      })
    })

    ctx.channels = channels

    ctx.virtualControllers = new Map()
  }),

  scheduleClear: ({ virtualControllers }, event): void => {
    assertIsObject(virtualControllers)

    const {
      channelNumber
    } = event as MidiTrackManager.Events.EmittedLoadRequested

    const virtualController = virtualControllers.get(channelNumber)

    assertIsObject(virtualController)

    virtualController.send({
      type: 'PART_RESET'
    })
  },

  inputChannelLatestUpdate: assign({
    inputChannelLatest: (_ctx, event) =>
      (event as Events.MidiInputNoteRelease).channelNumber
  }),

  setChannelPart: assignImmer((ctx, event) => {
    const { channels } = ctx
    assertIsObject(channels)

    const {
      part,
      channelNumber
    } = event as MidiTrackManager.Events.EmittedInitialized

    const channel = channels.get(channelNumber)

    assertIsObject(channel)

    channel.part = part
  }),

  virtualControllerInit: assignImmer((ctx, event) => {
    const {
      channelNumber
    } = event as SoundEngine.Events.EmittedChannelInitialized

    const {
      shortcutManager,
      channels,
      virtualDevice,
      recordingAvailable
    } = original(ctx) as Context
    const { virtualControllers } = ctx

    assertIsObject(virtualDevice)
    assertIsObject(virtualControllers)
    assertIsObject(channels)
    assertIsObject(shortcutManager)

    const channel = channels.get(channelNumber)
    assertIsObject(channel)

    const { presetNumber, bankId, part } = channel

    assertIsObject(part)

    const virtualController = spawn(
      VirtualController.machine.withContext({
        ...VirtualController.DEFAULT_CONTEXT,
        channel: channelNumber,
        presetNumber,
        bankId,
        part,
        recordingAvailable,
        midiDevice: virtualDevice,
        shortcutManager: shortcutManager
      }),
      { name: channelNumber.toString() }
    ) as VirtualController.VirtualControllerMachineActor

    virtualControllers.set(channelNumber, virtualController)
  }),

  midiInputsUpdate: assign({
    midiInputs: (_ctx, event) =>
      (event as Events.InputsChanged).midiInputs.filter(
        midiInput => !MIDI_INPUT_NAME_SKIP_PATTERN.test(midiInput.name)
      )
  }),

  midiInputSelectedSetDefault: assign({
    midiInputSelectedId: ({ midiInputSelectedId, midiInputs }) => {
      const selectedStillExists =
        midiInputSelectedId !== undefined &&
        midiInputs.some(({ id }) => id === midiInputSelectedId)

      if (selectedStillExists) {
        return midiInputSelectedId
      }

      return midiInputs[0]?.id
    }
  }),

  shortcutManagerSendVirtualControllerKeyboard: (ctx, event): void => {
    const { shortcutManager, virtualControllers } = ctx
    const {
      channelNumber
    } = event as MidiTrackManager.Events.EmittedInitialized

    assertIsObject(shortcutManager)
    assertIsObject(virtualControllers)

    const virtualController = virtualControllers.get(channelNumber)
    assertIsObject(virtualController)

    shortcutManager.send({
      type: 'SHORTCUT_MANAGER_KEYBOARD_ADD',
      keyboard: virtualController.state.context.keyboard
    })
  },

  layoutManagerSendVirtualController: (
    { layoutManager, virtualControllers },
    event
  ): void => {
    const {
      channelNumber
    } = event as MidiTrackManager.Events.EmittedInitialized

    assertIsObject(layoutManager)
    assertIsObject(virtualControllers)

    const virtualController = virtualControllers.get(channelNumber)
    assertIsObject(virtualController)

    layoutManager.send({
      type: 'VIRTUAL_CONTROLLER_ADD',
      virtualController: virtualController as VirtualController.VirtualControllerInterpreter
    })
  },

  unsupportedChannelReport: ({ notificationManager }): void => {
    assertIsObject(notificationManager)
    notificationManager.send({
      type: 'ADD_REQUEST',
      identifier: MessageIdentifier.BadChannel
    })
  }
}

export { actions }
