import {
  GeneratorType,
  ValueGeneratorType,
  ValueGenerator,
  RangeGenerator,
  InstrumentZoneGeneratorType,
  InstrumentZoneGenerator,
  PresetZoneGenerator
} from 'sf2-parser'

type GeneratorOfType<T> = T extends ValueGeneratorType
  ? ValueGenerator
  : RangeGenerator

export default function generatorWithType<
  T1 extends GeneratorType,
  T2 extends GeneratorOfType<T1>
>(
  type: T1,
  generators: T1 extends InstrumentZoneGeneratorType
    ? InstrumentZoneGenerator[]
    : PresetZoneGenerator[]
): T2 | null {
  const generator = generators.find((zone): zone is T2 => zone.type === type)
  return generator !== undefined ? generator : null
}
