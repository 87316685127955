import { Machine, Actor, State, assign, Interpreter } from 'xstate'
import { produce } from 'immer'

import { KeyboardMachineActor, KeyboardMachineState } from './keyboard-machine'
import * as selectors from './selectors'
import { getKeyboardKeyShortcut } from './keyboard-machine/utils'

import { logAction } from 'midi-city-xstate-utils'
import { KeyboardLayoutStyle } from 'midi-city-shared-types'
import { KeyboardKeyState } from 'midi-city-app-manager/src/keyboard-key-machine'
import { assertIsNumber } from 'assertate'

export interface ShortcutManagerMachineEventKeyboardAdd {
  type: 'SHORTCUT_MANAGER_KEYBOARD_ADD'
  keyboard: KeyboardMachineActor
}

export interface ShortcutManagerMachineEventOnRequest {
  type: 'SHORTCUT_MANAGER_ON_REQUEST'
  code: string
}

export interface ShortcutManagerMachineEventOffRequest {
  type: 'SHORTCUT_MANAGER_OFF_REQUEST'
  code: string
}

export interface ShortcutManagerMachineEventUpdateRequest {
  type: 'SHORTCUT_MANAGER_UPDATE_REQUEST'
}

export type ShortcutManagerMachineEvent =
  | ShortcutManagerMachineEventKeyboardAdd
  | ShortcutManagerMachineEventOnRequest
  | ShortcutManagerMachineEventOffRequest
  | ShortcutManagerMachineEventUpdateRequest

export interface ShortcutManagerMachineContext {
  keyboards: KeyboardMachineActor[]
}

export interface ShortcutManagerMachineSchema {
  states: {
    ready: {}
  }
}
export type ShortcutManagerMachineInterpreter = Interpreter<
  ShortcutManagerMachineContext,
  ShortcutManagerMachineSchema,
  ShortcutManagerMachineEvent
>

export type ShortcutManagerState = State<
  ShortcutManagerMachineContext,
  ShortcutManagerMachineEvent,
  ShortcutManagerMachineSchema
>

export type ShortcutManagerMachineActor = Actor<
  ShortcutManagerState,
  ShortcutManagerMachineEvent
>

const ShortcutManagerMachine = Machine<
  ShortcutManagerMachineContext,
  ShortcutManagerMachineSchema,
  ShortcutManagerMachineEvent
>(
  {
    id: 'shortcut-manager',
    initial: 'ready',
    strict: true,
    context: {
      keyboards: []
    },
    on: {
      SHORTCUT_MANAGER_ON_REQUEST: {
        actions: (_ctx, { code }, meta): void => {
          const key = selectors.getShortcutManagerCodeKey(
            code,
            meta.state as ShortcutManagerState
          )
          if (key === undefined) {
            return
          }
          key.send({ type: 'KEYBOARD_KEY_ON' })
        }
      },
      SHORTCUT_MANAGER_OFF_REQUEST: {
        actions: (_ctx, { code }, meta): void => {
          const key = selectors.getShortcutManagerCodeKey(
            code,
            meta.state as ShortcutManagerState
          )
          if (key === undefined) {
            return
          }
          key.send({ type: 'KEYBOARD_KEY_OFF' })
        }
      }
    },
    states: {
      ready: {
        on: {
          SHORTCUT_MANAGER_KEYBOARD_ADD: {
            actions: [
              assign({
                keyboards: (ctx, event) =>
                  produce(ctx.keyboards, draft => {
                    draft = draft ?? []
                    draft.push(event.keyboard)
                  })
              }),
              'updateKeys'
            ]
          },
          SHORTCUT_MANAGER_UPDATE_REQUEST: {
            actions: [logAction, 'updateKeys']
          }
        }
      }
    }
  },
  {
    actions: {
      updateKeys: (ctx, _event): void => {
        const keyboards = ctx.keyboards

        const mapping = keyboards.reduce<{
          [position: number]: KeyboardLayoutStyle
        }>((mapping, keyboard) => {
          const keyboardState = keyboard.state as KeyboardMachineState

          const position = keyboardState.context.position
          assertIsNumber(position)

          mapping[position] = keyboard.state.context.layoutStyle

          return mapping
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        }, {})

        keyboards.forEach(keyboard => {
          const keyboardState = keyboard.state as KeyboardMachineState
          const { numKeys, position } = keyboardState.context

          assertIsNumber(position)
          assertIsNumber(numKeys)

          const keyVisibleStart = selectors.getKeyboardKeyVisibleStart(
            keyboardState
          )

          const keys = selectors.getKeyboardKeys(keyboardState)

          keys.forEach(key => {
            const { number } = (key.state as KeyboardKeyState).context
            const shortcut = getKeyboardKeyShortcut(
              position,
              number,
              mapping,
              numKeys,
              keyVisibleStart
            )

            key.send({ type: 'KEYBOARD_KEY_SHORTCUT_UPDATE', shortcut })
          })
        })
      }
    }
  }
)

export default ShortcutManagerMachine
