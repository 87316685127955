import { Observable } from 'rxjs'
import WebMidi from 'webmidi'
export const webMidiEnable: Observable<Error | undefined> = new Observable(
  observer => {
    WebMidi.enable(error => {
      observer.next(error)
      observer.complete()
    })
  }
)
