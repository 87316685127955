import { clamp } from 'lodash-es'
import { AmplitudeEnvelope, Context as AudioContext } from 'tone'

import { timeCentsToSeconds } from '../../../unit-conversions'

interface CreateEnvelopeArgs {
  attackVolEnv: number
  decayVolEnv: number
  sustainVolEnv: number
  releaseVolEnv: number
  context: AudioContext
}

export default function instrumentZoneCreateEnvelope(
  args: CreateEnvelopeArgs
): AmplitudeEnvelope {
  const {
    attackVolEnv,
    decayVolEnv,
    sustainVolEnv,
    releaseVolEnv,
    context
  } = args

  // somehow this can become below 0 or above 1
  const sustain = clamp(1 - sustainVolEnv * 0.001, 0, 1)

  return new AmplitudeEnvelope({
    attack: timeCentsToSeconds(attackVolEnv),
    decay: timeCentsToSeconds(decayVolEnv),
    sustain,
    release: timeCentsToSeconds(releaseVolEnv),
    context
  })
}
