import moize from 'moize'

import { LayoutRectangle, KeyboardKeyNumber } from 'midi-city-shared-types'

import {
  KEYBOARD_LAYOUT_VIRTUAL_ROW_COUNT,
  KeyboardLayoutStyle
} from '../index'
import { getKeyLeft, getKeyWidth, getKeyRow } from './key'
import { times } from 'lodash-es'

const ROW_GUTTER_HEIGHT = 12

const getRowKeys = moize(
  (
    row: number,
    numKeys: number,
    layoutStyle: KeyboardLayoutStyle
  ): KeyboardKeyNumber[] => {
    const keys = times(numKeys) as KeyboardKeyNumber[]
    return keys.filter((key): boolean => getKeyRow(key, layoutStyle) === row)
  }
)

function getRowVisibleMaxKeyCount(keyboardLayout: KeyboardLayoutStyle): number {
  if (keyboardLayout === KeyboardLayoutStyle.Quad) {
    return 20
  } else {
    return 10
  }
}

function getRowHeight(
  row: number,
  layout: LayoutRectangle,
  layoutStyle: KeyboardLayoutStyle
): number {
  const layoutHeight = layout.height

  const numRows = KEYBOARD_LAYOUT_VIRTUAL_ROW_COUNT.get(layoutStyle)

  if (numRows === undefined) {
    throw new Error('Unmatched Keyboard Layout')
  }

  const paddingsHeight = (numRows + 1) * ROW_GUTTER_HEIGHT

  const rowsTotalHeight = layoutHeight - paddingsHeight

  if (layoutStyle === KeyboardLayoutStyle.Single) {
    return rowsTotalHeight
  }

  const ratioDiff = 2 / 5
  const rowHeightRaw =
    row === 0 ? rowsTotalHeight * ratioDiff : rowsTotalHeight * (1 - ratioDiff)
  return Math.floor(rowHeightRaw)
}

function getRowTop(
  row: number,
  layout: LayoutRectangle,
  layoutStyle: KeyboardLayoutStyle
): number {
  return row === 0
    ? ROW_GUTTER_HEIGHT
    : getRowHeight(0, layout, layoutStyle) + ROW_GUTTER_HEIGHT * 2
}

function getRowPaddingScale(
  row: number,
  layoutStyle: KeyboardLayoutStyle
): number {
  if (layoutStyle === KeyboardLayoutStyle.Single) {
    return 0
  } else {
    return row === 0 ? 0.5 : 0
  }
}

function getRowWidth(
  row: number,
  numKeys: number,
  layout: LayoutRectangle,
  layoutStyle: KeyboardLayoutStyle
): number {
  const rowKeys = getRowKeys(row, numKeys, layoutStyle)
  const keyLast = rowKeys[rowKeys.length - 1]
  return (
    getKeyLeft(keyLast, numKeys, layout, layoutStyle) +
    getKeyWidth(keyLast, layout, layoutStyle)
  )
}

export {
  getRowKeys,
  getRowHeight,
  getRowTop,
  getRowPaddingScale,
  getRowVisibleMaxKeyCount,
  getRowWidth
}
