import moize from 'moize'
import { ApiResponse } from 'midi-city-api'
import fetch from 'isomorphic-fetch'

async function getApiResponse(url: string): Promise<ApiResponse> {
  const response = await fetch(url)
  const json: ApiResponse = await response.json()
  return json
}

export default moize(getApiResponse, { isPromise: true, isDeepEqual: true })
