import { assertIsObject } from 'assertate'

import { Actor } from '../midi-device-machine'

import { LayoutRectangle, MidiNote } from 'midi-city-shared-types'
import { VirtualControllerState } from '../virtual-controller/types'

export function getVirtualControllerLayout(
  state: VirtualControllerState
): LayoutRectangle {
  const { layout } = state.context
  assertIsObject(layout)
  return layout
}

export function getVirtualControllerNavLayout(
  state: VirtualControllerState
): LayoutRectangle {
  const virtualControllerLayout = getVirtualControllerLayout(state)
  return { x: 0, y: 0, width: virtualControllerLayout.width, height: 64 }
}

export function getVirtualControllerIsOpen(
  state: VirtualControllerState
): boolean {
  return state.matches('initialized.open')
}

export function getVirtualControllerMidiDevice(
  state: VirtualControllerState
): Actor {
  const midiDevice = state.context.midiDevice
  assertIsObject(midiDevice)
  return midiDevice
}

export function ggetVirtualControllerKeyboardLayout(
  state: VirtualControllerState
): LayoutRectangle {
  const virtualControllerLayout = getVirtualControllerLayout(state)
  const virtualControllerNavLayout = getVirtualControllerNavLayout(state)
  return {
    x: 0,
    y: 0,
    height: virtualControllerLayout.height - virtualControllerNavLayout.height,
    width: virtualControllerLayout.width - 16
  }
}

export function getVirtualControllerMidiNoteIsLoading(
  midiNote: MidiNote,
  state: VirtualControllerState
): boolean {
  return state.context.midiNoteStates?.get(midiNote)?.isLoaded !== true
}
