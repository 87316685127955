import moize from 'moize'
import { sum } from 'lodash-es'

import {
  PhysicalKeyboardKeyMapping,
  KeyboardLayoutMapping,
  PHYSICAL_LAYOUT,
  KEYBOARD_LAYOUT_PHYSICAL_ROW_COUNT
} from './index'

const getRowCountPhysicalRows = (
  rowCount: number
): PhysicalKeyboardKeyMapping[][] => {
  switch (rowCount) {
    case 1: {
      return [PHYSICAL_LAYOUT[2]]
    }
    case 2: {
      return [PHYSICAL_LAYOUT[1], PHYSICAL_LAYOUT[2]]
    }
    case 3: {
      return [PHYSICAL_LAYOUT[0], PHYSICAL_LAYOUT[1], PHYSICAL_LAYOUT[2]]
    }
    default: {
      return [
        PHYSICAL_LAYOUT[0],
        PHYSICAL_LAYOUT[1],
        PHYSICAL_LAYOUT[2],
        PHYSICAL_LAYOUT[3]
      ]
    }
  }
}

const getPhysicalRowsToBeUsed = moize(
  (
    visibleKeyboardLayoutMapping: KeyboardLayoutMapping
  ): PhysicalKeyboardKeyMapping[][] => {
    const numRowsNeeded = sum(
      Object.values(visibleKeyboardLayoutMapping).map(keyboardLayoutStyle =>
        KEYBOARD_LAYOUT_PHYSICAL_ROW_COUNT.get(keyboardLayoutStyle)
      )
    )
    return getRowCountPhysicalRows(numRowsNeeded)
  }
)

export default getPhysicalRowsToBeUsed
