interface TypeDefaults {
  initialAttenuation: number
  chorusEffectsSend: number
  reverbEffectsSend: number
  pan: number
  initialFilterFc: number
  initialFilterQ: number
  attackVolEnv: number
  decayVolEnv: number
  sustainVolEnv: number
  releaseVolEnv: number
  coarseTune: number
  fineTune: number
}

const TYPE_DEFAULTS: TypeDefaults = {
  initialAttenuation: 0,
  chorusEffectsSend: 0,
  reverbEffectsSend: 0,
  pan: 0,
  initialFilterFc: 13500,
  initialFilterQ: 0,
  attackVolEnv: -12000,
  decayVolEnv: -12000,
  sustainVolEnv: 0,
  releaseVolEnv: -12000,
  coarseTune: 0,
  fineTune: 0
}

export default TYPE_DEFAULTS
