import { Machine, spawn } from 'xstate'
import { unexpectedAction } from 'midi-city-xstate-utils'
import { assertIsObject, assertIsString } from 'assertate'
import { assign } from '@xstate/immer'

import { createMasterReverb } from './utils/create-master-reverb'
import { createMasterChorus } from './utils/create-master-chorus'
import { Context, Schema, Events } from './types'
import {
  Context as AudioContext,
  Gain,
  Compressor,
  setContext,
  context,
  Reverb
} from 'tone'
import { choose, sendParent, pure, send, forwardTo } from 'xstate/lib/actions'
import { InstrumentZoneMidiNoteEmittedEventSampleLoadRequest } from '../instrument-zone-midi-note'
import sampleMachine, { SampleActor } from '../sample'
import channelMachine, { ChannelMachineActor } from '../channel'
import {
  ChannelT,
  getChannelBankNumberDefault,
  getChannelPresetNumberDefault,
  MIDI_CHANNELS
} from 'midi-city-shared-types'
import { enableMapSet, original } from 'immer'
import { NonUndefined } from 'utility-types'

export * from './types'

// WARNING: side-effects
enableMapSet()

function getChannelId(channelNumber: ChannelT): string {
  return `channel-${channelNumber}`
}

const AudioEnvironmentMachine = Machine<Context, Schema, Events.All>(
  {
    id: 'audio-environment',
    strict: true,
    on: {
      DISPOSE_REQUEST: {
        target: 'disposed'
      },
      '*': {
        actions: [unexpectedAction]
      }
    },
    context: { samples: {} },
    initial: 'uninitialized',
    states: {
      uninitialized: {
        on: {
          INITIALIZE: {
            target: 'initializing',
            actions: ['initialize']
          }
        }
      },

      initializing: {
        initial: 'creatingReverb',
        states: {
          creatingReverb: {
            invoke: [
              {
                id: 'reverbCreate',
                src: 'reverbCreate',
                onDone: {
                  target: '#audio-environment.initialized',
                  actions: [
                    assign((ctx, event) => {
                      ctx.reverb = event.data
                    }),
                    ({ reverb, gain }): void => {
                      assertIsObject(reverb)
                      assertIsObject(gain)
                      reverb.connect(gain)
                    },
                    'channelsCreate'
                  ]
                }
              }
            ]
          }
        }
      },

      initialized: {
        entry: [
          sendParent(
            (ctx): Events.EmittedInitialized => ({
              type: 'AUDIO_ENVIRONMENT_INITIALIZED',
              audioContext: ctx.audioContext as NonUndefined<
                Context['audioContext']
              >
            })
          )
        ],

        on: {
          CHANNEL_LOAD_REQUEST: {
            actions: ['channelLoad']
          },

          SAMPLE_BUFFER_LOAD_REQUEST: {
            actions: [
              choose<
                Context,
                InstrumentZoneMidiNoteEmittedEventSampleLoadRequest
              >([
                {
                  cond: 'isFirstRequestForSample',
                  actions: [
                    assign((ctx, { sampleId }) => {
                      ctx.samples[sampleId] = spawn(
                        sampleMachine
                      ) as SampleActor
                    }),
                    ({ samples, urlBase, api }, { sampleId }): void => {
                      assertIsObject(api)
                      assertIsString(urlBase)

                      const sampleActor = samples[sampleId]
                      assertIsObject(sampleActor)

                      const sampleJSON = api.samples.find(
                        sampleJSON => sampleJSON.id === sampleId
                      )

                      assertIsObject(sampleJSON)

                      sampleActor.send({
                        type: 'INITIALIZE',
                        urlBase,
                        sampleJSON
                      })
                    }
                  ]
                }
              ]),
              ({ samples }, { sampleId }): void => {
                const sampleActor = samples[sampleId]
                assertIsObject(sampleActor)
                sampleActor.send({ type: 'LOAD_REQUEST' })
              }
            ]
          },

          CHANNEL_INITIALIZED: {
            actions: forwardTo('#_parent')
          },

          CHANNEL_LOADING: {
            actions: sendParent(
              ({ audioContext }, event): Events.EmittedChannelLoading => {
                assertIsObject(audioContext)
                return {
                  ...event,
                  audioContext,
                  type: 'AUDIO_ENVIRONMENT_CHANNEL_LOADING'
                }
              }
            )
          },

          CHANNEL_NOTE_START_REQUEST: {
            actions: ({ channels }, event): void => {
              assertIsObject(channels)
              const channel = channels.get(event.channelNumber)
              assertIsObject(channel)
              channel.send({ ...event, type: 'NOTE_START_REQUEST' })
            }
          },

          CHANNEL_NOTE_RELEASE_REQUEST: {
            actions: ({ channels }, event): void => {
              assertIsObject(channels)
              const channel = channels.get(event.channelNumber)
              assertIsObject(channel)
              channel.send({ ...event, type: 'NOTE_RELEASE_REQUEST' })
            }
          },

          CHANNEL_RELEASE_ALL_REQUEST: {
            actions: ({ channels }, event): void => {
              assertIsObject(channels)
              const channel = channels.get(event.channelNumber)
              assertIsObject(channel)
              channel.send({ ...event, type: 'RELEASE_ALL_REQUEST' })
            }
          },

          CHANNEL_PRESET_CHANGE_REQUEST: {
            actions: ({ channels }, event): void => {
              assertIsObject(channels)
              const channel = channels.get(event.channelNumber)
              assertIsObject(channel)
              channel.send({ ...event, type: 'PRESET_CHANGE_REQUEST' })
            }
          },

          CHANNEL_NOTE_START_SCHEDULED: {
            actions: sendParent(
              (
                { audioContext },
                event
              ): Events.EmittedChannelNoteStartScheduled => {
                assertIsObject(audioContext)
                return {
                  ...event,
                  audioContext,
                  type: 'AUDIO_ENVIRONMENT_CHANNEL_NOTE_START_SCHEDULED'
                }
              }
            )
          },

          CHANNEL_NOTE_RELEASE_SCHEDULED: {
            actions: sendParent(
              (
                { audioContext },
                event
              ): Events.EmittedChannelNoteReleaseScheduled => {
                assertIsObject(audioContext)
                return {
                  ...event,
                  audioContext,
                  type: 'AUDIO_ENVIRONMENT_CHANNEL_NOTE_RELEASE_SCHEDULED'
                }
              }
            )
          },

          CHANNEL_PRESET_LOAD_SUCCESS: {
            actions: sendParent(
              ({ audioContext }, event): Events.EmittedChannelPresetLoaded => {
                assertIsObject(audioContext)
                return {
                  ...event,
                  audioContext,
                  type: 'AUDIO_ENVIRONMENT_CHANNEL_PRESET_LOADED'
                }
              }
            )
          },

          SAMPLE_BUFFER_LOADED: {
            actions: pure(({ channels }, event) => {
              assertIsObject(channels)
              return Array.from(channels).map(([channelNumber]) =>
                send(event, {
                  to: getChannelId(channelNumber)
                })
              )
            })
          }
        }
      },

      failed: {},

      disposed: {
        type: 'final',
        entry: [
          'dispose',
          ({ channels }): void => {
            channels?.forEach(channel => {
              channel.send({ type: 'DISPOSE_REQUEST' })
            })
          }
        ]
      }
    }
  },
  {
    actions: {
      initialize: assign((ctx, event) => {
        const { api, urlBase } = event as Events.Initialize

        ctx.api = api
        ctx.urlBase = urlBase

        const audioContext = new AudioContext({
          latencyHint: 'interactive'
        })

        // note we only do this to get rid of one context
        // should only be done for online context
        context.dispose()
        setContext(audioContext)

        ctx.audioContext = audioContext

        const gain = new Gain({ gain: 0.5, context: audioContext })
        gain.toDestination()
        ctx.gain = gain

        const compressor = new Compressor({ context: audioContext })
        ctx.compressor = compressor

        const chorus = createMasterChorus(audioContext)
        ctx.chorus = chorus

        chorus.chain(compressor, gain)

        ctx.channels = new Map()
      }),

      channelsCreate: assign((ctx): void => {
        const { channels } = ctx
        const { urlBase, gain, api, reverb, chorus, audioContext } = original(
          ctx
        ) as Context

        assertIsObject(api)
        assertIsString(urlBase)
        assertIsObject(chorus)
        assertIsObject(reverb)
        assertIsObject(audioContext)
        assertIsObject(gain)
        assertIsObject(channels)

        MIDI_CHANNELS.forEach(channelNumber => {
          const presetNumber = getChannelPresetNumberDefault(channelNumber)

          const bankId = getChannelBankNumberDefault(channelNumber)

          const channel = spawn(
            channelMachine.withContext({
              id: channelNumber,
              urlBase,
              reverb,
              chorus,
              audioContext,
              presetNumber,
              bankId,
              globalGain: gain,
              samples: api.samples,
              presets: api.presets,
              instruments: api.instruments
            }),
            getChannelId(channelNumber)
          )

          channels.set(channelNumber, channel as ChannelMachineActor)
        })
      }),

      dispose: ({ reverb, compressor, gain, chorus, audioContext }): void => {
        reverb?.dispose()
        gain?.dispose()
        compressor?.dispose()
        chorus?.dispose()
        audioContext?.dispose()
      },

      channelLoad: ({ channels }, event): void => {
        const {
          channelNumber,
          velocityRange,
          keyRange
        } = event as Events.ChannelLoadRequest
        const channel = channels?.get(channelNumber)
        assertIsObject(channel)
        channel.send({
          type: 'PRESET_LOAD_REQUEST',
          velocityRange,
          keyRange
        })
      }
    },
    services: {
      // reverb creation used to be async, but we may as well keep this logic
      // if we switch back to one that is async and doesn't use a convolver node
      reverbCreate: async ({ audioContext }: Context): Promise<Reverb> => {
        assertIsObject(audioContext)
        return await createMasterReverb(audioContext)
      }
    },
    guards: {
      isFirstRequestForSample: ({ samples }, event): boolean =>
        samples[
          (event as InstrumentZoneMidiNoteEmittedEventSampleLoadRequest)
            .sampleId
        ] === undefined
    }
  }
)

export default AudioEnvironmentMachine
