import { ActionFunctionMap, forwardTo } from 'xstate'
import { assertIsObject } from 'assertate'
import { assign } from '@xstate/immer'

import { AppMachineContext, Events, ChannelLoadingState } from '..'
import {
  velocityRangeCreate,
  VELOCITY_SUPPORTED,
  keyRangeCreate,
  MIDI_CHANNELS,
  getChannelBankNumberDefault,
  getChannelPresetNumberDefault,
  CHANNELS_SUPPORTED
} from 'midi-city-shared-types'

import { Events as GlobalEvents } from 'midi-city-sound-engine'

const channelActions: ActionFunctionMap<AppMachineContext, Events.All> = {
  channelsInit: assign(ctx => {
    const { channels } = ctx
    assertIsObject(channels)

    MIDI_CHANNELS.forEach(channelNumber => {
      const bankId = getChannelBankNumberDefault(channelNumber)
      const presetNumber = getChannelPresetNumberDefault(channelNumber)
      channels.set(channelNumber, {
        state: ChannelLoadingState.Uninitialized,
        bankId,
        presetNumber
      })
    })
  }),

  channelSetLoading: assign((ctx, event) => {
    const { channelNumber } = event as Events.GlobalEvents.EmittedChannelLoading
    const channel = ctx.channels?.get(channelNumber)
    assertIsObject(channel)
    channel.state = ChannelLoadingState.Loading
  }),

  channelsSendLoad: (ctx): void => {
    const { channels } = ctx
    assertIsObject(channels)
    channels.forEach((_state, channelNumber) => {
      if (!CHANNELS_SUPPORTED.has(channelNumber)) {
        return
      }
      const { global } = ctx
      assertIsObject(global)
      global.send({
        type: 'CHANNEL_LOAD_REQUEST',
        channelNumber,
        velocityRange: velocityRangeCreate(
          VELOCITY_SUPPORTED,
          VELOCITY_SUPPORTED
        ),
        keyRange: keyRangeCreate(0, 127)
      })
    })
  },

  channelInitialized: assign((ctx, event) => {
    const { channels } = ctx
    const { channelNumber } = event as GlobalEvents.EmittedChannelInitialized

    assertIsObject(channels)

    const channel = channels.get(channelNumber)
    assertIsObject(channel)
    channel.state = ChannelLoadingState.Initialized
  }),

  channelInitializedForwardTracksManager: forwardTo('recording-manager'),
  channelInitializedForwardDeviceManager: forwardTo('midi-device-manager')
}

export default channelActions
