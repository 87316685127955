import { Actor, Interpreter, State } from 'xstate'
import { PresetGeneratorMap, Instrument } from 'midi-city-api'
import {
  InstrumentZoneMidiNoteEmittedEvent,
  InstrumentZoneMidiNoteEmittedEventSampleLoadRequest
} from '../instrument-zone-midi-note'
import {
  KeyRange,
  VelocityRange,
  MidiNote,
  MidiNoteEventSource
} from 'midi-city-shared-types'
import { SampleEmittedEvent } from '../sample'
import {
  InstrumentZoneActor,
  InstrumentZoneEmittedEvent,
  InstrumentZoneEmittedEventConnected,
  InstrumentZoneEmittedEventStartScheduled,
  InstrumentZoneEmittedEventReleaseScheduled
} from '../instrument-zone/types'
import { NonUndefined, Assign } from 'utility-types'
import { Context as AudioContext } from 'tone'

export interface PresetZoneSchema {
  states: {
    uninitialized: {}
    initialized: {
      states: {
        unloaded: {}
        loading: {}
        loaded: {}
        failed: {}
      }
    }
    disposed: {}
  }
}

export enum PresetZoneMidiNoteStatus {
  Unloaded,
  Loading,
  Failed,
  Loaded,
  Scheduled
}

export interface PresetZoneContext {
  instrumentZones: InstrumentZoneActor[]
  audioContext?: AudioContext
  isPercussion?: boolean
  presetZoneJSON?: PresetGeneratorMap
  instrumentJSON?: Instrument
  velocityRangeLoading?: VelocityRange
  midiNoteStatuses: Record<number, PresetZoneMidiNoteStatus>
  keyRangeLoading?: KeyRange
  id: number
}

export interface PresetZoneEventInitialize {
  type: 'INITIALIZE'
  presetZoneJSON: NonUndefined<PresetZoneContext['presetZoneJSON']>
  instrumentJSON: NonUndefined<PresetZoneContext['instrumentJSON']>
  isPercussion: NonUndefined<PresetZoneContext['isPercussion']>
  audioContext: NonUndefined<PresetZoneContext['audioContext']>
}

export interface PresetZoneEventLoadRequest {
  type: 'LOAD_REQUEST'
  keyRange: KeyRange
  velocityRange: VelocityRange
}

export interface PresetZoneEventStartRequest {
  type: 'START_REQUEST'
  midiNote: MidiNote
  velocity: number
  time: number
  source: MidiNoteEventSource
}

export interface PresetZoneEventReleaseRequest {
  type: 'RELEASE_REQUEST'
  midiNote: MidiNote
  time: number
  source: MidiNoteEventSource
}

export interface PresetZoneEventDisposeRequest {
  type: 'DISPOSE_REQUEST'
}

export type PresetZoneEvent =
  | PresetZoneEventInitialize
  | PresetZoneEventLoadRequest
  | PresetZoneEventStartRequest
  | PresetZoneEventReleaseRequest
  | PresetZoneEventDisposeRequest
  | InstrumentZoneMidiNoteEmittedEvent
  | InstrumentZoneMidiNoteEmittedEventSampleLoadRequest
  | SampleEmittedEvent
  | InstrumentZoneEmittedEvent

export type PresetZoneInterpreter = Interpreter<
  PresetZoneContext,
  PresetZoneSchema,
  PresetZoneEvent
>

export type PresetZoneActor = Actor<
  State<PresetZoneContext, PresetZoneEvent>,
  PresetZoneEvent
>

export interface PresetZoneEmittedEventLoaded {
  type: 'PRESET_ZONE_LOADED'
}

export type PresetZoneEmittedEventStartScheduled = Assign<
  InstrumentZoneEmittedEventStartScheduled,
  {
    type: 'PRESET_ZONE_MIDI_NOTE_START_SCHEDULED'
  }
>

export type PresetZoneEmittedEventReleaseScheduled = Assign<
  InstrumentZoneEmittedEventReleaseScheduled,
  {
    type: 'PRESET_ZONE_MIDI_NOTE_RELEASE_SCHEDULED'
  }
>

export type PresetZoneEmittedEventSampleRequest = Assign<
  InstrumentZoneMidiNoteEmittedEventSampleLoadRequest,
  {
    type: 'PRESET_ZONE_SAMPLE_LOAD_REQUEST'
    zoneId: number
  }
>

export type PresetZoneEmittedEvent =
  | PresetZoneEmittedEventLoaded
  | PresetZoneEmittedEventStartScheduled
  | PresetZoneEmittedEventReleaseScheduled
  | PresetZoneEmittedEventSampleRequest
  | InstrumentZoneEmittedEventConnected
