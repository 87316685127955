import moize from 'moize'
import Flatbush from 'flatbush'
import { LayoutRectangle } from 'react-native'
import { times } from 'lodash-es'

import { KeyboardLayoutStyle, KeyboardKeyNumber } from 'midi-city-shared-types'

import { getKeyLayout, getKeyHitBox } from './key'

import { getRowWidth } from './row'

const getLayoutTree = moize(
  (
    numKeys: number,
    layout: LayoutRectangle,
    layoutStyle: KeyboardLayoutStyle
  ) => {
    const tree = new Flatbush(numKeys)
    times(numKeys).forEach(key => {
      const { x, width, y, height } = getKeyLayout(
        key as KeyboardKeyNumber,
        numKeys,
        layout,
        layoutStyle
      )

      tree.add(x, y, x + width, y + height)
    })
    tree.finish()
    return tree
  }
)

const getHitboxTree = moize(
  (
    numKeys: number,
    layout: LayoutRectangle,
    layoutStyle: KeyboardLayoutStyle
  ) => {
    const tree = new Flatbush(numKeys)
    times(numKeys).forEach(key => {
      const { x, width, y, height } = getKeyHitBox(
        key as KeyboardKeyNumber,
        numKeys,
        layout,
        layoutStyle
      )

      tree.add(x, y, x + width, y + height)
    })
    tree.finish()
    return tree
  }
)

export function getLocationKey(
  x: number,
  y: number,
  numKeys: number,
  layout: LayoutRectangle,
  layoutStyle: KeyboardLayoutStyle
): number | null {
  const tree = getHitboxTree(numKeys, layout, layoutStyle)
  const id = tree.search(x, y, x, y)[0]
  if (id !== undefined) {
    return id
  } else {
    return null
  }
}

export function getLocationClosestVisibleKey(
  x: number,
  y: number,
  numKeys: number,
  layout: LayoutRectangle,
  layoutStyle: KeyboardLayoutStyle
): KeyboardKeyNumber | undefined {
  const tree = getLayoutTree(numKeys, layout, layoutStyle)

  const closestItem = tree.neighbors(x, y, 1, 100, key => {
    const keyLayout = getKeyLayout(
      key as KeyboardKeyNumber,
      numKeys,
      layout,
      layoutStyle
    )

    return keyLayout.x >= x
  })[0]

  return closestItem as KeyboardKeyNumber | undefined
}

export function getKeyboardTranslateX(
  layout: LayoutRectangle | undefined,
  keyVisibleStart: KeyboardKeyNumber,
  numKeys: number,
  layoutStyle: KeyboardLayoutStyle
): number {
  if (layout !== undefined) {
    const keyVisibleStartLayout = getKeyLayout(
      keyVisibleStart,
      numKeys,
      layout,
      layoutStyle
    )
    return -keyVisibleStartLayout.x
  } else {
    return 0
  }
}

export function getKeyboardWidth(
  numKeys: number,
  layout: LayoutRectangle | undefined,
  layoutStyle: KeyboardLayoutStyle
): number {
  if (layout === undefined) {
    return 0
  }

  if (layoutStyle === KeyboardLayoutStyle.Single) {
    return getRowWidth(0, numKeys, layout, layoutStyle)
  } else {
    return getRowWidth(1, numKeys, layout, layoutStyle)
  }
}

const getKeysArray = moize((numKeys: number) => times(numKeys))

const getKeysVisible = moize.deep(
  (
    layout: LayoutRectangle,
    keyVisibleStart: KeyboardKeyNumber,
    numKeys: number,
    layoutStyle: KeyboardLayoutStyle
  ): number[] => {
    const x0 = -getKeyboardTranslateX(
      layout,
      keyVisibleStart,
      numKeys,
      layoutStyle
    )

    const x1 = x0 + layout.width
    return getKeysArray(numKeys).filter((key: number): boolean => {
      const layoutKey = getKeyLayout(
        key as KeyboardKeyNumber,
        numKeys,
        layout,
        layoutStyle
      )
      return layoutKey.x >= x0 && layoutKey.x + layoutKey.width <= x1
    })
  }
)

export { getLayoutTree, getKeysVisible }
