import { assertIsObject, assertIsString } from 'assertate'
import { assign, spawn, ActionFunctionMap } from 'xstate'
import { detect } from 'detect-browser'

import { AppMachineContext, Events } from '../types'
import shortcutManagerMachine, {
  ShortcutManagerMachineActor
} from '../../shortcut-manager-machine'

import * as RecordingManager from '../../recording-manager'

import channelActions from './channels'

import { globalMachine, GlobalActor } from 'midi-city-sound-engine'

import midiDeviceManager, {
  Actor as MidiDeviceManagerActor
} from '../../midi-device-manager'
import { choose, pure, send } from 'xstate/lib/actions'

const actions: ActionFunctionMap<AppMachineContext, Events.All> = {
  ...channelActions,

  globalAssign: assign({
    global: (_ctx, _event) =>
      spawn(globalMachine, { name: 'sound-engine' }) as GlobalActor
  }),

  isMobileAssign: assign({
    isMobile: _ctx => {
      const browser = detect()

      if (browser === null) {
        return false
      }

      const isMobileBrowser = ['ios', 'crios', 'fxios', 'ioswebview'].includes(
        browser.name
      )

      return isMobileBrowser
    }
  }),

  globalSendInitialize: ({ global, api, urlBase }: AppMachineContext): void => {
    assertIsObject(global)
    assertIsObject(api)
    assertIsString(urlBase)
    global.send({ type: 'INITIALIZE', urlBase, api })
  },

  midiDeviceManagerCreate: assign({
    midiDeviceManager: ({ recordingAvailable }) => {
      return spawn(
        midiDeviceManager.withContext({
          midiInputs: [],
          recordingAvailable: recordingAvailable ?? false
        }),
        'midi-device-manager'
      ) as MidiDeviceManagerActor
    }
  }),

  midiDeviceManagerInit: ctx => {
    const {
      midiDeviceManager,
      layoutManager,
      shortcutManager,
      notificationManager,
      channels
    } = ctx

    assertIsObject(channels)
    assertIsObject(midiDeviceManager)
    assertIsObject(shortcutManager)
    assertIsObject(layoutManager)
    assertIsObject(notificationManager)

    midiDeviceManager.send({
      type: 'INITIALIZE',
      shortcutManager,
      layoutManager,
      notificationManager
    })
  },

  shortcutManagerAssign: assign({
    shortcutManager: _ctx =>
      spawn(shortcutManagerMachine.withContext({ keyboards: [] }), {
        name: 'shortcut-manager'
      }) as ShortcutManagerMachineActor
  }),

  recordingManagerSpawn: assign({
    recordingManager: ({ urlBase, global }) => {
      assertIsString(urlBase)
      assertIsObject(global)
      return spawn(
        RecordingManager.machine.withContext({
          urlBase,
          autoplay: false,
          bpm: 80,
          duration: '2:0:0'
        }),
        { name: 'recording-manager' }
      ) as RecordingManager.Actor
    }
  }),

  recordingManagerInit: pure(({ recordingManager, global }) => {
    assertIsObject(recordingManager)
    assertIsObject(global)
    const { audioEnvironmentMain } = global.state.context
    assertIsObject(audioEnvironmentMain)

    return send(
      {
        type: 'INITIALIZE',
        audioContext: audioEnvironmentMain.state.context.audioContext
      } as RecordingManager.Events.Initialize,
      { to: 'recording-manager' }
    )
  }),

  recordingManagerLoadDefault: choose([
    {
      actions: send(
        {
          type: 'LOAD_SOURCE_REQUEST',
          id: RecordingManager.MIDI_FILE_INDEX_DEFAULT,
          identifier: RecordingManager.Events.LoadRequestType.Index
        } as RecordingManager.Events.LoadRequest,
        { to: 'recording-manager' }
      ),
      cond: 'isNotMobile'
    }
  ])
}

export default actions
