import { Machine, spawn, assign, sendParent } from 'xstate'
import { assign as assignImmer } from '@xstate/immer'
import { assertIsObject, assertIsNumber, assertIsArray } from 'assertate'
import { every } from 'lodash-es'

import { unexpectedAction, ignoredAction } from 'midi-city-xstate-utils'

import {
  getPresetZonesNonGlobal,
  getPresetZoneGlobal,
  getPresetZonesForRanges
} from './utils'

import {
  PresetMachineEvent,
  PresetMachineEventLoad,
  PresetMachineEmittedEventMidiNoteStartScheduled,
  PresetMachineEmittedEventMidiNoteReleaseScheduled
} from './events'

import {
  PresetMachineContext,
  PresetMachineSchema,
  PresetMidiNoteStatus
} from './types'

import presetGeneratorZoneMachine, {
  PresetZoneEmittedEventStartScheduled,
  DEFAULT_CONTEXT,
  PresetZoneActor
} from '../preset-generator-zone'

import {
  velocityRangeCreate,
  keyRangeCreate,
  VELOCITY_RANGE_FULL,
  VELOCITY_SUPPORTED,
  BANK_ID_PERCUSSION_DEFAULT
} from 'midi-city-shared-types'

import { InstrumentZoneEmittedEventConnected } from '../instrument-zone/types'

import { pure, send } from 'xstate/lib/actions'

export * from './types'

const presetMachine = Machine<
  PresetMachineContext,
  PresetMachineSchema,
  PresetMachineEvent
>(
  {
    id: 'preset-machine',
    strict: true,

    on: {
      DISPOSE_REQUEST: {
        target: 'disposed'
      },
      '*': {
        actions: unexpectedAction
      }
    },

    initial: 'uninitialized',

    states: {
      uninitialized: {
        always: {
          target: 'initialized',
          actions: [
            assignImmer(ctx => {
              ctx.presetZoneSampleRequests = new Map()
            }),
            assign({
              presetZones: ({ json }) => {
                assertIsObject(json)
                const presetZonesJSON = getPresetZonesNonGlobal(json)
                return presetZonesJSON.map(
                  (_zone, index) =>
                    spawn(
                      presetGeneratorZoneMachine.withContext({
                        ...DEFAULT_CONTEXT,
                        id: index
                      }),
                      `zone-${index}`
                    ) as PresetZoneActor
                )
              }
            }),
            'presetZonesInit'
          ]
        }
      },

      initialized: {
        on: {
          LOAD_REQUEST: {
            actions: [
              'loadRequestFwd',
              'velocityRangeLoadingAssign',
              'keyRangeLoadingAssign'
            ]
          },

          PRESET_ZONE_SAMPLE_LOAD_REQUEST: {
            actions: [
              assignImmer((ctx, { zoneId, sampleId }) => {
                const { presetZoneSampleRequests } = ctx
                assertIsObject(presetZoneSampleRequests)

                if (!presetZoneSampleRequests.has(sampleId)) {
                  presetZoneSampleRequests.set(sampleId, new Set())
                }
                const set = presetZoneSampleRequests.get(sampleId)

                assertIsObject(set)

                set.add(zoneId)
              }),
              sendParent((_ctx: PresetMachineContext, event) => event)
            ]
          },

          SAMPLE_BUFFER_LOADED: {
            actions: [
              pure(({ presetZoneSampleRequests }, event) => {
                const { sampleId } = event
                assertIsObject(presetZoneSampleRequests)

                const set = presetZoneSampleRequests.get(sampleId)

                if (set === undefined) {
                  return
                }

                return Array.from(set).map(zoneId =>
                  send(event, { to: `zone-${zoneId}` })
                )
              })
            ]
          },

          PRESET_ZONE_LOADED: [
            {
              actions: [
                assign({
                  velocityRangeActive: ({ velocityRangeLoading }) => {
                    assertIsObject(velocityRangeLoading)
                    return { ...velocityRangeLoading }
                  },

                  keyRangeActive: ({ keyRangeLoading }) => {
                    assertIsObject(keyRangeLoading)
                    return { ...keyRangeLoading }
                  }
                }),

                assignImmer(ctx => {
                  ctx.velocityRangeLoading = undefined
                  ctx.keyRangeLoading = undefined
                }),

                sendParent(
                  ({
                    id,
                    bankId,
                    velocityRangeActive,
                    keyRangeActive
                  }: PresetMachineContext) => {
                    return {
                      type: 'PRESET_LOAD_SUCCESS',
                      presetNumber: id,
                      bankId,
                      velocityRange: velocityRangeActive,
                      keyRange: keyRangeActive
                    }
                  }
                )
              ],
              cond: 'presetZonesAllLoaded'
            },
            {
              actions: ignoredAction
            }
          ],

          NOTE_START_REQUEST: {
            actions: [
              (ctx, event): void => {
                const { json, presetZones } = ctx
                assertIsObject(json)
                assertIsObject(presetZones)

                const { velocity, midiNote, time, source } = event
                const keyRange = keyRangeCreate(midiNote, midiNote)

                // this allows simulating velocity
                const velocityRange = velocityRangeCreate(
                  VELOCITY_SUPPORTED,
                  VELOCITY_SUPPORTED
                )

                const presetZonesNonGlobal = getPresetZonesNonGlobal(json)
                const presetZonesJSON = getPresetZonesForRanges(
                  json,
                  keyRange,
                  velocityRange
                )

                presetZonesJSON.forEach(presetZoneJSON => {
                  const index = presetZonesNonGlobal.indexOf(presetZoneJSON)
                  const presetZoneActor = presetZones[index]
                  assertIsObject(presetZoneActor)
                  presetZoneActor.send({
                    type: 'START_REQUEST',
                    midiNote,
                    velocity,
                    source,
                    time
                  })
                })
              }
            ]
          },

          NOTE_RELEASE_REQUEST: {
            actions: [
              (ctx, event): void => {
                const { json, presetZones } = ctx
                assertIsObject(json)
                assertIsObject(presetZones)

                const { midiNote, time, source } = event
                const keyRange = keyRangeCreate(midiNote, midiNote)

                // OPTIMIZATION store the last played velocity and use that
                const velocityRange = VELOCITY_RANGE_FULL

                const presetZonesNonGlobal = getPresetZonesNonGlobal(json)
                const presetZonesJSON = getPresetZonesForRanges(
                  json,
                  keyRange,
                  velocityRange
                )

                presetZonesJSON.forEach(presetZoneJSON => {
                  const index = presetZonesNonGlobal.indexOf(presetZoneJSON)
                  const presetZoneActor = presetZones[index]
                  assertIsObject(presetZoneActor)
                  presetZoneActor.send({
                    type: 'RELEASE_REQUEST',
                    midiNote,
                    source,
                    time
                  })
                })
              }
            ]
          },

          INSTRUMENT_ZONE_CONNECTED: {
            actions: [
              sendParent<
                PresetMachineContext,
                InstrumentZoneEmittedEventConnected
              >((_ctx, event) => event)
            ]
          },

          PRESET_ZONE_MIDI_NOTE_START_SCHEDULED: [
            {
              actions: [
                assign({
                  midiNoteStatuses: ({ midiNoteStatuses }, event) => {
                    const { midiNote } = event
                    const midiNoteStatus =
                      midiNoteStatuses[midiNote] ??
                      ({
                        velocityRangeLoaded: {},
                        isPlaying: false
                      } as PresetMidiNoteStatus)
                    return {
                      ...midiNoteStatuses,
                      [event.midiNote]: { ...midiNoteStatus, isPlaying: true }
                    }
                  }
                }),
                sendParent(
                  (
                    ctx: PresetMachineContext,
                    event: PresetZoneEmittedEventStartScheduled
                  ): PresetMachineEmittedEventMidiNoteStartScheduled => {
                    const { presetNumber, bankId } = ctx

                    assertIsNumber(presetNumber)
                    assertIsNumber(bankId)

                    return {
                      ...event,
                      type: 'PRESET_MIDI_NOTE_START_SCHEDULED',
                      presetNumber,
                      bankId
                    }
                  }
                )
              ],
              cond: 'midiNoteIsNotPlaying'
            },
            {
              actions: ignoredAction
            }
          ],

          PRESET_ZONE_MIDI_NOTE_RELEASE_SCHEDULED: [
            {
              actions: [
                assign({
                  midiNoteStatuses: ({ midiNoteStatuses }, event) => {
                    const { midiNote } = event
                    const midiNoteStatus =
                      midiNoteStatuses[midiNote] ??
                      ({
                        velocityRangeLoaded: {},
                        isPlaying: false
                      } as PresetMidiNoteStatus)
                    return {
                      ...midiNoteStatuses,
                      [event.midiNote]: { ...midiNoteStatus, isPlaying: false }
                    }
                  }
                }),
                sendParent<PresetMachineContext, any>(
                  (
                    ctx: PresetMachineContext,
                    event: PresetZoneEmittedEventStartScheduled
                  ): PresetMachineEmittedEventMidiNoteReleaseScheduled => {
                    const { presetNumber, bankId } = ctx

                    assertIsNumber(presetNumber)
                    assertIsNumber(bankId)

                    return {
                      ...event,
                      type: 'PRESET_MIDI_NOTE_RELEASE_SCHEDULED',
                      presetNumber,
                      bankId
                    }
                  }
                )
              ],
              cond: 'midiNoteIsPlaying'
            },
            {
              actions: ignoredAction
            }
          ]
        }
      },

      disposed: {
        type: 'final',
        entry: [
          assign({
            presetZones: ({ presetZones }) => {
              assertIsArray(presetZones)
              // this is not pure
              presetZones.forEach(zone =>
                zone.send({ type: 'DISPOSE_REQUEST' })
              )
              return []
            }
          })
        ]
      }
    }
  },
  {
    actions: {
      presetZonesInit: (ctx): void => {
        const { presetZones, instrumentsJSON, json, bankId, audioContext } = ctx
        assertIsObject(json)
        assertIsObject(audioContext)
        assertIsArray(presetZones)

        const presetZoneGlobalJSON = getPresetZoneGlobal(json)

        getPresetZonesNonGlobal(json).forEach((generatorMap, index) => {
          const { instrument } = generatorMap
          assertIsNumber(instrument)
          assertIsObject(instrumentsJSON)

          const instrumentJSON = instrumentsJSON[instrument]
          assertIsObject(instrumentJSON)

          const isPercussion = bankId === BANK_ID_PERCUSSION_DEFAULT

          presetZones[index].send({
            type: 'INITIALIZE',
            presetZoneJSON: { ...presetZoneGlobalJSON, ...generatorMap },
            instrumentJSON,
            isPercussion,
            audioContext
          })
        })
      },

      velocityRangeLoadingAssign: assign({
        velocityRangeLoading: (_ctx: PresetMachineContext, event) => {
          const { velocityRange } = event as PresetMachineEventLoad
          return velocityRange
        }
      }),

      keyRangeLoadingAssign: assign({
        keyRangeLoading: (_ctx: PresetMachineContext, event) => {
          const { keyRange } = event as PresetMachineEventLoad
          return keyRange
        }
      }),

      loadRequestFwd: ({ presetZones, json }, event): void => {
        assertIsObject(json)
        assertIsArray(presetZones)
        const { keyRange, velocityRange } = event as PresetMachineEventLoad

        const presetZonesNonGlobal = getPresetZonesNonGlobal(json)

        const presetZonesMatchingJSON = getPresetZonesForRanges(
          json,
          keyRange,
          velocityRange
        )

        presetZonesMatchingJSON.forEach(zone => {
          const id = presetZonesNonGlobal.indexOf(zone)
          presetZones[id].send(event as PresetMachineEventLoad)
        })
      }
    },

    guards: {
      presetZonesAllLoaded: (
        { json, presetZones, keyRangeLoading, velocityRangeLoading },
        _event
      ): boolean => {
        assertIsArray(presetZones)
        // FIXME this is a very brittle guard because we reach directly into the
        // spawned children

        // this can happen somehow
        if (
          velocityRangeLoading === undefined ||
          keyRangeLoading === undefined
        ) {
          return false
        }
        assertIsObject(json)
        assertIsObject(velocityRangeLoading)
        assertIsObject(keyRangeLoading)

        const presetZonesNonGlobal = getPresetZonesNonGlobal(json)

        const presetZonesLoading = getPresetZonesForRanges(
          json,
          keyRangeLoading,
          velocityRangeLoading
        )

        // BUG this doesn't check that we have loaded only the ones we want
        const presetZonesLoadingMap = presetZonesLoading.map(zone => {
          const id = presetZonesNonGlobal.indexOf(zone)
          return presetZones[id].state.context.keyRangeLoading === undefined
        })

        const presetZonesLoadingAllLoaded = every(presetZonesLoadingMap)

        return presetZonesLoadingAllLoaded
      },

      midiNoteIsPlaying: ({ midiNoteStatuses }, event): boolean => {
        const { midiNote } = event as PresetZoneEmittedEventStartScheduled
        return midiNoteStatuses[midiNote]?.isPlaying ?? false
      },

      midiNoteIsNotPlaying: ({ midiNoteStatuses }, event): boolean => {
        const { midiNote } = event as PresetZoneEmittedEventStartScheduled
        const status = midiNoteStatuses[midiNote]
        return status === undefined || !status.isPlaying
      }
    }
  }
)

export default presetMachine
