import {
  Actor as XActor,
  Interpreter as XInterpreter,
  State as XState,
  MachineConfig as XMachineConfig
} from 'xstate'
import { Context as AudioContext } from 'tone'

import type { MidiResponse } from 'midi-city-api'
import type { ChannelT } from 'midi-city-shared-types'

import * as Events from './events'

import * as MidiTrackManager from '../../midi-track-manager'

export { Events }

export enum RecordingChannelLoadState {
  Unloaded,
  Loading,
  Loaded
}
export interface RecordingChannelState {
  trackSource: MidiTrackManager.Actor
  trackMirror: MidiTrackManager.Actor
  loadState: RecordingChannelLoadState
}

export type ChannelMap = Map<ChannelT, RecordingChannelState>
export type AudioContextChannelMap = Map<AudioContext, ChannelMap>

export interface Context {
  urlBase: string
  autoplay: boolean
  bpm: number
  duration: string
  midis?: MidiResponse[]
  idSelected?: string
  audioContextChannelMap?: AudioContextChannelMap
  midiFileIndexInitial?: number
}

export interface Schema {
  states: {
    uninitialized: {}

    initialized: {
      type: 'parallel'
      states: {
        playState: {
          states: {
            inactive: {}
            active: {
              states: {
                isNotRecording: {}
                isRecording: {}
              }
            }
          }
        }

        remoteFiles: {
          states: {
            unloaded: {}

            loading: {}

            loaded: {}
          }
        }
      }
    }
    disposed: {}
  }
}

export type State = XState<Context, Events.All, Schema>

export type Interpreter = XInterpreter<Context, Schema, Events.All>

export type Actor = XActor<State, Events.All>

export type MachineConfig = XMachineConfig<Context, Schema, Events.All>
