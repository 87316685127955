import {
  Machine,
  Actor as XActor,
  Interpreter as XInterpreter,
  State
} from 'xstate'
import { MidiNote, LayoutRectangle } from 'midi-city-shared-types'
import { assign } from '@xstate/immer'
import { original } from 'immer'
import { assertIsObject, assertIsUndefined } from 'assertate'
import { Part } from '../midi-track-manager'
import { last } from 'lodash-es'

export interface NoteItem {
  x: number
  width?: number
}
export interface NoteRow {
  y: number
  height: number
  items: NoteItem[]
}

export type NoteMap = Map<MidiNote, NoteRow>

interface Context {
  scrubberEnabled: boolean
  scrubberPositionX: number
  layout: LayoutRectangle
  part: Part
  isPlaying: boolean
  progress: number
  noteMap?: NoteMap
}

interface Schema {
  states: {
    ready: {}
  }
}

export interface LayoutUpdateEvent {
  type: 'LAYOUT_UPDATE'
  layout: LayoutRectangle
}

export interface PartUpdatedEvent {
  type: 'PART_UPDATED'
}

export interface PartStarted {
  type: 'PART_STARTED'
}

export interface PartStopped {
  type: 'PART_STOPPED'
}
export interface PartProgressUpdated {
  type: 'PART_PROGRESS_UPDATED'
  progress: number
}

export type Events =
  | LayoutUpdateEvent
  | PartUpdatedEvent
  | PartProgressUpdated
  | PartStarted
  | PartStopped

export type Interpreter = XInterpreter<Context, Schema, Events>

export type Actor = XActor<State<Context, Events>, Events>

const visualizerMachine = Machine<Context, Schema, Events>(
  {
    id: 'visualizer',
    strict: true,
    initial: 'ready',
    states: {
      ready: {
        entry: [
          assign(ctx => (ctx.noteMap = new Map())),
          'visualizerNotesWrite'
        ],
        on: {
          LAYOUT_UPDATE: {
            actions: [
              assign((ctx, { layout }) => (ctx.layout = layout)),
              'visualizerNotesWrite'
            ]
          },

          PART_UPDATED: {
            actions: ['visualizerNotesWrite']
          },

          PART_STARTED: {
            actions: [assign(ctx => (ctx.isPlaying = true))]
          },

          PART_STOPPED: {
            actions: [assign(ctx => (ctx.isPlaying = false))]
          },

          PART_PROGRESS_UPDATED: {
            actions: [
              assign((ctx, { progress }) => (ctx.progress = progress)),
              'scrubberXUpdate'
            ]
          }
        }
      }
    }
  },
  {
    actions: {
      scrubberXUpdate: assign(ctx => {
        ctx.scrubberPositionX = ctx.progress * ctx.layout.width
      }),

      visualizerNotesWrite: assign(ctx => {
        const { layout, part } = original(ctx) as Context

        assertIsObject(part)

        const { noteMap } = ctx

        assertIsObject(noteMap)

        noteMap.clear()

        const { events, loopEnd } = part

        const ticks = part.toTicks(loopEnd)
        const unitsPerTick = layout.width / ticks

        let rowCount = 0

        for (const event of events) {
          const { midiNote, type } = event.value
          const ticks = event.startOffset
          const x = ticks * unitsPerTick

          if (type === 'CHANNEL_NOTE_START_REQUEST') {
            let midiNoteMapItem = noteMap.get(midiNote)
            if (midiNoteMapItem === undefined) {
              midiNoteMapItem = { y: 0, height: 0, items: [] }
              noteMap.set(midiNote, midiNoteMapItem)
              rowCount += 1
            }
            midiNoteMapItem.items.push({ x, width: undefined })
          } else if (type === 'CHANNEL_NOTE_RELEASE_REQUEST') {
            const midiNoteMapItem = noteMap.get(midiNote)
            assertIsObject(midiNoteMapItem)
            const startItem = last(midiNoteMapItem.items)
            assertIsObject(startItem)
            assertIsUndefined(startItem.width)
            startItem.width = x - startItem.x
          } else {
            // no-op
          }
        }

        let y = 0

        const rowHeight = layout.height / rowCount

        noteMap.forEach(row => {
          row.height = rowHeight
          row.y = rowHeight + y
          y += rowHeight
        })
      })
    }
  }
)

export default visualizerMachine
