import { assertIsObject } from 'assertate'

import { AppMachineContext } from '../app-machine'

import { BankIdT, BANK_ID_PERCUSSION_DEFAULT } from 'midi-city-shared-types'
import { ApiResponse, Preset } from 'midi-city-api'
import { ShortcutManagerMachineActor } from '../shortcut-manager-machine'
import { GlobalActor } from 'midi-city-sound-engine'
import { State } from '../recording-manager'

export function getShortcutManager(
  ctx: AppMachineContext
): ShortcutManagerMachineActor {
  const shortcutManager = ctx.shortcutManager
  assertIsObject(shortcutManager)
  return shortcutManager
}

export function getSoundEngine(ctx: AppMachineContext): GlobalActor {
  const { global } = ctx
  assertIsObject(global)
  return global
}

export function getBankIdIsMelodic(bankId: BankIdT): boolean {
  return bankId !== BANK_ID_PERCUSSION_DEFAULT
}

export function getApi(ctx: AppMachineContext): ApiResponse {
  const { api } = ctx
  assertIsObject(api)
  return api
}

export function getPresets(ctx: AppMachineContext): Preset[] {
  return getApi(ctx).presets
}

export function getIsRecording(recordingManagerState: State): boolean {
  return recordingManagerState.matches({
    initialized: { playing: { on: 'isRecording' } }
  })
}
