import { assertIsObject, assertIsNumber } from 'assertate'
import { Machine, assign, sendParent, send } from 'xstate'

import {
  logAction,
  unexpectedAction,
  ignoredAction
} from 'midi-city-xstate-utils'

import {
  InstrumentZoneMidiNoteContext,
  InstrumentZoneMidiNoteSchema,
  InstrumentZoneMidiNoteEvent,
  InstrumentZoneMidiNoteEventStart,
  InstrumentZoneMidiNoteEventRelease,
  InstrumentZoneMidiNoteEmittedEventLoaded,
  InstrumentZoneMidiNoteEmittedEventConnected,
  InstrumentZoneMidiNoteEmittedEventSampleLoadRequest,
  InstrumentZoneMidiNoteEmittedEventDisconnected,
  InstrumentZoneMidiNoteEmittedEventStartScheduled,
  InstrumentZoneMidiNoteEmittedEventReleaseScheduled
} from './types'

import { enableMapSet } from 'immer'

import { getInstrumentZoneAttenuationGain } from '../preset-generator-zone/utils'

import { instrumentZoneMidiNotePlayerCreate } from '../preset-generator-zone/utils/midi-note/instrument-zone/get-player'
import { SampleEmittedEventBufferLoaded } from '../sample'
import { presetZoneCreateEnvelope } from '../preset-generator-zone/utils/midi-note/instrument-zone/get-envelope'
import { isToneTimeError } from 'midi-city-shared-types'
import { warnError, StartTimeError } from '../../errors'

// WARNING: side-effects
enableMapSet()

export const AUTOMATIC_DISCONNECT_TIMEOUT = 6000

export * from './types'

const instrumentZoneMidiNoteMachine = Machine<
  InstrumentZoneMidiNoteContext,
  InstrumentZoneMidiNoteSchema,
  InstrumentZoneMidiNoteEvent
>(
  {
    id: 'instrument-zone-midi-note',
    initial: 'unloaded',

    on: {
      DISPOSE_REQUEST: {
        target: 'disposed'
      },
      '*': {
        actions: [unexpectedAction]
      }
    },

    states: {
      unloaded: {
        on: {
          LOAD_REQUEST: {
            target: 'loading'
          },

          SAMPLE_BUFFER_LOADED: {
            actions: ignoredAction
          },

          START: {
            actions: ignoredAction
          },

          RELEASE: {
            actions: ignoredAction
          }
        }
      },

      loading: {
        entry: ['sampleBufferLoad'],
        on: {
          LOAD_REQUEST: {
            actions: ignoredAction
          },

          SAMPLE_BUFFER_LOADED: {
            target: 'loaded',
            actions: [
              assign({
                sampleJSON: (_ctx, { sampleJSON }) => sampleJSON
              }),
              'nodesInit'
            ]
          }
        }
      },

      loaded: {
        entry: [
          sendParent(
            (
              _ctx: InstrumentZoneMidiNoteContext
            ): InstrumentZoneMidiNoteEmittedEventLoaded => ({
              type: 'INSTRUMENT_ZONE_MIDI_NOTE_LOADED'
            })
          )
        ],

        initial: 'disconnected',

        on: {
          LOAD_REQUEST: {
            actions: ignoredAction
          },
          SAMPLE_BUFFER_LOADED: {
            actions: ignoredAction
          }
        },

        states: {
          disconnected: {
            on: {
              START: {
                actions: [logAction],
                target: ['connected.waitingToSchedule.release']
              },

              RELEASE: {
                actions: ignoredAction
              }
            }
          },

          connected: {
            entry: ['connect', 'reportConnected'],
            exit: ['disconnect', 'reportDisconnected'],

            type: 'parallel',

            on: {
              DISCONNECT: {
                target: 'disconnected'
              },
              // @ts-expect-error
              'done.invoke.release-scheduler': {
                actions: ignoredAction
              },
              'done.invoke.start-scheduler': {
                actions: ignoredAction
              }
            },

            states: {
              waitingToSchedule: {
                initial: 'play',

                states: {
                  play: {
                    on: {
                      START: {
                        target: 'release'
                      },

                      RELEASE: {
                        actions: ignoredAction
                      }
                    }
                  },
                  release: {
                    entry: ['play', 'envelopeStart', 'reportStartScheduled'],
                    exit: ['envelopeRelease', 'reportReleaseScheduled'],
                    on: {
                      RELEASE: {
                        target: 'play'
                      },

                      START: {
                        actions: ignoredAction
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },

      disposed: {
        type: 'final',
        entry: [
          ({ player, envelope }): void => {
            player?.dispose()
            envelope?.dispose()
          }
        ]
      }
    }
  },

  {
    actions: {
      connect: (ctx: InstrumentZoneMidiNoteContext): void => {
        const { player, envelope } = ctx

        assertIsObject(player)
        assertIsObject(envelope)
        player.connect(envelope)
      },

      sampleBufferLoad: sendParent(
        ({
          instrumentZoneJSON
        }: InstrumentZoneMidiNoteContext): InstrumentZoneMidiNoteEmittedEventSampleLoadRequest => {
          const { sampleId } = instrumentZoneJSON
          assertIsNumber(sampleId)
          return {
            type: 'SAMPLE_BUFFER_LOAD_REQUEST',
            sampleId
          }
        }
      ),

      disconnect: (ctx: InstrumentZoneMidiNoteContext): void => {
        const { player } = ctx

        assertIsObject(player)

        player.stop()
        player.disconnect()
      },

      play: (
        { player }: InstrumentZoneMidiNoteContext,
        event: InstrumentZoneMidiNoteEventStart
      ): void => {
        const { timeContext } = event
        assertIsObject(player)

        try {
          player.start(timeContext, 0)
        } catch (error) {
          if (isToneTimeError(error)) {
            warnError(new StartTimeError(error))
            return
          }

          throw error
        }
      },

      reportConnected: sendParent(
        (
          ctx: InstrumentZoneMidiNoteContext
        ): InstrumentZoneMidiNoteEmittedEventConnected => {
          const { envelope } = ctx
          assertIsObject(envelope)
          return {
            type: 'INSTRUMENT_ZONE_MIDI_NOTE_CONNECTED',
            outputNode: envelope
          }
        }
      ),

      reportDisconnected: sendParent(
        (
          _ctx: InstrumentZoneMidiNoteContext
        ): InstrumentZoneMidiNoteEmittedEventDisconnected => {
          return {
            type: 'INSTRUMENT_ZONE_MIDI_NOTE_DISCONNECTED'
          }
        }
      ),

      reportStartScheduled: sendParent(
        (
          { midiNote, audioContext },
          event
        ): InstrumentZoneMidiNoteEmittedEventStartScheduled => {
          const {
            velocity,
            source,
            timeContext
          } = event as InstrumentZoneMidiNoteEventStart

          const timeTransport = audioContext.transport.getSecondsAtTime(
            timeContext
          )

          return {
            type: 'INSTRUMENT_ZONE_MIDI_NOTE_START_SCHEDULED',
            midiNote,
            velocity,
            source,
            timeContextScheduled: timeContext,
            timeTransportScheduled: timeTransport
          }
        }
      ),

      reportReleaseScheduled: sendParent(
        (
          { midiNote, audioContext },
          event
        ): InstrumentZoneMidiNoteEmittedEventReleaseScheduled => {
          const {
            source,
            timeContext
          } = event as InstrumentZoneMidiNoteEventStart

          const timeTransport = audioContext.transport.getSecondsAtTime(
            timeContext
          )

          return {
            type: 'INSTRUMENT_ZONE_MIDI_NOTE_RELEASE_SCHEDULED',
            midiNote,
            source,
            timeContextScheduled: timeContext,
            timeTransportScheduled: timeTransport
          }
        }
      ),

      startAutoDisconnectTimer: send('DISCONNECT', {
        id: 'disconnectTimer',
        delay: AUTOMATIC_DISCONNECT_TIMEOUT
      }),

      nodesInit: assign({
        player: (
          {
            instrumentZoneJSON,
            presetZoneJSON,
            midiNote,
            sampleJSON,
            isPercussion,
            audioContext
          },
          event
        ) => {
          const { buffer } = event as SampleEmittedEventBufferLoaded
          assertIsObject(sampleJSON)
          const player = instrumentZoneMidiNotePlayerCreate(
            buffer,
            instrumentZoneJSON,
            presetZoneJSON,
            midiNote,
            sampleJSON,
            isPercussion,
            audioContext
          )

          // TODO why is this its own method?
          const playerVolume = getInstrumentZoneAttenuationGain(
            presetZoneJSON,
            instrumentZoneJSON
          )

          player.volume.value = playerVolume

          return player
        },

        envelope: ctx =>
          presetZoneCreateEnvelope(
            ctx.presetZoneJSON,
            ctx.instrumentZoneJSON,
            ctx.audioContext
          )
      }),

      envelopeStart: (
        { envelope },
        event: InstrumentZoneMidiNoteEventStart
      ): void => {
        assertIsObject(envelope)
        const { timeContext: time, velocity } = event

        // this allows velocity simulation
        const velocityEnv = velocity / 127

        envelope.triggerAttack(time, velocityEnv)
      },

      envelopeRelease: (
        { envelope },
        event: InstrumentZoneMidiNoteEventRelease
      ): void => {
        assertIsObject(envelope)
        const { timeContext: time } = event
        envelope.triggerRelease(time)
      }
    }
  }
)

export default instrumentZoneMidiNoteMachine
