import moize from 'moize'
import {
  LayoutRectangle,
  KeyboardPosition,
  KeyboardKeyNumber
} from 'midi-city-shared-types'
import { KeyboardMachineState } from '../keyboard-machine'
import { KeyboardKeyMachineActor } from '../keyboard-key-machine'
import { assertIsObject, assertIsNumber } from 'assertate'
import { getLocationClosestVisibleKey } from '../keyboard-machine/utils'
import { getKeyboardKeyDimensions } from './keyboard-key'

export function getKeyboardKeys(
  state: KeyboardMachineState
): KeyboardKeyMachineActor[] {
  return state.context.keys
}

export function getKeyboardKeyVisibleStart(
  state: KeyboardMachineState
): KeyboardKeyNumber {
  const { numKeys, layoutStyle, keysWrapperLayout, scrollX } = state.context

  assertIsObject(keysWrapperLayout)
  assertIsNumber(numKeys)
  assertIsNumber(layoutStyle)

  const keyVisibleStart = getLocationClosestVisibleKey(
    scrollX,
    keysWrapperLayout.height,
    numKeys,
    keysWrapperLayout,
    layoutStyle
  )
  return keyVisibleStart ?? (0 as KeyboardKeyNumber)
}

export function getKeyboardScrollXInitial(state: KeyboardMachineState): number {
  const { keyVisibleStartInitial } = state.context
  assertIsNumber(keyVisibleStartInitial)
  const keyboardKeyActor = getKeyboardKey(keyVisibleStartInitial, state)
  return getKeyboardKeyDimensions(keyboardKeyActor.state).x
}

export function getKeyboardScrollX(state: KeyboardMachineState): number {
  return state.context.scrollX
}

export function getKeyboardPosition(
  state: KeyboardMachineState
): KeyboardPosition {
  const { position } = state.context
  assertIsNumber(position)
  return position
}

export function getKeyboardTrackbarLayout(
  state: KeyboardMachineState
): LayoutRectangle {
  const wrapperLayout = state.context.keysWrapperLayout
  assertIsObject(wrapperLayout)
  return moizedLayout(wrapperLayout.x, wrapperLayout.y, wrapperLayout.width, 48)
}

export function getKeyboardKey(
  keyNumber: KeyboardKeyNumber,
  state: KeyboardMachineState
): KeyboardKeyMachineActor {
  const key = state.context.keys.find(
    keyRef => keyRef.state.context.number === keyNumber
  )
  assertIsObject(key)
  return key
}

const moizedLayout = moize(
  (x: number, y: number, width: number, height: number) => {
    return { x, y, width, height }
  }
)
