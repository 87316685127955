import {
  Part,
  Unit,
  ToneEventOptions,
  ToneEvent,
  TransportTimeClass
} from 'tone'
import { Subject } from 'rxjs'
import { Time } from 'tone/build/esm/core/type/Units'

// These aren't exported from Tone
declare type CallbackType<T> = T extends {
  time: Time
  [key: string]: any
}
  ? T
  : T extends ArrayLike<any>
  ? T[1]
  : T extends Time
  ? null
  : never

interface PartOptions<T>
  extends Omit<ToneEventOptions<CallbackType<T>>, 'value'> {
  events: T[]
}

export default class ObservablePart<
  ValueType extends any
> extends Part<ValueType> {
  constructor(options: Partial<PartOptions<ValueType>>) {
    super(options)
    this.subject = new Subject()
    this.callback = (time, value): void => {
      this.subject.next([time, value])
    }
    // @ts-expect-error
    this.events = this._events
  }

  readonly events: Set<ToneEvent<ValueType>>

  eventsInRange(timeStart: Time, timeEnd: Time): Set<ToneEvent<any>> {
    const timeStartTicks = new TransportTimeClass(
      this.context,
      timeStart
    ).toTicks()

    const timeEndTicks = new TransportTimeClass(this.context, timeEnd).toTicks()

    const eventsInRange = new Set<ToneEvent<any>>()

    // @ts-expect-error
    for (const event of this._events) {
      const isInStartRange = event.startOffset >= timeStartTicks

      if (!isInStartRange) {
        continue
      }

      const isInEndRange = event.startOffset < timeEndTicks

      if (isInEndRange) {
        eventsInRange.add(event)
      } else {
        break
      }
    }

    return eventsInRange
  }

  public subject: Subject<[Unit.Seconds, ValueType]>
}
