import fetch from 'cross-fetch'
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  HttpOptions
} from '@apollo/client/core'

export function build(urlBase: string): ApolloClient<NormalizedCacheObject> {
  const uri = `${urlBase}/graphql`

  const client = new ApolloClient({
    uri,
    ssrMode: true,
    link: createHttpLink({
      // cross-fetch causing some issues here
      // do we even need to use cross-fetch?
      fetch: fetch as HttpOptions['fetch'],
      uri,
      credentials: 'include'
    }),
    cache: new InMemoryCache()
  })

  return client
}
