import { assign } from '@xstate/immer'
import { assertIsNumber, assertIsObject } from 'assertate'
import { ActionFunctionMap, spawn, send } from 'xstate'
import { original } from 'immer'
import log from 'loglevel'

import * as utils from './utils'
import { VirtualControllerMachineContext, Events } from './types'
import {
  BANK_ID_PERCUSSION_DEFAULT,
  KeyboardKeyNumber,
  KeyboardLayoutStyle,
  KeyboardPosition,
  MidiNote
} from 'midi-city-shared-types'
import keyboardMachine, { KeyboardMachineActor } from '../keyboard-machine'
import { PADDING } from '.'
import { PartUpdatedEvent } from '../visualizer'
import { NonUndefined } from 'utility-types'

const PADDING_HORIZONTAL = 16

const warnUnsupportedNote = (midiNote: MidiNote): void => {
  log.warn('Unsupported midi note ', midiNote)
}

const actions: ActionFunctionMap<
  VirtualControllerMachineContext,
  Events.All
> = {
  keyboardInit: assign(ctx => {
    ctx.keyboard = spawn(keyboardMachine) as KeyboardMachineActor
  }),

  othersInit: ({ keyboard, shortcutManager, keyboardLayout, bankId }): void => {
    assertIsObject(keyboard)
    assertIsObject(shortcutManager)
    assertIsObject(keyboardLayout)
    assertIsNumber(bankId)

    const position =
      bankId === BANK_ID_PERCUSSION_DEFAULT
        ? (0 as KeyboardPosition)
        : (1 as KeyboardPosition)

    const layoutStyle =
      bankId === BANK_ID_PERCUSSION_DEFAULT
        ? KeyboardLayoutStyle.Single
        : KeyboardLayoutStyle.Double

    const keyVisibleStartInitial =
      bankId === BANK_ID_PERCUSSION_DEFAULT
        ? (0 as KeyboardKeyNumber)
        : (12 as KeyboardKeyNumber)

    keyboard.send({
      type: 'INITIALIZE',
      layout: keyboardLayout,
      numKeys: utils.getMidiNotes(bankId).length,
      shortcutManager,
      position,
      layoutStyle,
      keyVisibleStartInitial
    })
  },

  contentLayoutUpdate: assign(ctx => {
    const { layout } = ctx
    assertIsObject(layout)
    ctx.contentLayout = {
      ...layout,
      height: layout.height - PADDING * 2,
      width: layout.width - PADDING * 2
    }
  }),

  navLayoutUpdate: assign(ctx => {
    const { contentLayout } = ctx
    assertIsObject(contentLayout)
    ctx.navLayout = { ...contentLayout, height: 64 }
  }),

  keyboardLayoutSend: ({ keyboard, keyboardLayout }): void => {
    assertIsObject(keyboard)
    assertIsObject(keyboardLayout)
    keyboard.send({ type: 'LAYOUT_UPDATE_REQUEST', value: keyboardLayout })
  },

  addToScheduled: assign(({ midiNoteStates }, event) => {
    const { midiNote, timeContext } = event as
      | Events.ChannelNoteStartScheduled
      | Events.ChannelNoteReleaseScheduled

    assertIsObject(midiNoteStates)

    const noteState = midiNoteStates.get(midiNote)

    assertIsObject(noteState)

    noteState.schedule.set(
      timeContext,
      event.type === 'NOTE_START_SCHEDULED' ? 'start' : 'release'
    )
  }),

  keyboardLayoutUpdate: assign(ctx => {
    const { contentLayout, navLayout } = ctx
    assertIsObject(contentLayout)
    assertIsObject(navLayout)
    ctx.keyboardLayout = {
      ...contentLayout,
      height: contentLayout.height - navLayout.height,
      width: contentLayout.width - PADDING_HORIZONTAL * 2
    }
  }),

  partProgressUpdate: assign(ctx => {
    // original is extracted here because of how expensive it is to ready
    // from the proxies verison
    const ctxOriginal = original(ctx)

    const { part } = ctxOriginal as NonUndefined<typeof ctxOriginal>

    ctx.progress = part.progress
  }),

  visualizerSendPartUpdated: send(
    (): PartUpdatedEvent => ({ type: 'PART_UPDATED' }),
    {
      to: 'visualizer'
    }
  ),

  frameUpdate: assign(ctx => {
    // original is extracted here because of how expensive it is to read
    // from the proxies verison
    const ctxOriginal = original(ctx)

    const {
      part,
      bankId,
      keyboard,
      midiNoteStates: midiNoteStatesOrig
    } = ctxOriginal as NonUndefined<typeof ctxOriginal>

    const { midiNoteStates } = ctx

    assertIsObject(midiNoteStates)
    assertIsObject(midiNoteStatesOrig)

    // TODO
    // reaching into progress part just to get the audio context here
    const timeContext = part.context.immediate()
    const timeContextForFrame = timeContext + 0.08

    for (const [midiNote, midiNoteStateOrig] of midiNoteStatesOrig) {
      let keyNumber

      for (const [time, event] of midiNoteStateOrig.schedule) {
        if (time > timeContextForFrame) {
          break
        }

        const midiNoteState = midiNoteStates.get(midiNote)
        assertIsObject(midiNoteState)

        keyNumber = keyNumber ?? utils.getMidiNoteKey(midiNote, bankId)

        midiNoteState.schedule.delete(time)

        if (keyNumber === undefined) {
          warnUnsupportedNote(midiNote)
          return
        }

        ;(keyboard as NonUndefined<typeof keyboard>).send({
          type: event === 'start' ? 'FORCE_KEY_ON' : 'FORCE_KEY_OFF',
          keyNumber
        })
      }
    }
  }),

  forceAllKeysOff: assign(ctx => {
    const { part, midiNoteStates, bankId, keyboard } = ctx

    assertIsObject(keyboard)
    assertIsNumber(bankId)
    assertIsObject(part)
    assertIsObject(midiNoteStates)

    for (const [midiNote, midiNoteState] of midiNoteStates) {
      midiNoteState.schedule.clear()

      const keyNumber = utils.getMidiNoteKey(midiNote, bankId)

      if (keyNumber === undefined) {
        continue
      }

      keyboard.send({
        type: 'FORCE_KEY_OFF',
        keyNumber
      })
    }
  }),

  presetChangeFwdMidiDevice: (ctx, event): void => {
    const { presetNumber, bankId } = event as Events.ChangePreset
    const { midiDevice, channel } = ctx

    assertIsObject(midiDevice)
    assertIsNumber(channel)

    midiDevice.send({
      type: 'MIDI_DEVICE_CHANGE_PRESET',
      presetNumber,
      bankId,
      channelNumber: channel
    })
  },

  presetUpdate: assign((ctx, event) => {
    const { presetNumber, bankId } = event as Events.ChangePreset
    ctx.presetNumber = presetNumber
    ctx.bankId = bankId
  })
}

export default actions
