import { Machine, assign, sendParent } from 'xstate'
import { getSampleAudioBuffer } from './utils'
import { assertIsString, assertIsObject } from 'assertate'
import {
  SampleContext,
  SampleSchema,
  SampleEmittedEventBufferLoaded,
  SampleEvent
} from './types'

export * from './types'

const sampleMachine = Machine<SampleContext, SampleSchema, SampleEvent>(
  {
    id: 'sample-machine',
    strict: true,
    initial: 'uninitialized',
    context: {},
    states: {
      uninitialized: {
        on: {
          INITIALIZE: {
            target: 'initialized',
            actions: [
              assign({
                sampleJSON: (_ctx, { sampleJSON }) => sampleJSON,
                urlBase: (_ctx, { urlBase }) => urlBase
              })
            ]
          }
        }
      },
      initialized: {
        initial: 'unloaded',
        states: {
          unloaded: {
            on: {
              LOAD_REQUEST: {
                target: 'loading'
              }
            }
          },
          loading: {
            invoke: {
              id: 'load-buffer',
              src: async ({ urlBase, sampleJSON }): Promise<AudioBuffer> => {
                assertIsString(urlBase)
                assertIsObject(sampleJSON)
                return await getSampleAudioBuffer(sampleJSON, urlBase)
              },
              onDone: {
                target: 'loaded',
                actions: assign({
                  audioBuffer: (_ctx, { data }) => data
                })
              }
            }
          },
          loaded: {
            entry: 'loadedEmit',
            on: {
              LOAD_REQUEST: {
                actions: 'loadedEmit'
              }
            }
          },
          failed: {}
        }
      }
    }
  },
  {
    actions: {
      loadedEmit: sendParent(
        ({ sampleJSON, audioBuffer }): SampleEmittedEventBufferLoaded => {
          assertIsObject(sampleJSON)
          assertIsObject(audioBuffer)
          return {
            type: 'SAMPLE_BUFFER_LOADED',
            sampleId: sampleJSON.id,
            buffer: audioBuffer,
            sampleJSON: sampleJSON
          }
        }
      )
    }
  }
)

export default sampleMachine
