import GA4React from 'ga-4-react'

const googleAnalytcsTrackingID = process.env.GOOGLE_ANALYTICS_TRACKING_ID

const ga =
  googleAnalytcsTrackingID !== undefined
    ? new GA4React(googleAnalytcsTrackingID ?? 'no-ga', {
        debug_mode: process.env.NODE_ENV !== 'production'
      })
    : undefined

export default ga

export const isInitialized = GA4React.isInitialized
