import { Gain, Context as AudioContext } from 'tone'

import { PresetGeneratorMap, InstrumentZone } from 'midi-city-api'

import stackValues from '../../../stack-values'

export function getInstrumentZoneChorusGain(
  presetZone: PresetGeneratorMap,
  instrumentZone: InstrumentZone,
  context: AudioContext
): Gain {
  const chorus = stackValues('chorusEffectsSend', presetZone, instrumentZone)

  const wet = chorus / 1000
  const gain = new Gain({ gain: wet, context })
  return gain
}
