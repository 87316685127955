import { Interpreter, Actor as XActor, State } from 'xstate'
import { Context as AudioContext } from 'tone'
import { ObservablePart } from 'midi-city-sound-engine'
import { Events as AudioEnvironmentEvents } from 'midi-city-sound-engine/src/machines/audio-environment'

import { Track } from '@tonejs/midi'
import type { ChannelT, MidiNote } from 'midi-city-shared-types'

import * as Events from './events'

export { Events }

export type Part = ObservablePart<PartCallback>

export type PartCallback = (
  | Omit<
      AudioEnvironmentEvents.ChannelNoteStart,
      'time' | 'channelNumber' | 'source'
    >
  | Omit<
      AudioEnvironmentEvents.ChannelNoteRelease,
      'time' | 'channelNumber' | 'source'
    >
) & { to?: string }

export enum TrackType {
  Source,
  Mirror
}

export interface Context {
  channelNumber: ChannelT
  audioContext: AudioContext
  tickMultiplier: number
  quarterNotes: number
  trackType: TrackType
  track?: Track
  part?: Part
  midiNotesRecording?: Set<MidiNote>
}

export interface Schema {
  states: {
    uninitialized: {}
    initialized: {
      states: {
        sourceFile: {
          states: {
            unloaded: {}
            loading: {}
            loaded: {}
          }
        }
        playing: {
          states: {
            inactive: {}
            active: {}
          }
        }
      }
    }
  }
}

export type MTMInterpreter = Interpreter<Context, Schema, Events.All>
export type Actor = XActor<State<Context, Events.All>, Events.All>
