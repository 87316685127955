import { assign } from '@xstate/immer'
import { assertIsString } from 'assertate'
import { buildClient, CreateAppUserDocument } from 'midi-city-server'
import { Machine } from 'xstate'

const URL_BASE = process.env.URL_BASE
assertIsString(URL_BASE)
const apolloClient = buildClient(URL_BASE)

interface Context {
  input?: string
}

const machine = Machine<Context>({
  id: 'login-form',
  strict: true,
  initial: 'invalid',
  context: {
    input: undefined
  },
  on: {
    INPUT_CHANGED: {
      actions: assign((ctx, { value }) => {
        ctx.input = value
      })
    },
    SUBMIT: {
      target: 'loading'
    }
  },
  states: {
    invalid: {},
    loading: {
      invoke: {
        id: 'submit',
        src: async ({ input }): Promise<any> => {
          assertIsString(input)
          return await apolloClient.mutate({
            mutation: CreateAppUserDocument,
            variables: {
              email: input
            }
          })
        },
        onDone: {
          target: 'success'
        }
      }
    },
    success: {}
  }
})

export { machine }
