import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any };
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** Reads and enables pagination through a set of `ContinuityContainer`. */
  allContinuityContainers?: Maybe<ContinuityContainersConnection>;
  /** Reads and enables pagination through a set of `CourierMessage`. */
  allCourierMessages?: Maybe<CourierMessagesConnection>;
  /** Reads and enables pagination through a set of `Identity`. */
  allIdentities?: Maybe<IdentitiesConnection>;
  /** Reads and enables pagination through a set of `IdentityCredentialIdentifier`. */
  allIdentityCredentialIdentifiers?: Maybe<IdentityCredentialIdentifiersConnection>;
  /** Reads and enables pagination through a set of `IdentityCredentialType`. */
  allIdentityCredentialTypes?: Maybe<IdentityCredentialTypesConnection>;
  /** Reads and enables pagination through a set of `IdentityCredential`. */
  allIdentityCredentials?: Maybe<IdentityCredentialsConnection>;
  /** Reads and enables pagination through a set of `IdentityRecoveryAddress`. */
  allIdentityRecoveryAddresses?: Maybe<IdentityRecoveryAddressesConnection>;
  /** Reads and enables pagination through a set of `IdentityRecoveryToken`. */
  allIdentityRecoveryTokens?: Maybe<IdentityRecoveryTokensConnection>;
  /** Reads and enables pagination through a set of `IdentityVerifiableAddress`. */
  allIdentityVerifiableAddresses?: Maybe<IdentityVerifiableAddressesConnection>;
  /** Reads and enables pagination through a set of `IdentityVerificationToken`. */
  allIdentityVerificationTokens?: Maybe<IdentityVerificationTokensConnection>;
  /** Reads and enables pagination through a set of `SchemaMigration`. */
  allSchemaMigrations?: Maybe<SchemaMigrationsConnection>;
  /** Reads and enables pagination through a set of `SelfserviceError`. */
  allSelfserviceErrors?: Maybe<SelfserviceErrorsConnection>;
  /** Reads and enables pagination through a set of `SelfserviceLoginFlowMethod`. */
  allSelfserviceLoginFlowMethods?: Maybe<SelfserviceLoginFlowMethodsConnection>;
  /** Reads and enables pagination through a set of `SelfserviceLoginFlow`. */
  allSelfserviceLoginFlows?: Maybe<SelfserviceLoginFlowsConnection>;
  /** Reads and enables pagination through a set of `SelfserviceRecoveryFlowMethod`. */
  allSelfserviceRecoveryFlowMethods?: Maybe<SelfserviceRecoveryFlowMethodsConnection>;
  /** Reads and enables pagination through a set of `SelfserviceRecoveryFlow`. */
  allSelfserviceRecoveryFlows?: Maybe<SelfserviceRecoveryFlowsConnection>;
  /** Reads and enables pagination through a set of `SelfserviceRegistrationFlowMethod`. */
  allSelfserviceRegistrationFlowMethods?: Maybe<SelfserviceRegistrationFlowMethodsConnection>;
  /** Reads and enables pagination through a set of `SelfserviceRegistrationFlow`. */
  allSelfserviceRegistrationFlows?: Maybe<SelfserviceRegistrationFlowsConnection>;
  /** Reads and enables pagination through a set of `SelfserviceSettingsFlowMethod`. */
  allSelfserviceSettingsFlowMethods?: Maybe<SelfserviceSettingsFlowMethodsConnection>;
  /** Reads and enables pagination through a set of `SelfserviceSettingsFlow`. */
  allSelfserviceSettingsFlows?: Maybe<SelfserviceSettingsFlowsConnection>;
  /** Reads and enables pagination through a set of `SelfserviceVerificationFlowMethod`. */
  allSelfserviceVerificationFlowMethods?: Maybe<SelfserviceVerificationFlowMethodsConnection>;
  /** Reads and enables pagination through a set of `SelfserviceVerificationFlow`. */
  allSelfserviceVerificationFlows?: Maybe<SelfserviceVerificationFlowsConnection>;
  /** Reads and enables pagination through a set of `Session`. */
  allSessions?: Maybe<SessionsConnection>;
  continuityContainerById?: Maybe<ContinuityContainer>;
  courierMessageById?: Maybe<CourierMessage>;
  identityById?: Maybe<Identity>;
  identityCredentialIdentifierById?: Maybe<IdentityCredentialIdentifier>;
  identityCredentialTypeById?: Maybe<IdentityCredentialType>;
  identityCredentialById?: Maybe<IdentityCredential>;
  identityRecoveryAddressById?: Maybe<IdentityRecoveryAddress>;
  identityRecoveryTokenById?: Maybe<IdentityRecoveryToken>;
  identityVerifiableAddressById?: Maybe<IdentityVerifiableAddress>;
  identityVerificationTokenById?: Maybe<IdentityVerificationToken>;
  selfserviceErrorById?: Maybe<SelfserviceError>;
  selfserviceLoginFlowMethodById?: Maybe<SelfserviceLoginFlowMethod>;
  selfserviceLoginFlowById?: Maybe<SelfserviceLoginFlow>;
  selfserviceRecoveryFlowMethodById?: Maybe<SelfserviceRecoveryFlowMethod>;
  selfserviceRecoveryFlowById?: Maybe<SelfserviceRecoveryFlow>;
  selfserviceRegistrationFlowMethodById?: Maybe<SelfserviceRegistrationFlowMethod>;
  selfserviceRegistrationFlowById?: Maybe<SelfserviceRegistrationFlow>;
  selfserviceSettingsFlowMethodById?: Maybe<SelfserviceSettingsFlowMethod>;
  selfserviceSettingsFlowById?: Maybe<SelfserviceSettingsFlow>;
  selfserviceVerificationFlowMethodById?: Maybe<SelfserviceVerificationFlowMethod>;
  selfserviceVerificationFlowById?: Maybe<SelfserviceVerificationFlow>;
  sessionById?: Maybe<Session>;
  getCurrentUser?: Maybe<AppUserSelf>;
  getUsernameIsAvailable?: Maybe<Scalars['Boolean']>;
  usernameIsValidFormat?: Maybe<Scalars['Boolean']>;
  /** Reads a single `ContinuityContainer` using its globally unique `ID`. */
  continuityContainer?: Maybe<ContinuityContainer>;
  /** Reads a single `CourierMessage` using its globally unique `ID`. */
  courierMessage?: Maybe<CourierMessage>;
  /** Reads a single `Identity` using its globally unique `ID`. */
  identity?: Maybe<Identity>;
  /** Reads a single `IdentityCredentialIdentifier` using its globally unique `ID`. */
  identityCredentialIdentifier?: Maybe<IdentityCredentialIdentifier>;
  /** Reads a single `IdentityCredentialType` using its globally unique `ID`. */
  identityCredentialType?: Maybe<IdentityCredentialType>;
  /** Reads a single `IdentityCredential` using its globally unique `ID`. */
  identityCredential?: Maybe<IdentityCredential>;
  /** Reads a single `IdentityRecoveryAddress` using its globally unique `ID`. */
  identityRecoveryAddress?: Maybe<IdentityRecoveryAddress>;
  /** Reads a single `IdentityRecoveryToken` using its globally unique `ID`. */
  identityRecoveryToken?: Maybe<IdentityRecoveryToken>;
  /** Reads a single `IdentityVerifiableAddress` using its globally unique `ID`. */
  identityVerifiableAddress?: Maybe<IdentityVerifiableAddress>;
  /** Reads a single `IdentityVerificationToken` using its globally unique `ID`. */
  identityVerificationToken?: Maybe<IdentityVerificationToken>;
  /** Reads a single `SelfserviceError` using its globally unique `ID`. */
  selfserviceError?: Maybe<SelfserviceError>;
  /** Reads a single `SelfserviceLoginFlowMethod` using its globally unique `ID`. */
  selfserviceLoginFlowMethod?: Maybe<SelfserviceLoginFlowMethod>;
  /** Reads a single `SelfserviceLoginFlow` using its globally unique `ID`. */
  selfserviceLoginFlow?: Maybe<SelfserviceLoginFlow>;
  /** Reads a single `SelfserviceRecoveryFlowMethod` using its globally unique `ID`. */
  selfserviceRecoveryFlowMethod?: Maybe<SelfserviceRecoveryFlowMethod>;
  /** Reads a single `SelfserviceRecoveryFlow` using its globally unique `ID`. */
  selfserviceRecoveryFlow?: Maybe<SelfserviceRecoveryFlow>;
  /** Reads a single `SelfserviceRegistrationFlowMethod` using its globally unique `ID`. */
  selfserviceRegistrationFlowMethod?: Maybe<SelfserviceRegistrationFlowMethod>;
  /** Reads a single `SelfserviceRegistrationFlow` using its globally unique `ID`. */
  selfserviceRegistrationFlow?: Maybe<SelfserviceRegistrationFlow>;
  /** Reads a single `SelfserviceSettingsFlowMethod` using its globally unique `ID`. */
  selfserviceSettingsFlowMethod?: Maybe<SelfserviceSettingsFlowMethod>;
  /** Reads a single `SelfserviceSettingsFlow` using its globally unique `ID`. */
  selfserviceSettingsFlow?: Maybe<SelfserviceSettingsFlow>;
  /** Reads a single `SelfserviceVerificationFlowMethod` using its globally unique `ID`. */
  selfserviceVerificationFlowMethod?: Maybe<SelfserviceVerificationFlowMethod>;
  /** Reads a single `SelfserviceVerificationFlow` using its globally unique `ID`. */
  selfserviceVerificationFlow?: Maybe<SelfserviceVerificationFlow>;
  /** Reads a single `Session` using its globally unique `ID`. */
  session?: Maybe<Session>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAllContinuityContainersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ContinuityContainersOrderBy>>;
  condition?: Maybe<ContinuityContainerCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCourierMessagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CourierMessagesOrderBy>>;
  condition?: Maybe<CourierMessageCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllIdentitiesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdentitiesOrderBy>>;
  condition?: Maybe<IdentityCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllIdentityCredentialIdentifiersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdentityCredentialIdentifiersOrderBy>>;
  condition?: Maybe<IdentityCredentialIdentifierCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllIdentityCredentialTypesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdentityCredentialTypesOrderBy>>;
  condition?: Maybe<IdentityCredentialTypeCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllIdentityCredentialsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdentityCredentialsOrderBy>>;
  condition?: Maybe<IdentityCredentialCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllIdentityRecoveryAddressesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdentityRecoveryAddressesOrderBy>>;
  condition?: Maybe<IdentityRecoveryAddressCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllIdentityRecoveryTokensArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdentityRecoveryTokensOrderBy>>;
  condition?: Maybe<IdentityRecoveryTokenCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllIdentityVerifiableAddressesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdentityVerifiableAddressesOrderBy>>;
  condition?: Maybe<IdentityVerifiableAddressCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllIdentityVerificationTokensArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdentityVerificationTokensOrderBy>>;
  condition?: Maybe<IdentityVerificationTokenCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSchemaMigrationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SchemaMigrationsOrderBy>>;
  condition?: Maybe<SchemaMigrationCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSelfserviceErrorsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelfserviceErrorsOrderBy>>;
  condition?: Maybe<SelfserviceErrorCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSelfserviceLoginFlowMethodsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelfserviceLoginFlowMethodsOrderBy>>;
  condition?: Maybe<SelfserviceLoginFlowMethodCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSelfserviceLoginFlowsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelfserviceLoginFlowsOrderBy>>;
  condition?: Maybe<SelfserviceLoginFlowCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSelfserviceRecoveryFlowMethodsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelfserviceRecoveryFlowMethodsOrderBy>>;
  condition?: Maybe<SelfserviceRecoveryFlowMethodCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSelfserviceRecoveryFlowsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelfserviceRecoveryFlowsOrderBy>>;
  condition?: Maybe<SelfserviceRecoveryFlowCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSelfserviceRegistrationFlowMethodsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelfserviceRegistrationFlowMethodsOrderBy>>;
  condition?: Maybe<SelfserviceRegistrationFlowMethodCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSelfserviceRegistrationFlowsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelfserviceRegistrationFlowsOrderBy>>;
  condition?: Maybe<SelfserviceRegistrationFlowCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSelfserviceSettingsFlowMethodsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelfserviceSettingsFlowMethodsOrderBy>>;
  condition?: Maybe<SelfserviceSettingsFlowMethodCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSelfserviceSettingsFlowsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelfserviceSettingsFlowsOrderBy>>;
  condition?: Maybe<SelfserviceSettingsFlowCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSelfserviceVerificationFlowMethodsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelfserviceVerificationFlowMethodsOrderBy>>;
  condition?: Maybe<SelfserviceVerificationFlowMethodCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSelfserviceVerificationFlowsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SelfserviceVerificationFlowsOrderBy>>;
  condition?: Maybe<SelfserviceVerificationFlowCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSessionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SessionsOrderBy>>;
  condition?: Maybe<SessionCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryContinuityContainerByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCourierMessageByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityCredentialIdentifierByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityCredentialTypeByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityCredentialByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityRecoveryAddressByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityRecoveryTokenByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityVerifiableAddressByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityVerificationTokenByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceErrorByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceLoginFlowMethodByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceLoginFlowByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceRecoveryFlowMethodByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceRecoveryFlowByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceRegistrationFlowMethodByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceRegistrationFlowByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceSettingsFlowMethodByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceSettingsFlowByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceVerificationFlowMethodByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceVerificationFlowByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySessionByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetUsernameIsAvailableArgs = {
  usernameInput: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUsernameIsValidFormatArgs = {
  username?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryContinuityContainerArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCourierMessageArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityCredentialIdentifierArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityCredentialTypeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityCredentialArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityRecoveryAddressArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityRecoveryTokenArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityVerifiableAddressArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIdentityVerificationTokenArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceErrorArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceLoginFlowMethodArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceLoginFlowArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceRecoveryFlowMethodArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceRecoveryFlowArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceRegistrationFlowMethodArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceRegistrationFlowArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceSettingsFlowMethodArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceSettingsFlowArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceVerificationFlowMethodArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySelfserviceVerificationFlowArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySessionArgs = {
  nodeId: Scalars['ID'];
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** A connection to a list of `ContinuityContainer` values. */
export type ContinuityContainersConnection = {
  __typename?: 'ContinuityContainersConnection';
  /** A list of `ContinuityContainer` objects. */
  nodes: Array<ContinuityContainer>;
  /** A list of edges which contains the `ContinuityContainer` and cursor to aid in pagination. */
  edges: Array<ContinuityContainersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ContinuityContainer` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type ContinuityContainer = Node & {
  __typename?: 'ContinuityContainer';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  identityId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
  expiresAt: Scalars['Datetime'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};




/** A `ContinuityContainer` edge in the connection. */
export type ContinuityContainersEdge = {
  __typename?: 'ContinuityContainersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ContinuityContainer` at the end of the edge. */
  node: ContinuityContainer;
};


/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
};

/** Methods to use when ordering `ContinuityContainer`. */
export enum ContinuityContainersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `ContinuityContainer` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ContinuityContainerCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `CourierMessage` values. */
export type CourierMessagesConnection = {
  __typename?: 'CourierMessagesConnection';
  /** A list of `CourierMessage` objects. */
  nodes: Array<CourierMessage>;
  /** A list of edges which contains the `CourierMessage` and cursor to aid in pagination. */
  edges: Array<CourierMessagesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CourierMessage` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type CourierMessage = Node & {
  __typename?: 'CourierMessage';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  type: Scalars['Int'];
  status: Scalars['Int'];
  body: Scalars['String'];
  subject: Scalars['String'];
  recipient: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A `CourierMessage` edge in the connection. */
export type CourierMessagesEdge = {
  __typename?: 'CourierMessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CourierMessage` at the end of the edge. */
  node: CourierMessage;
};

/** Methods to use when ordering `CourierMessage`. */
export enum CourierMessagesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `CourierMessage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CourierMessageCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `Identity` values. */
export type IdentitiesConnection = {
  __typename?: 'IdentitiesConnection';
  /** A list of `Identity` objects. */
  nodes: Array<Identity>;
  /** A list of edges which contains the `Identity` and cursor to aid in pagination. */
  edges: Array<IdentitiesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Identity` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type Identity = Node & {
  __typename?: 'Identity';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  schemaId: Scalars['String'];
  traits: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A `Identity` edge in the connection. */
export type IdentitiesEdge = {
  __typename?: 'IdentitiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Identity` at the end of the edge. */
  node: Identity;
};

/** Methods to use when ordering `Identity`. */
export enum IdentitiesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `Identity` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type IdentityCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `IdentityCredentialIdentifier` values. */
export type IdentityCredentialIdentifiersConnection = {
  __typename?: 'IdentityCredentialIdentifiersConnection';
  /** A list of `IdentityCredentialIdentifier` objects. */
  nodes: Array<IdentityCredentialIdentifier>;
  /** A list of edges which contains the `IdentityCredentialIdentifier` and cursor to aid in pagination. */
  edges: Array<IdentityCredentialIdentifiersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdentityCredentialIdentifier` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type IdentityCredentialIdentifier = Node & {
  __typename?: 'IdentityCredentialIdentifier';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  identifier: Scalars['String'];
  identityCredentialId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A `IdentityCredentialIdentifier` edge in the connection. */
export type IdentityCredentialIdentifiersEdge = {
  __typename?: 'IdentityCredentialIdentifiersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdentityCredentialIdentifier` at the end of the edge. */
  node: IdentityCredentialIdentifier;
};

/** Methods to use when ordering `IdentityCredentialIdentifier`. */
export enum IdentityCredentialIdentifiersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IdentifierAsc = 'IDENTIFIER_ASC',
  IdentifierDesc = 'IDENTIFIER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `IdentityCredentialIdentifier` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type IdentityCredentialIdentifierCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `identifier` field. */
  identifier?: Maybe<Scalars['String']>;
};

/** A connection to a list of `IdentityCredentialType` values. */
export type IdentityCredentialTypesConnection = {
  __typename?: 'IdentityCredentialTypesConnection';
  /** A list of `IdentityCredentialType` objects. */
  nodes: Array<IdentityCredentialType>;
  /** A list of edges which contains the `IdentityCredentialType` and cursor to aid in pagination. */
  edges: Array<IdentityCredentialTypesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdentityCredentialType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type IdentityCredentialType = Node & {
  __typename?: 'IdentityCredentialType';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  name: Scalars['String'];
};

/** A `IdentityCredentialType` edge in the connection. */
export type IdentityCredentialTypesEdge = {
  __typename?: 'IdentityCredentialTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdentityCredentialType` at the end of the edge. */
  node: IdentityCredentialType;
};

/** Methods to use when ordering `IdentityCredentialType`. */
export enum IdentityCredentialTypesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `IdentityCredentialType` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type IdentityCredentialTypeCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
};

/** A connection to a list of `IdentityCredential` values. */
export type IdentityCredentialsConnection = {
  __typename?: 'IdentityCredentialsConnection';
  /** A list of `IdentityCredential` objects. */
  nodes: Array<IdentityCredential>;
  /** A list of edges which contains the `IdentityCredential` and cursor to aid in pagination. */
  edges: Array<IdentityCredentialsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdentityCredential` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type IdentityCredential = Node & {
  __typename?: 'IdentityCredential';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  config: Scalars['JSON'];
  identityCredentialTypeId: Scalars['UUID'];
  identityId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A `IdentityCredential` edge in the connection. */
export type IdentityCredentialsEdge = {
  __typename?: 'IdentityCredentialsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdentityCredential` at the end of the edge. */
  node: IdentityCredential;
};

/** Methods to use when ordering `IdentityCredential`. */
export enum IdentityCredentialsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `IdentityCredential` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type IdentityCredentialCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `IdentityRecoveryAddress` values. */
export type IdentityRecoveryAddressesConnection = {
  __typename?: 'IdentityRecoveryAddressesConnection';
  /** A list of `IdentityRecoveryAddress` objects. */
  nodes: Array<IdentityRecoveryAddress>;
  /** A list of edges which contains the `IdentityRecoveryAddress` and cursor to aid in pagination. */
  edges: Array<IdentityRecoveryAddressesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdentityRecoveryAddress` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type IdentityRecoveryAddress = Node & {
  __typename?: 'IdentityRecoveryAddress';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  via: Scalars['String'];
  value: Scalars['String'];
  identityId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A `IdentityRecoveryAddress` edge in the connection. */
export type IdentityRecoveryAddressesEdge = {
  __typename?: 'IdentityRecoveryAddressesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdentityRecoveryAddress` at the end of the edge. */
  node: IdentityRecoveryAddress;
};

/** Methods to use when ordering `IdentityRecoveryAddress`. */
export enum IdentityRecoveryAddressesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ViaAsc = 'VIA_ASC',
  ViaDesc = 'VIA_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `IdentityRecoveryAddress` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type IdentityRecoveryAddressCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `via` field. */
  via?: Maybe<Scalars['String']>;
};

/** A connection to a list of `IdentityRecoveryToken` values. */
export type IdentityRecoveryTokensConnection = {
  __typename?: 'IdentityRecoveryTokensConnection';
  /** A list of `IdentityRecoveryToken` objects. */
  nodes: Array<IdentityRecoveryToken>;
  /** A list of edges which contains the `IdentityRecoveryToken` and cursor to aid in pagination. */
  edges: Array<IdentityRecoveryTokensEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdentityRecoveryToken` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type IdentityRecoveryToken = Node & {
  __typename?: 'IdentityRecoveryToken';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  token: Scalars['String'];
  used: Scalars['Boolean'];
  usedAt?: Maybe<Scalars['Datetime']>;
  identityRecoveryAddressId: Scalars['UUID'];
  selfserviceRecoveryFlowId?: Maybe<Scalars['UUID']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  expiresAt: Scalars['Datetime'];
  issuedAt: Scalars['Datetime'];
};

/** A `IdentityRecoveryToken` edge in the connection. */
export type IdentityRecoveryTokensEdge = {
  __typename?: 'IdentityRecoveryTokensEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdentityRecoveryToken` at the end of the edge. */
  node: IdentityRecoveryToken;
};

/** Methods to use when ordering `IdentityRecoveryToken`. */
export enum IdentityRecoveryTokensOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TokenAsc = 'TOKEN_ASC',
  TokenDesc = 'TOKEN_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `IdentityRecoveryToken` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type IdentityRecoveryTokenCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `token` field. */
  token?: Maybe<Scalars['String']>;
};

/** A connection to a list of `IdentityVerifiableAddress` values. */
export type IdentityVerifiableAddressesConnection = {
  __typename?: 'IdentityVerifiableAddressesConnection';
  /** A list of `IdentityVerifiableAddress` objects. */
  nodes: Array<IdentityVerifiableAddress>;
  /** A list of edges which contains the `IdentityVerifiableAddress` and cursor to aid in pagination. */
  edges: Array<IdentityVerifiableAddressesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdentityVerifiableAddress` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type IdentityVerifiableAddress = Node & {
  __typename?: 'IdentityVerifiableAddress';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  status: Scalars['String'];
  via: Scalars['String'];
  verified: Scalars['Boolean'];
  value: Scalars['String'];
  verifiedAt?: Maybe<Scalars['Datetime']>;
  identityId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `IdentityVerificationToken`. */
  identityVerificationTokensByIdentityVerifiableAddressId: IdentityVerificationTokensConnection;
};


export type IdentityVerifiableAddressIdentityVerificationTokensByIdentityVerifiableAddressIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdentityVerificationTokensOrderBy>>;
  condition?: Maybe<IdentityVerificationTokenCondition>;
};

/** A connection to a list of `IdentityVerificationToken` values. */
export type IdentityVerificationTokensConnection = {
  __typename?: 'IdentityVerificationTokensConnection';
  /** A list of `IdentityVerificationToken` objects. */
  nodes: Array<IdentityVerificationToken>;
  /** A list of edges which contains the `IdentityVerificationToken` and cursor to aid in pagination. */
  edges: Array<IdentityVerificationTokensEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IdentityVerificationToken` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type IdentityVerificationToken = Node & {
  __typename?: 'IdentityVerificationToken';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  token: Scalars['String'];
  used: Scalars['Boolean'];
  usedAt?: Maybe<Scalars['Datetime']>;
  expiresAt: Scalars['Datetime'];
  issuedAt: Scalars['Datetime'];
  identityVerifiableAddressId: Scalars['UUID'];
  selfserviceVerificationFlowId?: Maybe<Scalars['UUID']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `IdentityVerifiableAddress` that is related to this `IdentityVerificationToken`. */
  identityVerifiableAddressByIdentityVerifiableAddressId?: Maybe<IdentityVerifiableAddress>;
  /** Reads a single `SelfserviceVerificationFlow` that is related to this `IdentityVerificationToken`. */
  selfserviceVerificationFlowBySelfserviceVerificationFlowId?: Maybe<SelfserviceVerificationFlow>;
};

export type SelfserviceVerificationFlow = Node & {
  __typename?: 'SelfserviceVerificationFlow';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  requestUrl: Scalars['String'];
  issuedAt: Scalars['Datetime'];
  expiresAt: Scalars['Datetime'];
  csrfToken: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  messages?: Maybe<Scalars['JSON']>;
  type: Scalars['String'];
  state: Scalars['String'];
  activeMethod?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `IdentityVerificationToken`. */
  identityVerificationTokensBySelfserviceVerificationFlowId: IdentityVerificationTokensConnection;
};


export type SelfserviceVerificationFlowIdentityVerificationTokensBySelfserviceVerificationFlowIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<IdentityVerificationTokensOrderBy>>;
  condition?: Maybe<IdentityVerificationTokenCondition>;
};

/** Methods to use when ordering `IdentityVerificationToken`. */
export enum IdentityVerificationTokensOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TokenAsc = 'TOKEN_ASC',
  TokenDesc = 'TOKEN_DESC',
  IdentityVerifiableAddressIdAsc = 'IDENTITY_VERIFIABLE_ADDRESS_ID_ASC',
  IdentityVerifiableAddressIdDesc = 'IDENTITY_VERIFIABLE_ADDRESS_ID_DESC',
  SelfserviceVerificationFlowIdAsc = 'SELFSERVICE_VERIFICATION_FLOW_ID_ASC',
  SelfserviceVerificationFlowIdDesc = 'SELFSERVICE_VERIFICATION_FLOW_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `IdentityVerificationToken` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type IdentityVerificationTokenCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `token` field. */
  token?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `identityVerifiableAddressId` field. */
  identityVerifiableAddressId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `selfserviceVerificationFlowId` field. */
  selfserviceVerificationFlowId?: Maybe<Scalars['UUID']>;
};

/** A `IdentityVerificationToken` edge in the connection. */
export type IdentityVerificationTokensEdge = {
  __typename?: 'IdentityVerificationTokensEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdentityVerificationToken` at the end of the edge. */
  node: IdentityVerificationToken;
};

/** A `IdentityVerifiableAddress` edge in the connection. */
export type IdentityVerifiableAddressesEdge = {
  __typename?: 'IdentityVerifiableAddressesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IdentityVerifiableAddress` at the end of the edge. */
  node: IdentityVerifiableAddress;
};

/** Methods to use when ordering `IdentityVerifiableAddress`. */
export enum IdentityVerifiableAddressesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ViaAsc = 'VIA_ASC',
  ViaDesc = 'VIA_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `IdentityVerifiableAddress` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type IdentityVerifiableAddressCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `via` field. */
  via?: Maybe<Scalars['String']>;
};

/** A connection to a list of `SchemaMigration` values. */
export type SchemaMigrationsConnection = {
  __typename?: 'SchemaMigrationsConnection';
  /** A list of `SchemaMigration` objects. */
  nodes: Array<SchemaMigration>;
  /** A list of edges which contains the `SchemaMigration` and cursor to aid in pagination. */
  edges: Array<SchemaMigrationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SchemaMigration` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SchemaMigration = {
  __typename?: 'SchemaMigration';
  version: Scalars['String'];
};

/** A `SchemaMigration` edge in the connection. */
export type SchemaMigrationsEdge = {
  __typename?: 'SchemaMigrationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SchemaMigration` at the end of the edge. */
  node: SchemaMigration;
};

/** Methods to use when ordering `SchemaMigration`. */
export enum SchemaMigrationsOrderBy {
  Natural = 'NATURAL',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC'
}

/**
 * A condition to be used against `SchemaMigration` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SchemaMigrationCondition = {
  /** Checks for equality with the object’s `version` field. */
  version?: Maybe<Scalars['String']>;
};

/** A connection to a list of `SelfserviceError` values. */
export type SelfserviceErrorsConnection = {
  __typename?: 'SelfserviceErrorsConnection';
  /** A list of `SelfserviceError` objects. */
  nodes: Array<SelfserviceError>;
  /** A list of edges which contains the `SelfserviceError` and cursor to aid in pagination. */
  edges: Array<SelfserviceErrorsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelfserviceError` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SelfserviceError = Node & {
  __typename?: 'SelfserviceError';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  errors: Scalars['JSON'];
  seenAt?: Maybe<Scalars['Datetime']>;
  wasSeen: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  csrfToken: Scalars['String'];
};

/** A `SelfserviceError` edge in the connection. */
export type SelfserviceErrorsEdge = {
  __typename?: 'SelfserviceErrorsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelfserviceError` at the end of the edge. */
  node: SelfserviceError;
};

/** Methods to use when ordering `SelfserviceError`. */
export enum SelfserviceErrorsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SelfserviceError` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SelfserviceErrorCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `SelfserviceLoginFlowMethod` values. */
export type SelfserviceLoginFlowMethodsConnection = {
  __typename?: 'SelfserviceLoginFlowMethodsConnection';
  /** A list of `SelfserviceLoginFlowMethod` objects. */
  nodes: Array<SelfserviceLoginFlowMethod>;
  /** A list of edges which contains the `SelfserviceLoginFlowMethod` and cursor to aid in pagination. */
  edges: Array<SelfserviceLoginFlowMethodsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelfserviceLoginFlowMethod` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SelfserviceLoginFlowMethod = Node & {
  __typename?: 'SelfserviceLoginFlowMethod';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  method: Scalars['String'];
  selfserviceLoginFlowId: Scalars['UUID'];
  config: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A `SelfserviceLoginFlowMethod` edge in the connection. */
export type SelfserviceLoginFlowMethodsEdge = {
  __typename?: 'SelfserviceLoginFlowMethodsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelfserviceLoginFlowMethod` at the end of the edge. */
  node: SelfserviceLoginFlowMethod;
};

/** Methods to use when ordering `SelfserviceLoginFlowMethod`. */
export enum SelfserviceLoginFlowMethodsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SelfserviceLoginFlowMethod` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type SelfserviceLoginFlowMethodCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `SelfserviceLoginFlow` values. */
export type SelfserviceLoginFlowsConnection = {
  __typename?: 'SelfserviceLoginFlowsConnection';
  /** A list of `SelfserviceLoginFlow` objects. */
  nodes: Array<SelfserviceLoginFlow>;
  /** A list of edges which contains the `SelfserviceLoginFlow` and cursor to aid in pagination. */
  edges: Array<SelfserviceLoginFlowsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelfserviceLoginFlow` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SelfserviceLoginFlow = Node & {
  __typename?: 'SelfserviceLoginFlow';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  requestUrl: Scalars['String'];
  issuedAt: Scalars['Datetime'];
  expiresAt: Scalars['Datetime'];
  activeMethod: Scalars['String'];
  csrfToken: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  forced: Scalars['Boolean'];
  messages?: Maybe<Scalars['JSON']>;
  type: Scalars['String'];
};

/** A `SelfserviceLoginFlow` edge in the connection. */
export type SelfserviceLoginFlowsEdge = {
  __typename?: 'SelfserviceLoginFlowsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelfserviceLoginFlow` at the end of the edge. */
  node: SelfserviceLoginFlow;
};

/** Methods to use when ordering `SelfserviceLoginFlow`. */
export enum SelfserviceLoginFlowsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SelfserviceLoginFlow` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type SelfserviceLoginFlowCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `SelfserviceRecoveryFlowMethod` values. */
export type SelfserviceRecoveryFlowMethodsConnection = {
  __typename?: 'SelfserviceRecoveryFlowMethodsConnection';
  /** A list of `SelfserviceRecoveryFlowMethod` objects. */
  nodes: Array<SelfserviceRecoveryFlowMethod>;
  /** A list of edges which contains the `SelfserviceRecoveryFlowMethod` and cursor to aid in pagination. */
  edges: Array<SelfserviceRecoveryFlowMethodsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelfserviceRecoveryFlowMethod` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SelfserviceRecoveryFlowMethod = Node & {
  __typename?: 'SelfserviceRecoveryFlowMethod';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  method: Scalars['String'];
  config: Scalars['JSON'];
  selfserviceRecoveryFlowId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A `SelfserviceRecoveryFlowMethod` edge in the connection. */
export type SelfserviceRecoveryFlowMethodsEdge = {
  __typename?: 'SelfserviceRecoveryFlowMethodsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelfserviceRecoveryFlowMethod` at the end of the edge. */
  node: SelfserviceRecoveryFlowMethod;
};

/** Methods to use when ordering `SelfserviceRecoveryFlowMethod`. */
export enum SelfserviceRecoveryFlowMethodsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SelfserviceRecoveryFlowMethod` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type SelfserviceRecoveryFlowMethodCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `SelfserviceRecoveryFlow` values. */
export type SelfserviceRecoveryFlowsConnection = {
  __typename?: 'SelfserviceRecoveryFlowsConnection';
  /** A list of `SelfserviceRecoveryFlow` objects. */
  nodes: Array<SelfserviceRecoveryFlow>;
  /** A list of edges which contains the `SelfserviceRecoveryFlow` and cursor to aid in pagination. */
  edges: Array<SelfserviceRecoveryFlowsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelfserviceRecoveryFlow` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SelfserviceRecoveryFlow = Node & {
  __typename?: 'SelfserviceRecoveryFlow';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  requestUrl: Scalars['String'];
  issuedAt: Scalars['Datetime'];
  expiresAt: Scalars['Datetime'];
  messages?: Maybe<Scalars['JSON']>;
  activeMethod?: Maybe<Scalars['String']>;
  csrfToken: Scalars['String'];
  state: Scalars['String'];
  recoveredIdentityId?: Maybe<Scalars['UUID']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  type: Scalars['String'];
};

/** A `SelfserviceRecoveryFlow` edge in the connection. */
export type SelfserviceRecoveryFlowsEdge = {
  __typename?: 'SelfserviceRecoveryFlowsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelfserviceRecoveryFlow` at the end of the edge. */
  node: SelfserviceRecoveryFlow;
};

/** Methods to use when ordering `SelfserviceRecoveryFlow`. */
export enum SelfserviceRecoveryFlowsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SelfserviceRecoveryFlow` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type SelfserviceRecoveryFlowCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `SelfserviceRegistrationFlowMethod` values. */
export type SelfserviceRegistrationFlowMethodsConnection = {
  __typename?: 'SelfserviceRegistrationFlowMethodsConnection';
  /** A list of `SelfserviceRegistrationFlowMethod` objects. */
  nodes: Array<SelfserviceRegistrationFlowMethod>;
  /** A list of edges which contains the `SelfserviceRegistrationFlowMethod` and cursor to aid in pagination. */
  edges: Array<SelfserviceRegistrationFlowMethodsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelfserviceRegistrationFlowMethod` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SelfserviceRegistrationFlowMethod = Node & {
  __typename?: 'SelfserviceRegistrationFlowMethod';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  method: Scalars['String'];
  selfserviceRegistrationFlowId: Scalars['UUID'];
  config: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A `SelfserviceRegistrationFlowMethod` edge in the connection. */
export type SelfserviceRegistrationFlowMethodsEdge = {
  __typename?: 'SelfserviceRegistrationFlowMethodsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelfserviceRegistrationFlowMethod` at the end of the edge. */
  node: SelfserviceRegistrationFlowMethod;
};

/** Methods to use when ordering `SelfserviceRegistrationFlowMethod`. */
export enum SelfserviceRegistrationFlowMethodsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SelfserviceRegistrationFlowMethod` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type SelfserviceRegistrationFlowMethodCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `SelfserviceRegistrationFlow` values. */
export type SelfserviceRegistrationFlowsConnection = {
  __typename?: 'SelfserviceRegistrationFlowsConnection';
  /** A list of `SelfserviceRegistrationFlow` objects. */
  nodes: Array<SelfserviceRegistrationFlow>;
  /** A list of edges which contains the `SelfserviceRegistrationFlow` and cursor to aid in pagination. */
  edges: Array<SelfserviceRegistrationFlowsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelfserviceRegistrationFlow` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SelfserviceRegistrationFlow = Node & {
  __typename?: 'SelfserviceRegistrationFlow';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  requestUrl: Scalars['String'];
  issuedAt: Scalars['Datetime'];
  expiresAt: Scalars['Datetime'];
  activeMethod: Scalars['String'];
  csrfToken: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  messages?: Maybe<Scalars['JSON']>;
  type: Scalars['String'];
};

/** A `SelfserviceRegistrationFlow` edge in the connection. */
export type SelfserviceRegistrationFlowsEdge = {
  __typename?: 'SelfserviceRegistrationFlowsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelfserviceRegistrationFlow` at the end of the edge. */
  node: SelfserviceRegistrationFlow;
};

/** Methods to use when ordering `SelfserviceRegistrationFlow`. */
export enum SelfserviceRegistrationFlowsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SelfserviceRegistrationFlow` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type SelfserviceRegistrationFlowCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `SelfserviceSettingsFlowMethod` values. */
export type SelfserviceSettingsFlowMethodsConnection = {
  __typename?: 'SelfserviceSettingsFlowMethodsConnection';
  /** A list of `SelfserviceSettingsFlowMethod` objects. */
  nodes: Array<SelfserviceSettingsFlowMethod>;
  /** A list of edges which contains the `SelfserviceSettingsFlowMethod` and cursor to aid in pagination. */
  edges: Array<SelfserviceSettingsFlowMethodsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelfserviceSettingsFlowMethod` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SelfserviceSettingsFlowMethod = Node & {
  __typename?: 'SelfserviceSettingsFlowMethod';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  method: Scalars['String'];
  selfserviceSettingsFlowId: Scalars['UUID'];
  config: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A `SelfserviceSettingsFlowMethod` edge in the connection. */
export type SelfserviceSettingsFlowMethodsEdge = {
  __typename?: 'SelfserviceSettingsFlowMethodsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelfserviceSettingsFlowMethod` at the end of the edge. */
  node: SelfserviceSettingsFlowMethod;
};

/** Methods to use when ordering `SelfserviceSettingsFlowMethod`. */
export enum SelfserviceSettingsFlowMethodsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SelfserviceSettingsFlowMethod` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type SelfserviceSettingsFlowMethodCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `SelfserviceSettingsFlow` values. */
export type SelfserviceSettingsFlowsConnection = {
  __typename?: 'SelfserviceSettingsFlowsConnection';
  /** A list of `SelfserviceSettingsFlow` objects. */
  nodes: Array<SelfserviceSettingsFlow>;
  /** A list of edges which contains the `SelfserviceSettingsFlow` and cursor to aid in pagination. */
  edges: Array<SelfserviceSettingsFlowsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelfserviceSettingsFlow` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SelfserviceSettingsFlow = Node & {
  __typename?: 'SelfserviceSettingsFlow';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  requestUrl: Scalars['String'];
  issuedAt: Scalars['Datetime'];
  expiresAt: Scalars['Datetime'];
  identityId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  activeMethod?: Maybe<Scalars['String']>;
  messages?: Maybe<Scalars['JSON']>;
  state: Scalars['String'];
  type: Scalars['String'];
};

/** A `SelfserviceSettingsFlow` edge in the connection. */
export type SelfserviceSettingsFlowsEdge = {
  __typename?: 'SelfserviceSettingsFlowsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelfserviceSettingsFlow` at the end of the edge. */
  node: SelfserviceSettingsFlow;
};

/** Methods to use when ordering `SelfserviceSettingsFlow`. */
export enum SelfserviceSettingsFlowsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SelfserviceSettingsFlow` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type SelfserviceSettingsFlowCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `SelfserviceVerificationFlowMethod` values. */
export type SelfserviceVerificationFlowMethodsConnection = {
  __typename?: 'SelfserviceVerificationFlowMethodsConnection';
  /** A list of `SelfserviceVerificationFlowMethod` objects. */
  nodes: Array<SelfserviceVerificationFlowMethod>;
  /** A list of edges which contains the `SelfserviceVerificationFlowMethod` and cursor to aid in pagination. */
  edges: Array<SelfserviceVerificationFlowMethodsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelfserviceVerificationFlowMethod` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type SelfserviceVerificationFlowMethod = Node & {
  __typename?: 'SelfserviceVerificationFlowMethod';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  method: Scalars['String'];
  selfserviceVerificationFlowId: Scalars['UUID'];
  config: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A `SelfserviceVerificationFlowMethod` edge in the connection. */
export type SelfserviceVerificationFlowMethodsEdge = {
  __typename?: 'SelfserviceVerificationFlowMethodsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelfserviceVerificationFlowMethod` at the end of the edge. */
  node: SelfserviceVerificationFlowMethod;
};

/** Methods to use when ordering `SelfserviceVerificationFlowMethod`. */
export enum SelfserviceVerificationFlowMethodsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SelfserviceVerificationFlowMethod` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type SelfserviceVerificationFlowMethodCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `SelfserviceVerificationFlow` values. */
export type SelfserviceVerificationFlowsConnection = {
  __typename?: 'SelfserviceVerificationFlowsConnection';
  /** A list of `SelfserviceVerificationFlow` objects. */
  nodes: Array<SelfserviceVerificationFlow>;
  /** A list of edges which contains the `SelfserviceVerificationFlow` and cursor to aid in pagination. */
  edges: Array<SelfserviceVerificationFlowsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SelfserviceVerificationFlow` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SelfserviceVerificationFlow` edge in the connection. */
export type SelfserviceVerificationFlowsEdge = {
  __typename?: 'SelfserviceVerificationFlowsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SelfserviceVerificationFlow` at the end of the edge. */
  node: SelfserviceVerificationFlow;
};

/** Methods to use when ordering `SelfserviceVerificationFlow`. */
export enum SelfserviceVerificationFlowsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SelfserviceVerificationFlow` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type SelfserviceVerificationFlowCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `Session` values. */
export type SessionsConnection = {
  __typename?: 'SessionsConnection';
  /** A list of `Session` objects. */
  nodes: Array<Session>;
  /** A list of edges which contains the `Session` and cursor to aid in pagination. */
  edges: Array<SessionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Session` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type Session = Node & {
  __typename?: 'Session';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  issuedAt: Scalars['Datetime'];
  expiresAt: Scalars['Datetime'];
  authenticatedAt: Scalars['Datetime'];
  identityId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  token?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
};

/** A `Session` edge in the connection. */
export type SessionsEdge = {
  __typename?: 'SessionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Session` at the end of the edge. */
  node: Session;
};

/** Methods to use when ordering `Session`. */
export enum SessionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TokenAsc = 'TOKEN_ASC',
  TokenDesc = 'TOKEN_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A condition to be used against `Session` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SessionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `token` field. */
  token?: Maybe<Scalars['String']>;
};

export type AppUserSelf = {
  __typename?: 'AppUserSelf';
  uuid?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  emailIsVerified?: Maybe<Scalars['Boolean']>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  authenticateWithEmailToken?: Maybe<AuthenticateWithEmailTokenPayload>;
  createUserWithEmail?: Maybe<CreateUserWithEmailPayload>;
  updateAppUserSelf?: Maybe<UpdateAppUserSelfPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAuthenticateWithEmailTokenArgs = {
  input: AuthenticateWithEmailTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserWithEmailArgs = {
  input: CreateUserWithEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAppUserSelfArgs = {
  input: UpdateAppUserSelfInput;
};

/** The output of our `authenticateWithEmailToken` mutation. */
export type AuthenticateWithEmailTokenPayload = {
  __typename?: 'AuthenticateWithEmailTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  sessionSecretToken?: Maybe<Scalars['UUID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `authenticateWithEmailToken` mutation. */
export type AuthenticateWithEmailTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  emailToken: Scalars['UUID'];
};

/** The output of our `createUserWithEmail` mutation. */
export type CreateUserWithEmailPayload = {
  __typename?: 'CreateUserWithEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  appUserSelf?: Maybe<AppUserSelf>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createUserWithEmail` mutation. */
export type CreateUserWithEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  emailInput: Scalars['String'];
};

/** The output of our `updateAppUserSelf` mutation. */
export type UpdateAppUserSelfPayload = {
  __typename?: 'UpdateAppUserSelfPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  appUserSelf?: Maybe<AppUserSelf>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateAppUserSelf` mutation. */
export type UpdateAppUserSelfInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  args: AppUserSelfArgInput;
};

/** An input for mutations affecting `AppUserSelfArg` */
export type AppUserSelfArgInput = {
  username?: Maybe<Scalars['String']>;
};

export type AuthenticateTokenMutationVariables = Exact<{
  token: Scalars['UUID'];
}>;


export type AuthenticateTokenMutation = (
  { __typename?: 'Mutation' }
  & { authenticateWithEmailToken?: Maybe<(
    { __typename?: 'AuthenticateWithEmailTokenPayload' }
    & Pick<AuthenticateWithEmailTokenPayload, 'sessionSecretToken'>
  )> }
);

export type CreateAppUserMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type CreateAppUserMutation = (
  { __typename?: 'Mutation' }
  & { createUserWithEmail?: Maybe<(
    { __typename?: 'CreateUserWithEmailPayload' }
    & { appUserSelf?: Maybe<(
      { __typename?: 'AppUserSelf' }
      & Pick<AppUserSelf, 'uuid' | 'email'>
    )> }
  )> }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { getCurrentUser?: Maybe<(
    { __typename?: 'AppUserSelf' }
    & Pick<AppUserSelf, 'email' | 'username' | 'emailIsVerified'>
  )> }
);

export type GetUsernameIsAvailableQueryVariables = Exact<{
  usernameInput: Scalars['String'];
}>;


export type GetUsernameIsAvailableQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUsernameIsAvailable'>
);

export type UpdateAppUserSelfMutationVariables = Exact<{
  username: Scalars['String'];
}>;


export type UpdateAppUserSelfMutation = (
  { __typename?: 'Mutation' }
  & { updateAppUserSelf?: Maybe<(
    { __typename?: 'UpdateAppUserSelfPayload' }
    & { appUserSelf?: Maybe<(
      { __typename?: 'AppUserSelf' }
      & Pick<AppUserSelf, 'uuid' | 'email' | 'username'>
    )> }
  )> }
);


export const AuthenticateTokenDocument: DocumentNode<AuthenticateTokenMutation, AuthenticateTokenMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AuthenticateToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"authenticateWithEmailToken"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"emailToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sessionSecretToken"}}]}}]}}]};
export const CreateAppUserDocument: DocumentNode<CreateAppUserMutation, CreateAppUserMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateAppUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createUserWithEmail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"emailInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"appUserSelf"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]}}]};
export const GetCurrentUserDocument: DocumentNode<GetCurrentUserQuery, GetCurrentUserQueryVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCurrentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCurrentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"username"}},{"kind":"Field","name":{"kind":"Name","value":"emailIsVerified"}}]}}]}}]};
export const GetUsernameIsAvailableDocument: DocumentNode<GetUsernameIsAvailableQuery, GetUsernameIsAvailableQueryVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetUsernameIsAvailable"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"usernameInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getUsernameIsAvailable"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"usernameInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"usernameInput"}}}]}]}}]};
export const UpdateAppUserSelfDocument: DocumentNode<UpdateAppUserSelfMutation, UpdateAppUserSelfMutationVariables> = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateAppUserSelf"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"username"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateAppUserSelf"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"args"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"username"},"value":{"kind":"Variable","name":{"kind":"Name","value":"username"}}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"appUserSelf"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"username"}}]}}]}}]}}]};