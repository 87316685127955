import { ConditionPredicate } from 'xstate'
import { Context, Events } from './types'

const isMatchingChannel: ConditionPredicate<Context, Events.All> = (
  _context,
  event
) => {
  if (
    event.type !== 'CHANNEL_LOAD_SUCCESS' &&
    event.type !== 'CHANNEL_LOADING'
  ) {
    throw new Error('isMatchingChannel used for wrong event')
  }
  return true
}

export { isMatchingChannel }
