import { Panner, Context as AudioContext } from 'tone'
import { clamp } from 'lodash-es'

import { InstrumentZone, PresetGeneratorMap } from 'midi-city-api'

import stackValues from '../../../../../stack-values'

const PAN_MIN = -1
const PAN_MAX = 1

export function instrumentZoneCreatePanner(
  presetZone: PresetGeneratorMap,
  instrumentZone: InstrumentZone,
  context: AudioContext
): Panner {
  const pan = stackValues('pan', presetZone, instrumentZone)

  const value = clamp(pan / 500, PAN_MIN, PAN_MAX)

  const panEffect = new Panner({ pan: value, context })
  return panEffect
}
