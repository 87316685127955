import getById from './get-by-id'
import { Preset, PresetGeneratorMap } from 'midi-city-api'
import {
  KeyRange,
  VelocityRange,
  getItemsMatchingRanges
} from 'midi-city-shared-types'
import { assertIsObject } from 'assertate'
import { without } from 'lodash-es'

export { getById }

export function getPresetZonesForRanges(
  preset: Preset,
  keyRange: KeyRange,
  velocityRange: VelocityRange
): PresetGeneratorMap[] {
  return getItemsMatchingRanges(
    getPresetZonesNonGlobal(preset),
    keyRange,
    velocityRange
  )
}

export function getPresetZoneGlobal(preset: Preset): PresetGeneratorMap {
  const global = preset.generatorMaps.find(
    zone => zone.instrument === undefined
  )
  assertIsObject(global)

  return global
}

export function getPresetZonesNonGlobal(preset: Preset): PresetGeneratorMap[] {
  const global = getPresetZoneGlobal(preset)
  return without(preset.generatorMaps, global)
}
