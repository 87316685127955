import { distance, interval } from '@tonaljs/tonal'
import { midiToNoteName } from '@tonaljs/midi'

import { Sample, InstrumentZone, PresetGeneratorMap } from 'midi-city-api'

import stackValues from '../../../../stack-values'
import { MidiNote } from 'midi-city-shared-types'

function getPresetGeneratorMidiNoteSampleDetune(
  presetZone: PresetGeneratorMap,
  instrumentZone: InstrumentZone,
  midiNote: MidiNote,
  sample: Sample,
  isPercussion: boolean
): number {
  const { overridingRootKey } = instrumentZone
  const rootKey =
    overridingRootKey !== undefined && overridingRootKey >= 0
      ? overridingRootKey
      : isPercussion
      ? midiNote
      : sample.originalPitch

  const noteNameRoot = midiToNoteName(rootKey)
  const noteNameTo = midiToNoteName(midiNote)
  const intervalName = distance(noteNameRoot, noteNameTo)
  const rootSemitones = interval(intervalName).semitones
  if (rootSemitones === undefined) {
    throw new Error('No semitone offset')
  }

  const coarseTune = stackValues('coarseTune', presetZone, instrumentZone)
  const fineTune = stackValues('fineTune', presetZone, instrumentZone)

  const cents =
    (rootSemitones + coarseTune) * 100 + sample.pitchCorrection + fineTune

  return cents
}

export default getPresetGeneratorMidiNoteSampleDetune
