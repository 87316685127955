import { Instrument, InstrumentZone } from 'midi-city-api'
import { warnError } from '../../errors'

function getGlobalZone(instrument: Instrument): InstrumentZone {
  const globalZone = instrument.zones.find(
    (zone): boolean => zone.sampleId === undefined
  )

  if (globalZone === undefined) {
    const error = new Error('Missing Global Zone')
    // @ts-expect-error
    error.instrument = instrument
    warnError(error)
    return {} as InstrumentZone
  }

  return globalZone
}

export default getGlobalZone
