import ChainedCustomError from 'typescript-chained-error'
import { captureException, withScope, Severity } from '@sentry/browser'
import log from 'loglevel'

export function warnError(error: Error, extra?: any): void {
  if (process.env.NODE_ENV !== 'production') {
    log.warn(error)
  }
  withScope(function (scope) {
    scope.setLevel(Severity.Warning)
    if (extra !== undefined) {
      // @ts-expect-error
      error.extra = extra
    }
    captureException(error)
  })
}

// ******************************
// all errors should be added to reserved mangle list

export class BufferFetchError extends ChainedCustomError {
  constructor(public url: string, cause?: Error) {
    super('Failed to fetch buffer', cause, { cleanStack: false })
  }
}

export class StartTimeError extends ChainedCustomError {
  constructor(cause: Error) {
    super('Tone start error', cause, { cleanStack: false })
  }
}
