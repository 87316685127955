import { Context as AudioContext } from 'tone'
import { BiquadFilter } from 'tone/build/esm/component/filter/BiquadFilter'

import { InstrumentZone, PresetGeneratorMap } from 'midi-city-api'

import { centsToFreq } from '../../../unit-conversions'
import stackValues from '../../../stack-values'
import { clamp } from 'lodash-es'

// this exists because we can't use a -6 rolloff so we have to raise the filter to allow some low end frequencies in
const MIN_FILTER_OVERRIDE = 700

export function presetZoneInstrumentZoneCreateFilter(
  presetZone: PresetGeneratorMap,
  instrumentZone: InstrumentZone,
  context: AudioContext
): BiquadFilter {
  const initialFilterFc = stackValues(
    'initialFilterFc',
    presetZone,
    instrumentZone
  )
  const initialFilterQ = stackValues(
    'initialFilterQ',
    presetZone,
    instrumentZone
  )

  const q = initialFilterQ / 10

  // Note: I don't understand this -- we can get RangeErrors
  // if we don't reduce the max filter frequency
  // see: https://sentry.io/organizations/astral-digital/issues/1718917195/events/705b399eed1f440dbcea45376a6cf12c/?project=1524574&statsPeriod=1h
  const filterMaxFreq = context.sampleRate / 2

  const frequency = clamp(
    centsToFreq(initialFilterFc),
    MIN_FILTER_OVERRIDE,
    filterMaxFreq
  )

  const filter = new BiquadFilter({
    frequency,
    type: 'lowpass',
    Q: q,
    context
  })

  return filter
}
