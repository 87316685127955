import { InstrumentZone, PresetGeneratorMap } from 'midi-city-api'

import stackValues from '../../../stack-values'

export function getInstrumentZoneAttenuationGain(
  presetZone: PresetGeneratorMap,
  instrumentZone: InstrumentZone
): number {
  const valueRaw = stackValues('initialAttenuation', presetZone, instrumentZone)
  const value = Math.max(0, valueRaw)

  const gain = -value / 100
  return gain
}
