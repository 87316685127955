import type { ApiResponse } from 'midi-city-api'
import type { LayoutItemMachineActor } from '../layout-item-machine'
import type { ShortcutManagerMachineActor } from '../shortcut-manager-machine'
import type { GlobalActor } from 'midi-city-sound-engine'
import type { LayoutManagerMachineActor } from 'packages/app-manager/src/layout-manager'
import type { Transaction } from '@sentry/types'

import type { All } from './events'
import type {
  Interpreter as XInterpreter,
  StateConfig,
  MachineConfig
} from 'xstate'
import type { Actor as RecordingManagerActor } from '../recording-manager'
import type { Actor as MidiDeviceManagerActor } from '../midi-device-manager'
import type { NotificationManagerActor } from '../notification-manager'
import type { BankIdT, ChannelT, PresetNumber } from 'midi-city-shared-types'
import type { TypedDocumentNode } from 'midi-city-sanity'
import { AppUserSelf } from 'midi-city-server'

export * as Events from './events'

export type SingletonModal = 'About' | 'MidiInput' | 'MidiSelector'

export enum ChannelLoadingState {
  Uninitialized,
  Initialized,
  Loading,
  Loaded
}

export type Page = '/about' | '/' | '/login'

export interface ChannelState {
  state: ChannelLoadingState
  bankId: BankIdT
  presetNumber: PresetNumber
}

export interface AppMachineContext<
  AppUserSelfT extends AppUserSelf = AppUserSelf
> {
  transactionInit?: Transaction
  midiDeviceManager?: MidiDeviceManagerActor
  notificationManager?: NotificationManagerActor
  shortcutManager?: ShortcutManagerMachineActor
  layoutManager?: LayoutManagerMachineActor
  layout?: LayoutItemMachineActor
  hardwareLayout?: LayoutItemMachineActor
  api?: ApiResponse
  urlBase?: string
  global?: GlobalActor
  recordingManager?: RecordingManagerActor

  // navigation
  modalOpen: string | undefined
  menuOpen: string | undefined
  page: Page

  // flags
  isSupported?: boolean
  isMobile?: boolean
  recordingAvailable?: boolean

  channels?: Map<ChannelT, ChannelState>

  // sanity
  ads?: TypedDocumentNode.AdsQuery['allAd']
  globalSettings?: TypedDocumentNode.GlobalSettingsQuery

  currentUser?: AppUserSelfT | null
}

export interface Schema {
  states: {
    uninitialized: {}
    initializing: {
      states: {
        fetchingApi: {}
        initializingEngine: {}
        postEngineInitialized: {}
      }
    }
    ready: {
      states: {
        idle: {}
        unmutingAudio: {}
      }
    }
    disposed: {}
  }
}

export type Interpreter = XInterpreter<AppMachineContext, Schema, All>

export type AppMachineState = StateConfig<AppMachineContext, All>

export type AppMachineConfig = MachineConfig<AppMachineContext, Schema, All>
