import { produce } from 'immer'
import { Machine, assign, spawn, sendParent } from 'xstate'
import { assign as assignImmer } from '@xstate/immer'
import { choose, pure, send } from 'xstate/lib/actions'
import { assertIsNumber, assertIsObject } from 'assertate'
import { every, range } from 'lodash-es'

import {
  keyRangeCreate,
  velocityRangeCreate,
  VELOCITY_RANGE_FULL,
  rangeIntersection,
  VelocityRange,
  KEY_RANGE_FULL,
  KeyRange,
  VELOCITY_SUPPORTED
} from 'midi-city-shared-types'
import { unexpectedAction, ignoredAction } from 'midi-city-xstate-utils'

import instrumentZoneMachine from '../instrument-zone'
import { InstrumentZoneMidiNoteEmittedEventSampleLoadRequest } from '../instrument-zone-midi-note'
import {
  getNonGlobalZones,
  getInstrumentZonesMatchingRanges,
  getGlobalZone
} from '../instrument'

import {
  PresetZoneContext,
  PresetZoneSchema,
  PresetZoneEvent,
  PresetZoneEventReleaseRequest,
  PresetZoneEventStartRequest,
  PresetZoneMidiNoteStatus,
  PresetZoneEmittedEventLoaded,
  PresetZoneEmittedEventStartScheduled,
  PresetZoneEmittedEventReleaseScheduled,
  PresetZoneEventInitialize,
  PresetZoneEmittedEventSampleRequest
} from './types'

import {
  InstrumentZoneEmittedEventLoaded,
  InstrumentZoneEmittedEventStartScheduled,
  InstrumentZoneEmittedEventReleaseScheduled,
  InstrumentZoneActor
} from '../instrument-zone/types'
import { Instrument, InstrumentZone } from 'packages/api'

export * from './types'

export const DEFAULT_CONTEXT = Object.freeze({
  instrumentZones: [],
  midiNoteStatuses: {}
})

function getInstrumentZoneActorId(
  instrumentZoneJSON: InstrumentZone,
  instrument: Instrument
): string {
  const index = getNonGlobalZones(instrument).indexOf(instrumentZoneJSON)
  assertIsNumber(index)
  return `instrument-zone-${index}`
}

const presetGeneratorZoneMachine = Machine<
  PresetZoneContext,
  PresetZoneSchema,
  PresetZoneEvent
>(
  {
    id: 'preset-generator-zone',
    strict: true,
    initial: 'uninitialized',

    on: {
      DISPOSE_REQUEST: {
        target: 'disposed'
      },
      '*': {
        actions: unexpectedAction
      }
    },

    states: {
      uninitialized: {
        on: {
          INITIALIZE: {
            target: 'initialized',
            actions: ['initialize']
          }
        }
      },
      initialized: {
        on: {
          LOAD_REQUEST: {
            actions: [
              assign({
                velocityRangeLoading: (
                  { presetZoneJSON },
                  { velocityRange }
                ) => {
                  assertIsObject(presetZoneJSON)
                  return rangeIntersection(
                    (presetZoneJSON.velRange as VelocityRange) ??
                      VELOCITY_RANGE_FULL,
                    velocityRange
                  )
                },
                keyRangeLoading: ({ presetZoneJSON }, { keyRange }) => {
                  assertIsObject(presetZoneJSON)
                  return rangeIntersection(
                    (presetZoneJSON.keyRange as KeyRange) ?? KEY_RANGE_FULL,
                    keyRange
                  )
                }
              }),
              'instrumentZoneFwdLoadRequest'
            ]
          },

          INSTRUMENT_ZONE_LOADED: {
            actions: choose<
              PresetZoneContext,
              InstrumentZoneEmittedEventLoaded
            >([
              {
                cond: 'hasLoadedAllInstrumentZones',
                actions: [
                  assign<PresetZoneContext, InstrumentZoneEmittedEventLoaded>({
                    keyRangeLoading: _ctx => undefined,
                    velocityRangeLoading: _ctx => undefined,
                    midiNoteStatuses: (
                      { keyRangeLoading, midiNoteStatuses },
                      _event
                    ) =>
                      produce(midiNoteStatuses, draft => {
                        assertIsObject(keyRangeLoading)
                        range(
                          keyRangeLoading.lo,
                          keyRangeLoading.hi + 1
                        ).forEach(midiNote => {
                          draft[midiNote] = PresetZoneMidiNoteStatus.Loaded
                        })
                      })
                  }),
                  sendParent(
                    (): PresetZoneEmittedEventLoaded => ({
                      type: 'PRESET_ZONE_LOADED'
                    })
                  )
                ]
              }
            ])
          },

          START_REQUEST: {
            actions: 'instrumentZoneFwdStartRequest'
          },

          RELEASE_REQUEST: {
            actions: 'instrumentZoneFwdReleaseRequest'
          },

          SAMPLE_BUFFER_LOAD_REQUEST: {
            actions: [
              sendParent(
                (
                  { id }: PresetZoneContext,
                  event: InstrumentZoneMidiNoteEmittedEventSampleLoadRequest
                ): PresetZoneEmittedEventSampleRequest => ({
                  ...event,
                  type: 'PRESET_ZONE_SAMPLE_LOAD_REQUEST',
                  zoneId: id
                })
              )
            ]
          },

          INSTRUMENT_ZONE_START_SCHEDULED: [
            {
              actions: [
                assign({
                  midiNoteStatuses: ({ midiNoteStatuses }, { midiNote }) => {
                    return {
                      ...midiNoteStatuses,
                      [midiNote]: PresetZoneMidiNoteStatus.Scheduled
                    }
                  }
                }),
                sendParent(
                  (
                    _ctx: PresetZoneContext,
                    event: InstrumentZoneEmittedEventStartScheduled
                  ): PresetZoneEmittedEventStartScheduled => ({
                    ...event,
                    type: 'PRESET_ZONE_MIDI_NOTE_START_SCHEDULED'
                  })
                )
              ],
              cond: ({ midiNoteStatuses }, { midiNote }): boolean => {
                return (
                  midiNoteStatuses[midiNote as number] ===
                  PresetZoneMidiNoteStatus.Loaded
                )
              }
            },
            {
              actions: ignoredAction
            }
          ],

          INSTRUMENT_ZONE_RELEASE_SCHEDULED: [
            {
              actions: [
                assign({
                  midiNoteStatuses: ({ midiNoteStatuses }, { midiNote }) => {
                    return {
                      ...midiNoteStatuses,
                      [midiNote]: PresetZoneMidiNoteStatus.Loaded
                    }
                  }
                }),
                sendParent(
                  (
                    _ctx: PresetZoneContext,
                    event: InstrumentZoneEmittedEventReleaseScheduled
                  ): PresetZoneEmittedEventReleaseScheduled => ({
                    ...event,
                    type: 'PRESET_ZONE_MIDI_NOTE_RELEASE_SCHEDULED'
                  })
                )
              ],

              cond: ({ midiNoteStatuses }, { midiNote }): boolean => {
                return (
                  midiNoteStatuses[midiNote] ===
                  PresetZoneMidiNoteStatus.Scheduled
                )
              }
            },
            {
              actions: [ignoredAction]
            }
          ],

          INSTRUMENT_ZONE_CONNECTED: {
            actions: [sendParent((_ctx, event) => event)]
          },

          SAMPLE_BUFFER_LOADED: {
            actions: [
              pure(({ instrumentJSON }, event) => {
                assertIsObject(instrumentJSON)
                return getNonGlobalZones(instrumentJSON).map(instrumentZone =>
                  send(event, {
                    to: getInstrumentZoneActorId(instrumentZone, instrumentJSON)
                  })
                )
              })
            ]
          }
        }
      },

      disposed: {
        entry: ({ instrumentZones }): void => {
          instrumentZones.forEach(zone =>
            zone.send({ type: 'DISPOSE_REQUEST' })
          )
        },
        type: 'final'
      }
    }
  },
  {
    actions: {
      instrumentZoneFwdReleaseRequest: pure(({ instrumentJSON }, event) => {
        const {
          midiNote,
          time,
          source
        } = event as PresetZoneEventReleaseRequest
        assertIsObject(instrumentJSON)
        const keyRange = keyRangeCreate(midiNote, midiNote)
        const velocityRange = VELOCITY_RANGE_FULL

        const instrumentZonesJSONToStart = getInstrumentZonesMatchingRanges(
          instrumentJSON,
          keyRange,
          velocityRange
        )

        return instrumentZonesJSONToStart.map(instrumentZoneJSON => {
          return send(
            {
              type: 'RELEASE_REQUEST',
              time,
              midiNote,
              source
            },
            { to: getInstrumentZoneActorId(instrumentZoneJSON, instrumentJSON) }
          )
        })
      }),

      instrumentZoneFwdStartRequest: pure(({ instrumentJSON }, event) => {
        const {
          midiNote,
          velocity,
          time,
          source
        } = event as PresetZoneEventStartRequest
        assertIsObject(instrumentJSON)
        const keyRange = keyRangeCreate(midiNote, midiNote)

        // this allows simulated velocity
        const velocityRange = velocityRangeCreate(
          VELOCITY_SUPPORTED,
          VELOCITY_SUPPORTED
        )

        const instrumentZonesJSONToStart = getInstrumentZonesMatchingRanges(
          instrumentJSON,
          keyRange,
          velocityRange
        )

        return instrumentZonesJSONToStart.map(instrumentZoneJSON => {
          return send(
            {
              type: 'START_REQUEST',
              time,
              velocity,
              midiNote,
              source
            },
            { to: getInstrumentZoneActorId(instrumentZoneJSON, instrumentJSON) }
          )
        })
      }),

      initialize: assignImmer((ctx, event) => {
        const {
          presetZoneJSON,
          instrumentJSON,
          isPercussion,
          audioContext
        } = event as PresetZoneEventInitialize

        ctx.presetZoneJSON = presetZoneJSON
        ctx.instrumentJSON = instrumentJSON

        const instrumentZoneJSONGlobal = getGlobalZone(instrumentJSON)

        ctx.instrumentZones = getNonGlobalZones(instrumentJSON).map(
          (instrumentZoneJSON, id) =>
            spawn(
              instrumentZoneMachine.withContext({
                audioContext,
                isPercussion,
                presetZoneJSON,
                instrumentZoneJSON: {
                  ...instrumentZoneJSONGlobal,
                  ...instrumentZoneJSON
                },
                midiNotes: {},
                id,
                midiNotesConnectedNum: 0
              }),
              getInstrumentZoneActorId(instrumentZoneJSON, instrumentJSON)
            ) as InstrumentZoneActor
        )
      }),

      instrumentZoneFwdLoadRequest: (
        {
          presetZoneJSON: generatorMap,
          instrumentJSON,
          keyRangeLoading,
          instrumentZones,
          velocityRangeLoading
        },
        _event
      ): void => {
        assertIsObject(generatorMap)
        assertIsObject(keyRangeLoading)
        assertIsObject(instrumentZones)
        assertIsObject(velocityRangeLoading)
        assertIsObject(instrumentJSON)

        const instrumentZonesJSON = getNonGlobalZones(instrumentJSON)
        const instrumentZonesJSONToLoad = getInstrumentZonesMatchingRanges(
          instrumentJSON,
          keyRangeLoading,
          velocityRangeLoading
        )

        instrumentZonesJSONToLoad.forEach(instrumentZoneJSON => {
          const index = instrumentZonesJSON.indexOf(instrumentZoneJSON)
          const instrumentZoneActor = instrumentZones[index]

          assertIsObject(instrumentZoneActor)

          instrumentZoneActor.send({
            type: 'LOAD_REQUEST',
            keyRange: keyRangeLoading,
            velocityRange: velocityRangeLoading
          })
        })
      }
    },

    guards: {
      hasLoadedAllInstrumentZones: ({
        keyRangeLoading,
        instrumentZones,
        velocityRangeLoading,
        instrumentJSON
      }): boolean => {
        assertIsObject(instrumentJSON)

        if (
          keyRangeLoading === undefined ||
          velocityRangeLoading === undefined
        ) {
          // this happens because we do not internally track the instrument zone load status
          return false
        }

        const instrumentZonesJSON = getNonGlobalZones(instrumentJSON)

        const instrumentZonesJSONToLoad = getInstrumentZonesMatchingRanges(
          instrumentJSON,
          keyRangeLoading,
          velocityRangeLoading
        )

        const instrumentZonesLoadedMap = instrumentZonesJSONToLoad.map(
          instrumentZoneJSON => {
            const index = instrumentZonesJSON.indexOf(instrumentZoneJSON)
            const instrumentZoneActor = instrumentZones[index]
            return (
              instrumentZoneActor.state.context.velocityRangeActive !==
              undefined
            )
          }
        )

        const zonesAllLoaded = every(instrumentZonesLoadedMap)

        return zonesAllLoaded
      }
    }
  }
)

export default presetGeneratorZoneMachine
