import { getSoundEngine } from './index'
import { AppMachineContext } from '../app-machine'
import { ChannelMachineActor } from 'midi-city-sound-engine/src/machines/channel'
import { GlobalInterpreter } from 'midi-city-sound-engine'
import { assertIsObject } from 'assertate'
import { sortBy, reverse } from 'lodash-es'

export function getChannels(ctx: AppMachineContext): ChannelMachineActor[] {
  const globalActor = getSoundEngine(ctx)
  const globalCtx = (globalActor as GlobalInterpreter).state.context
  const audioEnvironmentMain = globalCtx.audioEnvironmentMain
  assertIsObject(audioEnvironmentMain)
  const channels: ChannelMachineActor[] = Object.values(
    audioEnvironmentMain.state.context.channels
  )
  return reverse(sortBy(channels, channel => channel.state.contextid))
}
