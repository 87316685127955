import { Interpreter, SpawnedActorRef } from 'xstate'
import { LayoutRectangle } from 'midi-city-shared-types'
import { VirtualController } from 'midi-city-app-manager'
import { KeyboardMachineEmittedEventLayoutStyleChanged } from '../keyboard-machine/events'
import { VirtualControllerID } from '../midi-device-manager'

export enum SidebarStatus {
  Closed,
  Opened,
  Pinned
}

export interface LayoutManagerMachineContext {
  windowLayout?: LayoutRectangle
  appLayout?: LayoutRectangle
  mainLayout?: LayoutRectangle
  navLayout?: LayoutRectangle
  sidebarLayout?: LayoutRectangle
  sidebarStatus: SidebarStatus
  footerLayout?: LayoutRectangle
  hardwareLayout?: LayoutRectangle
  isMobile: boolean
  isNarrowWidth: boolean
  virtualControllers: VirtualController.VirtualControllerInterpreter[]
  virtualControllerLayouts?: Map<VirtualControllerID, LayoutRectangle>
}

export interface LayoutManagerMachineSchema {
  states: {
    idle: {}
    disposed: {}
  }
}

export interface LayoutManagerMachineEventWindowLayoutUpdate {
  type: 'WINDOW_LAYOUT_UPDATE'
  layout: LayoutRectangle
}

export interface LayoutManagerMachineEventVirtualControllerAdd {
  type: 'VIRTUAL_CONTROLLER_ADD'
  virtualController: VirtualController.VirtualControllerInterpreter
}

export interface LayoutManagerMachineEventIsMobileUpdate {
  type: 'IS_MOBILE_UPDATE'
  value: boolean
}

export interface LayoutManagerMachineEventSidebarOpen {
  type: 'SIDEBAR_OPEN'
}

export interface LayoutManagerMachineEventSidebarClose {
  type: 'SIDEBAR_CLOSE'
}

export interface DisposeRequest {
  type: 'DISPOSE_REQUEST'
}

export type LayoutManagerMachineEvent =
  | LayoutManagerMachineEventWindowLayoutUpdate
  | LayoutManagerMachineEventVirtualControllerAdd
  | LayoutManagerMachineEventIsMobileUpdate
  | KeyboardMachineEmittedEventLayoutStyleChanged
  | LayoutManagerMachineEventSidebarOpen
  | LayoutManagerMachineEventSidebarClose
  | DisposeRequest

export type LayoutManagerInterpreter = Interpreter<
  LayoutManagerMachineContext,
  LayoutManagerMachineSchema,
  LayoutManagerMachineEvent
>

export type LayoutManagerMachineActor = SpawnedActorRef<LayoutManagerMachineEvent>
