import { get } from 'lodash-es'

import {
  InstrumentZone,
  ValueGeneratorType,
  PresetGeneratorMap
} from 'midi-city-api'

import typeDefaults from './type-defaults'

type StackableType = keyof InstrumentZone &
  ValueGeneratorType &
  keyof typeof typeDefaults

function stackValues(
  value: StackableType,
  presetZone: PresetGeneratorMap,
  instrumentZone: InstrumentZone
): number {
  const base = get(instrumentZone, value, typeDefaults[value])

  const addend = presetZone[value] ?? 0

  return base + addend
}

export default stackValues
