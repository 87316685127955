import { Machine, send, sendParent } from 'xstate'
import { MidiResponse, getMidis } from 'midi-city-api'
import { assign as assignImmer } from '@xstate/immer'
import { enableMapSet } from 'immer'

import { unexpectedAction, ignoredAction } from 'midi-city-xstate-utils'
import { assertIsObject } from 'assertate'

import { Events, Context, Schema } from './types'

import { Events as GlobalEvents } from 'midi-city-sound-engine'
import { MidiNoteEventSource } from 'midi-city-shared-types'

import actions from './actions'
import { choose, forwardTo } from 'xstate/lib/actions'
import * as TrackManager from '../midi-track-manager'
import { getIsLoadingAnyTrack } from './utils'

export * from './types'
export * from './utils'

// WARNING: side-effects
enableMapSet()

export const PPQ = 240
export const MIDI_FILE_INDEX_DEFAULT = 10

const machine = Machine<Context, Schema, Events.All>(
  {
    id: 'recording-manager',
    strict: true,
    initial: 'uninitialized',

    on: {
      DISPOSE_REQUEST: {
        target: 'disposed'
      },

      CHANNEL_INITIALIZED: {
        actions: ignoredAction
      },

      GLOBAL_CHANNEL_NOTE_START_SCHEDULED: {
        actions: ignoredAction
      },

      GLOBAL_CHANNEL_NOTE_RELEASE_SCHEDULED: {
        actions: ignoredAction
      },

      CHANNEL_NOTE_START_REQUEST: {
        actions: forwardTo('#_parent')
      },

      CHANNEL_NOTE_RELEASE_REQUEST: {
        actions: forwardTo('#_parent')
      },

      NOTE_START_OVERLAPPED_RECORDING: {
        // FIXME ____________________------------------
        actions: ignoredAction
      },

      TRACK_MANAGER_LOAD_REQUESTED: {
        actions: sendParent((_ctx, event) => event)
      },

      PART_LOOP_END: {
        actions: ignoredAction
      },

      '*': {
        actions: [unexpectedAction]
      }
    },

    states: {
      uninitialized: {
        on: {
          INITIALIZE: {
            actions: ['initialize'],
            target: 'initialized'
          }
        }
      },

      initialized: {
        type: 'parallel',

        entry: ['audioContextsSetup'],

        on: {
          TRACK_MANAGER_INITIALIZED: [
            {
              actions: [forwardTo('#_parent')],
              cond: 'isTrackManagerInitializedOfSourceType'
            },
            {
              actions: ignoredAction
            }
          ],

          TRACK_MANAGER_DURATION_UPDATED: {
            actions: [forwardTo('#_parent')]
          },

          TRACK_MANAGER_LOADING: {
            actions: 'channelLoadedStateUpdate'
          },

          TRACK_MANAGER_LOAD_SUCCESS: {
            actions: [
              'channelLoadedStateUpdate',

              choose<Context, any>([
                {
                  actions: [send({ type: 'START_REQUEST' })],
                  cond: 'shouldAutoplay'
                }
              ])
            ]
          },

          GLOBAL_CHANNEL_LOADED: {
            actions: ['fwdChannelEvent']
          },

          GLOBAL_CHANNEL_LOADING: {
            actions: ['fwdChannelEvent']
          },

          BPM_CHANGE_REQUEST: {
            actions: [
              assignImmer((ctx, { value }) => {
                ctx.bpm = value
              }),
              ({ audioContextChannelMap }, { value }): void => {
                assertIsObject(audioContextChannelMap)
                audioContextChannelMap.forEach((_key, audioContext) => {
                  audioContext.transport.bpm.setValueAtTime(
                    value,
                    audioContext.now()
                  )
                })
              }
            ]
          }
        },

        states: {
          playState: {
            initial: 'inactive',

            states: {
              inactive: {
                on: {
                  START_REQUEST: {
                    target: 'active'
                  },
                  RECORD_REQUEST: {
                    target: 'active.isRecording'
                  }
                }
              },

              active: {
                entry: [
                  'transportStart',
                  'tracksStart',
                  'autoplayDisable',
                  'emitStarted'
                ],

                exit: [
                  'transportStop',
                  'channelsReleaseAll',
                  'tracksStop',
                  'emitStopped'
                ],

                on: {
                  STOP_REQUEST: {
                    target: 'inactive'
                  },

                  START_REQUEST: {
                    actions: ignoredAction
                  },

                  TRACK_MANAGER_LOADING: {
                    target: 'inactive',
                    actions: ['autoplayEnable', 'channelLoadedStateUpdate']
                  },

                  LOAD_SOURCE_REQUEST: {
                    target: 'inactive',
                    actions: 'autoplayEnable'
                  }
                },

                initial: 'isNotRecording',

                states: {
                  isNotRecording: {
                    on: {
                      RECORD_REQUEST: {
                        target: 'isRecording'
                      }
                    }
                  },

                  isRecording: {
                    on: {
                      GLOBAL_CHANNEL_NOTE_START_SCHEDULED: {
                        actions: ['processNoteScheduledEvent']
                      },
                      GLOBAL_CHANNEL_NOTE_RELEASE_SCHEDULED: {
                        actions: ['processNoteScheduledEvent']
                      }
                    }
                  }
                }
              }
            }
          },

          remoteFiles: {
            initial: 'unloaded',

            states: {
              unloaded: {
                on: {
                  FETCH_ALL_REQUEST: {
                    target: 'loading'
                  },

                  LOAD_SOURCE_REQUEST: {
                    target: ['loading'],
                    actions: ['idSelectedAssign']
                  }
                }
              },

              loading: {
                on: {
                  FETCH_ALL_REQUEST: {
                    actions: ignoredAction
                  },
                  LOAD_SOURCE_REQUEST: {
                    actions: ignoredAction
                  },
                  RECORD_REQUEST: {
                    actions: unexpectedAction
                  },
                  START_REQUEST: {
                    actions: unexpectedAction
                  }
                },

                invoke: {
                  id: 'fetchingAll',
                  src: async ({ urlBase }): Promise<MidiResponse[]> => {
                    const midis = await getMidis(urlBase)
                    return midis
                  },
                  onDone: {
                    target: 'loaded',
                    actions: ['midisAssign']
                  }
                }
              },

              loaded: {
                entry: [
                  sendParent({
                    type: 'MIDIS_FETCHED'
                  } as Events.EmittedFetched),
                  choose([
                    {
                      actions: 'trackManagersLoadSource',
                      cond: 'hasSelectedSourceFile'
                    }
                  ])
                ],

                on: {
                  FETCH_ALL_REQUEST: {
                    actions: ignoredAction
                  },

                  LOAD_SOURCE_REQUEST: [
                    {
                      actions: ['idSelectedAssign', 'trackManagersLoadSource']
                    }
                  ]
                }
              }
            }
          }
        }
      },

      disposed: {
        type: 'final'
      }
    }
  },
  {
    actions,

    guards: {
      isNonScheduledEvent: (_ctx, event): boolean => {
        const { source } = event as
          | GlobalEvents.EmittedChannelNoteStartScheduled
          | GlobalEvents.EmittedChannelNoteReleaseScheduled
        return source !== MidiNoteEventSource.Scheduled
      },

      autoplayEnabled: ({ autoplay }): boolean => autoplay,

      autoplayDisabled: ({ autoplay }): boolean => !autoplay,

      shouldAutoplay: (ctx): boolean =>
        ctx.autoplay && !getIsLoadingAnyTrack(ctx),

      isTrackManagerInitializedOfSourceType: (_ctx, event): boolean =>
        (event as TrackManager.Events.EmittedInitialized).trackType ===
        TrackManager.TrackType.Source,

      hasSelectedSourceFile: ({ idSelected }): boolean =>
        idSelected !== undefined
    }
  }
)

export { machine }
