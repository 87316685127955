import { fromEventPattern } from 'rxjs'
import WebMidi from 'webmidi'
export const midisConnected = fromEventPattern(
  handler => {
    WebMidi.addListener('connected', handler)
    WebMidi.addListener('disconnected', handler)
  },
  handler => {
    WebMidi.removeListener('connected', handler)
    WebMidi.removeListener('disconnected', handler)
  }
)
