import { Machine, assign } from 'xstate'
import { assign as assignImmer } from '@xstate/immer'
import { KeyboardLayoutStyle } from 'midi-city-shared-types'
import { assertIsObject } from 'assertate'
import { clamp } from 'lodash-es'
import { VirtualControllerInterpreter } from 'midi-city-app-manager/src/virtual-controller'
import { ignoredAction } from 'midi-city-xstate-utils'

import {
  LayoutManagerMachineContext,
  LayoutManagerMachineSchema,
  LayoutManagerMachineEvent,
  SidebarStatus
} from './types'
import { choose } from 'xstate/lib/actions'
import { enableMapSet } from 'immer'

export * from './types'

export const NAV_HEIGHT = 64
export const FOOTER_HEIGHT = 64 + 8 * 2

export const APP_WIDTH_MAX = Infinity
export const APP_HEIGHT_MAX = Infinity
export const APP_WIDTH_NARROW = 800

export const VIRTUAL_CONTROLLER_MAX_WIDTH = 1000
export const VIRTUAL_CONTROLLER_MAX_UNIT_HEIGHT = 256

export const SIDEBAR_MAX_WIDTH = 280

// WARNING: side-effects
enableMapSet()

const layoutManagerMachine = Machine<
  LayoutManagerMachineContext,
  LayoutManagerMachineSchema,
  LayoutManagerMachineEvent
>(
  {
    id: 'layout-manager',
    strict: true,
    context: {
      virtualControllers: [],
      isMobile: false,
      isNarrowWidth: false,
      sidebarStatus: SidebarStatus.Pinned
    },
    initial: 'idle',
    states: {
      idle: {
        entry: [
          assignImmer(ctx => {
            ctx.virtualControllerLayouts = new Map()
          }),
          'windowLayoutInit',
          'handleWindowChange'
        ],
        on: {
          WINDOW_LAYOUT_UPDATE: {
            actions: [
              assign({
                windowLayout: (_ctx, { layout }) => layout
              }),
              'handleWindowChange'
            ]
          },

          KEYBOARD_LAYOUT_STYLE_CHANGED: {
            actions: [
              'virtualControllersUpdate',
              'virtualControllersSendLayout',
              // this is a hack to get the menus to move when
              // the layout changes -- ideally, menus are informed directly
              assignImmer(ctx => {
                const { windowLayout } = ctx
                assertIsObject(windowLayout)
                ctx.windowLayout = { ...windowLayout }
              })
            ]
          },

          VIRTUAL_CONTROLLER_ADD: {
            actions: [
              assign({
                virtualControllers: (
                  { virtualControllers },
                  { virtualController }
                ) => [...virtualControllers, virtualController]
              }),
              'virtualControllersUpdate',
              'virtualControllersSendLayout'
            ]
          },

          IS_MOBILE_UPDATE: {
            actions: [
              assignImmer((ctx, { value }) => {
                ctx.isMobile = value
              }),
              // TODO: Ideally IS_MOBILE_UPDATE isn't necessary and we initialize
              // the layout manager with that variable and never change it
              'handleWindowChange'
            ]
          },

          SIDEBAR_CLOSE: [
            {
              actions: [
                assignImmer(ctx => {
                  ctx.sidebarStatus = SidebarStatus.Closed
                }),
                'handleWindowChange'
              ],
              cond: (ctx: LayoutManagerMachineContext): boolean =>
                ctx.sidebarStatus === SidebarStatus.Opened
            },
            {
              actions: ignoredAction
            }
          ],

          SIDEBAR_OPEN: [
            {
              actions: [
                assignImmer(ctx => {
                  ctx.sidebarStatus = SidebarStatus.Opened
                }),
                'handleWindowChange'
              ],
              cond: (ctx: LayoutManagerMachineContext): boolean =>
                ctx.sidebarStatus === SidebarStatus.Closed
            },
            {
              actions: ignoredAction
            }
          ],

          DISPOSE_REQUEST: {
            target: 'disposed'
          }
        }
      },

      disposed: {
        type: 'final',
        entry: assignImmer(ctx => {
          ctx.virtualControllers = []
        })
      }
    }
  },
  {
    actions: {
      windowLayoutInit: assignImmer(ctx => {
        ctx.windowLayout = {
          x: 0,
          y: 0,
          width: 0,
          height: 0
        }
      }),

      handleWindowChange: choose([
        {
          actions: [
            'updateLayouts',
            'virtualControllersUpdate',
            'virtualControllersSendLayout'
          ]
        }
      ]),

      updateLayouts: assignImmer(ctx => {
        const { windowLayout, isMobile } = ctx
        assertIsObject(windowLayout)

        ctx.appLayout = {
          x: 0,
          y: 0,
          width: clamp(windowLayout.width, 0, APP_WIDTH_MAX),
          height: clamp(windowLayout.height, 0, APP_HEIGHT_MAX)
        }

        ctx.isNarrowWidth = ctx.appLayout.width < APP_WIDTH_NARROW

        if (ctx.isNarrowWidth && ctx.sidebarStatus === SidebarStatus.Pinned) {
          ctx.sidebarStatus = SidebarStatus.Closed
        } else if (!ctx.isNarrowWidth) {
          ctx.sidebarStatus = SidebarStatus.Pinned
        }

        const sidebarIsPinned = ctx.sidebarStatus === SidebarStatus.Pinned
        const sidebarIsClosed = ctx.sidebarStatus === SidebarStatus.Closed

        ctx.sidebarLayout = {
          height: ctx.appLayout.height,
          width: SIDEBAR_MAX_WIDTH,
          x: sidebarIsClosed ? -SIDEBAR_MAX_WIDTH : 0,
          y: 0
        }

        const sidebarLayoutRight = sidebarIsPinned
          ? ctx.sidebarLayout.width + ctx.sidebarLayout.x
          : 0

        ctx.navLayout = {
          x: sidebarLayoutRight,
          y: 0,
          width: ctx.appLayout.width - sidebarLayoutRight,
          height: NAV_HEIGHT
        }

        ctx.mainLayout = {
          x: sidebarLayoutRight,
          y: ctx.navLayout.height,
          height: Math.max(0, ctx.appLayout.height - ctx.navLayout.height),
          width: ctx.appLayout.width - sidebarLayoutRight
        }

        ctx.footerLayout = {
          ...ctx.mainLayout,
          height: isMobile ? 0 : FOOTER_HEIGHT
        }

        ctx.hardwareLayout = {
          y: 0,
          x: 0,
          width: ctx.mainLayout.width,
          height: Math.max(0, ctx.mainLayout.height - ctx.footerLayout.height)
        }

        ctx.footerLayout.y = ctx.hardwareLayout.y + ctx.hardwareLayout.height
      }),

      virtualControllersUpdate: assignImmer(ctx => {
        const {
          hardwareLayout,
          virtualControllers,
          virtualControllerLayouts
        } = ctx

        assertIsObject(hardwareLayout)
        assertIsObject(virtualControllerLayouts)

        let virtualUnits = 0

        // TODO this 2/3 magic number is used again below
        virtualControllers.forEach(virtualController => {
          virtualUnits +=
            (virtualController as VirtualControllerInterpreter).state.context
              .keyboard?.state.context.layoutStyle ===
            KeyboardLayoutStyle.Single
              ? 1
              : 2
        })

        virtualControllers.forEach(virtualController => {
          const keyboardLayout = (virtualController as VirtualControllerInterpreter)
            .state.context.keyboard?.state.context.layoutStyle

          const units = keyboardLayout === KeyboardLayoutStyle.Single ? 1 : 2

          const height = Math.min(
            hardwareLayout.height * (units / virtualUnits),
            units * VIRTUAL_CONTROLLER_MAX_UNIT_HEIGHT
          )

          virtualControllerLayouts.set(
            virtualController.state.context.channel,
            {
              ...hardwareLayout,
              y: 0,
              height,
              width: Math.min(
                hardwareLayout.width,
                VIRTUAL_CONTROLLER_MAX_WIDTH
              )
            }
          )
        })

        ctx.virtualControllerLayouts = virtualControllerLayouts
      }),

      virtualControllersSendLayout: ({
        virtualControllers,
        virtualControllerLayouts
      }): void => {
        assertIsObject(virtualControllerLayouts)
        virtualControllers.forEach(virtualController => {
          const layout = virtualControllerLayouts.get(
            virtualController.state.context.channel
          )

          assertIsObject(layout)

          virtualController.send({
            type: 'LAYOUT_UPDATE',
            layout
          })
        })
      }
    }
  }
)

export default layoutManagerMachine
