import { Machine, Actor, Interpreter, State, sendParent } from 'xstate'

import { KeyboardKeyNumber } from 'midi-city-shared-types'
import { logAction } from 'midi-city-xstate-utils'

export interface KeyboardGestureManagerMachineEventKeyOnRequest {
  type: 'KEYBOARD_GESTURE_KEY_ON_REQUEST'
  keyNumber: KeyboardKeyNumber
}

export interface KeyboardGestureManagerMachineEventKeyOffRequest {
  type: 'KEYBOARD_GESTURE_KEY_OFF_REQUEST'
  keyNumber: KeyboardKeyNumber
}

export type KeyboardGestureManagerMachineEvent =
  | KeyboardGestureManagerMachineEventKeyOnRequest
  | KeyboardGestureManagerMachineEventKeyOffRequest

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface KeyboardGestureManagerMachineContext {}

export interface KeyboardGestureManagerMachineSchema {
  states: {
    ready: {}
  }
}

export type KeyboardGestureManagerInterpreter = Interpreter<
  KeyboardGestureManagerMachineContext,
  KeyboardGestureManagerMachineSchema,
  KeyboardGestureManagerMachineEvent
>

export type KeyboardGestureManagerState = State<
  KeyboardGestureManagerMachineContext,
  KeyboardGestureManagerMachineEvent,
  KeyboardGestureManagerMachineSchema
>

export type KeyboardGestureManagerMachineActor = Actor<
  KeyboardGestureManagerState,
  KeyboardGestureManagerMachineEvent
>

const KeyboardGestureManagerMachine = Machine<
  KeyboardGestureManagerMachineContext,
  KeyboardGestureManagerMachineSchema,
  KeyboardGestureManagerMachineEvent
>({
  id: 'kb-gesture-mngr',
  initial: 'ready',
  strict: true,
  states: {
    ready: {
      on: {
        KEYBOARD_GESTURE_KEY_ON_REQUEST: {
          actions: [
            sendParent(
              (
                _ctx: KeyboardGestureManagerMachineContext,
                { keyNumber }: KeyboardGestureManagerMachineEventKeyOnRequest
              ) => ({
                type: 'KEYBOARD_KEY_ON_REQUEST',
                keyNumber
              })
            ),
            logAction
          ]
        },
        KEYBOARD_GESTURE_KEY_OFF_REQUEST: {
          actions: [
            sendParent(
              (
                _ctx: KeyboardGestureManagerMachineContext,
                { keyNumber }: KeyboardGestureManagerMachineEventKeyOffRequest
              ) => ({
                type: 'KEYBOARD_KEY_OFF_REQUEST',
                keyNumber
              })
            ),
            logAction
          ]
        }
      }
    }
  }
})

export default KeyboardGestureManagerMachine
