import moize from 'moize'
import { LayoutRectangle, KeyboardKeyNumber } from 'midi-city-shared-types'

import {
  getRowKeys,
  getRowHeight,
  getRowTop,
  getRowPaddingScale,
  getRowVisibleMaxKeyCount
} from '../row'
import { KeyboardLayoutStyle as KeyboardLayoutStyleT } from '../../index'

const keyGutterWidth = 8

const minimumKeyWidth = 42

export function getKeyHitBox(
  key: KeyboardKeyNumber,
  numKeys: number,
  layout: LayoutRectangle,
  layoutStyle: KeyboardLayoutStyle
): LayoutRectangle {
  const xOffset = keyGutterWidth / 2
  return {
    x: getKeyLeft(key, numKeys, layout, layoutStyle) - xOffset,
    y: getKeyTop(key, layout, layoutStyle),
    width: getKeyWidth(key, layout, layoutStyle) + xOffset * 2,
    height: getKeyHeight(key, layout, layoutStyle)
  }
}

function getKeyWidth(
  _key: number,
  layout: LayoutRectangle,
  layoutStyle: KeyboardLayoutStyle
): number {
  const rowVisibleMaxKeyCount = getRowVisibleMaxKeyCount(layoutStyle)
  const evenWidth =
    (layout.width - keyGutterWidth) / rowVisibleMaxKeyCount - keyGutterWidth
  return Math.floor(Math.max(evenWidth, minimumKeyWidth))
}

function getKeyHeight(
  keyNumber: KeyboardKeyNumber,
  layout: LayoutRectangle,
  layoutStyle: KeyboardLayoutStyle
): number {
  return getRowHeight(getKeyRow(keyNumber, layoutStyle), layout, layoutStyle)
}

function getKeyRow(
  keyNumber: KeyboardKeyNumber,
  layoutStyle: KeyboardLayoutStyle
): number {
  if (layoutStyle === KeyboardLayoutStyleT.Single) {
    return 0
  } else {
    const isAccidental = [1, 3, 6, 8, 10].includes(keyNumber % 12)
    return isAccidental ? 0 : 1
  }
}

function getKeyRowSlot(
  keyNumber: KeyboardKeyNumber,
  numKeys: number,
  layoutStyle: KeyboardLayoutStyle
): number {
  const keyRow = getKeyRow(keyNumber, layoutStyle)
  const keyRowKeys = getRowKeys(keyRow, numKeys, layoutStyle)
  const keyRowIndex = keyRowKeys.indexOf(keyNumber)
  const keyRowPrevKey = keyRowKeys[keyRowIndex - 1]

  if (keyRowPrevKey === undefined) {
    return 0
  }

  const offset = Math.max(keyNumber - keyRowPrevKey - 2, 0)

  const keyRowPrevKeySlot = getKeyRowSlot(keyRowPrevKey, numKeys, layoutStyle)

  return keyRowPrevKeySlot + offset + 1
}

function getKeyLeft(
  key: KeyboardKeyNumber,
  numKeys: number,
  layout: LayoutRectangle,
  layoutStyle: KeyboardLayoutStyle
): number {
  const keyRow = getKeyRow(key, layoutStyle)
  const rowPaddingLeft = getRowPaddingScale(keyRow, layoutStyle)
  const keyWidth = getKeyWidth(key, layout, layoutStyle)
  const keyRowSlot = getKeyRowSlot(key, numKeys, layoutStyle)

  return (keyWidth + keyGutterWidth) * keyRowSlot + keyWidth * rowPaddingLeft
}

function getKeyTop(
  key: KeyboardKeyNumber,
  layout: LayoutRectangle,
  layoutStyle: KeyboardLayoutStyle
): number {
  const row = getKeyRow(key, layoutStyle)
  return getRowTop(row, layout, layoutStyle)
}

const getKeyLayout = moize(
  (
    key: KeyboardKeyNumber,
    numKeys: number,
    layoutContainer: LayoutRectangle,
    layoutStyle: KeyboardLayoutStyle
  ): LayoutRectangle => {
    return {
      x: getKeyLeft(key, numKeys, layoutContainer, layoutStyle),
      y: getKeyTop(key, layoutContainer, layoutStyle),
      width: getKeyWidth(key, layoutContainer, layoutStyle),
      height: getKeyHeight(key, layoutContainer, layoutStyle)
    }
  }
)

export {
  getKeyWidth,
  getKeyLeft,
  getKeyHeight,
  getKeyTop,
  getKeyRowSlot,
  getKeyLayout,
  keyGutterWidth,
  getKeyRow
}

export type KeyboardLayoutStyle = KeyboardLayoutStyleT
