import { Gain } from 'tone'
import { Machine, sendParent, spawn, send } from 'xstate'
import { pure } from 'xstate/lib/actions'
import { assertIsObject, assertIsNumber } from 'assertate'
import { startTransaction } from '@sentry/browser'

import { CHANNELS_SUPPORTED, rangeExceeds } from 'midi-city-shared-types'

import presetMachine, {
  PresetMachineEmittedEventMidiNoteStartScheduled,
  PresetMachineEmittedEventMidiNoteReleaseScheduled,
  PresetMachineEmittedEventLoadSuccess,
  PresetMachineActor
} from '../preset'

import { unexpectedAction, ignoredAction } from 'midi-city-xstate-utils'

import { Context, Schema, Events } from './types'
import { original } from 'immer'
import { assign as assignImmer } from '@xstate/immer'
import { isEqual } from 'lodash-es'
import log from 'loglevel'

export * from './types'

const IMMEDIATE_OFFSET = 0.05
const TRANSACTION_LOADING_ID = Object.freeze('channel-load')

const channelMachine = Machine<Context, Schema, Events.All>(
  {
    id: 'channel-machine',
    strict: true,

    initial: 'uninitialized',

    on: {
      '*': {
        actions: unexpectedAction
      }
    },

    states: {
      uninitialized: {
        always: {
          cond: 'isSupportedChannel',
          target: 'initialized',
          actions: [
            assignImmer(ctx => {
              const { audioContext, globalGain } = original(ctx) as Context

              ctx.midiNotesOn = new Set()

              ctx.gain = new Gain({ context: audioContext })
              ctx.gain.connect(globalGain)
            }),
            'presetSpawn'
          ]
        },
        on: {
          SAMPLE_BUFFER_LOADED: {
            actions: ignoredAction
          },
          RELEASE_ALL_REQUEST: {
            actions: ignoredAction
          },
          DISPOSE_REQUEST: {
            target: 'disposed'
          }
        }
      },

      initialized: {
        initial: 'unloaded',

        entry: [
          sendParent(
            ({
              presetNumber,
              bankId,
              audioContext,
              id
            }): Events.ChannelEmittedInitialized => {
              assertIsNumber(presetNumber)
              assertIsNumber(bankId)

              return {
                type: 'CHANNEL_INITIALIZED',
                channelNumber: id,
                audioContext,
                presetNumber,
                bankId
              }
            }
          )
        ],

        on: {
          NOTE_START_REQUEST: {
            actions: ignoredAction
          },

          NOTE_RELEASE_REQUEST: {
            actions: ignoredAction
          },

          PRESET_ZONE_SAMPLE_LOAD_REQUEST: {
            actions: [
              sendParent((_ctx: Context, event) => ({
                ...event,
                type: 'SAMPLE_BUFFER_LOAD_REQUEST'
              }))
            ]
          },

          SAMPLE_BUFFER_LOADED: {
            actions: [
              ({ preset }, event): void => {
                if (preset === undefined) {
                  log.warn('Missing a preset on channel-machine')
                  return
                }
                preset.send(event)
              }
            ]
          },

          DISPOSE_REQUEST: {
            target: 'disposed'
          }
        },

        states: {
          unloaded: {
            on: {
              PRESET_LOAD_REQUEST: [
                {
                  actions: [
                    'velocityRangeLoadingAssign',
                    'keyRangeLoadingAssign'
                  ],

                  target: 'loading'
                }
              ],

              PRESET_CHANGE_REQUEST: {
                actions: unexpectedAction
              },

              RELEASE_ALL_REQUEST: {
                actions: ignoredAction
              }
            }
          },

          loading: {
            entry: [
              assignImmer(ctx => {
                ctx.transactionLoading = startTransaction({
                  name: TRANSACTION_LOADING_ID
                })
              }),
              'activeClear',
              'presetLoad',
              'emitLoading'
            ],

            on: {
              PRESET_CHANGE_REQUEST: {
                actions: [
                  'presetDisposingUpdate',
                  assignImmer((ctx, { presetNumber, bankId }) => {
                    ctx.presetNumber = presetNumber
                    ctx.bankId = bankId
                  }),
                  'presetSpawn',
                  'presetDisposingDispose'
                ],
                target: 'loading'
              },

              // this can come from auto-playing in the track manager
              RELEASE_ALL_REQUEST: {
                actions: ignoredAction
              },

              PRESET_LOAD_REQUEST: [
                {
                  actions: [
                    'velocityRangeLoadingAssign',
                    'keyRangeLoadingAssign'
                  ],

                  target: 'loading',
                  cond: 'shouldAllowLoadRequest'
                },
                { actions: ignoredAction }
              ],

              PRESET_LOAD_SUCCESS: {
                target: 'loaded',
                actions: [
                  'sendPresetLoadSuccess',
                  assignImmer((ctx, { velocityRange, keyRange }) => {
                    ctx.velocityRangeActive = velocityRange
                    ctx.keyRangeActive = keyRange
                  })
                ]
              }
            }
          },

          loaded: {
            entry: [
              'presetDisposingDispose',
              'loadingClear',
              ({ transactionLoading }): void => {
                transactionLoading?.finish()
              }
            ],
            exit: ['presetDisposingUpdate', 'presetDisposingDispose'],
            on: {
              NOTE_START_REQUEST: {
                actions: ['sendPresetStart']
              },

              NOTE_RELEASE_REQUEST: {
                actions: ['sendPresetRelease']
              },

              RELEASE_ALL_REQUEST: {
                actions: pure(({ midiNotesOn }, { time }) => {
                  assertIsObject(midiNotesOn)
                  return Array.from(midiNotesOn).map(note => {
                    return send({
                      type: 'NOTE_RELEASE_REQUEST',
                      time,
                      midiNote: note
                    })
                  })
                })
              },

              // TODO unclear why this is getting emitted
              PRESET_LOAD_SUCCESS: {
                actions: ignoredAction
              },

              PRESET_LOAD_REQUEST: [
                {
                  actions: [
                    'velocityRangeLoadingAssign',
                    'keyRangeLoadingAssign',
                    'presetLoad'
                  ],

                  target: 'loading',
                  cond: 'shouldAllowLoadRequest'
                },
                {
                  actions: ignoredAction
                }
              ],

              INSTRUMENT_ZONE_CONNECTED: {
                actions: [
                  (
                    { gain, reverb, chorus },
                    { outputNode, reverbGain, chorusGain }
                  ): void => {
                    assertIsObject(gain)
                    assertIsObject(reverb)
                    assertIsObject(chorus)
                    outputNode.connect(gain)
                    reverbGain.connect(reverb)
                    chorusGain.connect(chorus)
                  }
                ]
              },

              PRESET_MIDI_NOTE_START_SCHEDULED: {
                actions: [
                  assignImmer((ctx, { midiNote }) => {
                    ctx.midiNotesOn?.add(midiNote)
                  }),
                  sendParent(
                    (
                      ctx: Context,
                      event: PresetMachineEmittedEventMidiNoteStartScheduled
                    ): Events.ChannelEmittedEventNoteStartScheduled => ({
                      ...event,
                      type: 'CHANNEL_NOTE_START_SCHEDULED',
                      channelNumber: ctx.id
                    })
                  )
                ]
              },

              PRESET_MIDI_NOTE_RELEASE_SCHEDULED: {
                actions: [
                  assignImmer((ctx, { midiNote }) => {
                    ctx.midiNotesOn?.delete(midiNote)
                  }),
                  sendParent(
                    (
                      ctx: Context,
                      event: PresetMachineEmittedEventMidiNoteReleaseScheduled
                    ): Events.ChannelEmittedEventNoteReleaseScheduled => ({
                      ...event,
                      type: 'CHANNEL_NOTE_RELEASE_SCHEDULED',
                      channelNumber: ctx.id
                    })
                  )
                ]
              },

              PRESET_CHANGE_REQUEST: {
                actions: [
                  assignImmer((ctx, { presetNumber, bankId }) => {
                    ctx.presetNumber = presetNumber
                    ctx.bankId = bankId
                  }),
                  'presetSpawn',
                  assignImmer(ctx => {
                    ctx.velocityRangeLoading = ctx.velocityRangeActive
                    ctx.keyRangeLoading = ctx.keyRangeActive
                  })
                ],
                target: 'loading'
              },

              DISPOSE_REQUEST: {
                target: '#channel-machine.disposed'
              }
            }
          }
        }
      },

      disposed: {
        type: 'final',
        entry: ({ gain }): void => {
          gain?.dispose()
        }
      }
    }
  },
  {
    actions: {
      presetDisposingUpdate: assignImmer(ctx => {
        ctx.presetDisposing = ctx.preset
      }),

      presetDisposingDispose: assignImmer(ctx => {
        const { presetDisposing } = ctx
        if (presetDisposing === undefined) {
          return
        }
        // WARNING: side effect in assign
        presetDisposing.send({ type: 'DISPOSE_REQUEST' })
        ctx.presetDisposing = undefined
      }),

      presetSpawn: assignImmer((ctx, _event) => {
        const {
          id,
          urlBase,
          presetNumber,
          bankId,
          audioContext,
          samples,
          instruments,
          presets
        } = original(ctx) as Context

        assertIsNumber(presetNumber)
        assertIsNumber(bankId)

        const presetJSON = presets.find(
          item => item.bankId === bankId && item.midiId === presetNumber
        )

        assertIsObject(presetJSON)

        ctx.preset = spawn(
          presetMachine.withContext({
            id,
            presetNumber,
            bankId,
            urlBase,
            audioContext,
            samplesJSON: samples,
            instrumentsJSON: instruments,
            json: presetJSON,
            midiNoteStatuses: {}
          }),
          {
            name: `channel-${id as number}-preset-${
              bankId as number
            }-${presetNumber}`
          }
        ) as PresetMachineActor
      }),

      presetLoad: (
        { preset, velocityRangeLoading, keyRangeLoading }: Context,
        _event
      ): void => {
        assertIsObject(velocityRangeLoading)
        assertIsObject(keyRangeLoading)
        assertIsObject(preset)
        preset.send({
          type: 'LOAD_REQUEST',
          velocityRange: velocityRangeLoading,
          keyRange: keyRangeLoading
        })
      },

      sendPresetStart: ({ preset, audioContext }: Context, event): void => {
        assertIsObject(preset)
        assertIsObject(audioContext)
        const { midiNote, velocity, time, source } = event as Events.NoteStart

        preset.send({
          type: 'NOTE_START_REQUEST',
          midiNote,
          velocity,
          time: time ?? audioContext.immediate() + IMMEDIATE_OFFSET,
          source
        })
      },

      sendPresetRelease: ({ preset, audioContext }: Context, event): void => {
        assertIsObject(preset)
        assertIsObject(audioContext)
        const { midiNote, time, source } = event as Events.NoteRelease

        preset.send({
          type: 'NOTE_RELEASE_REQUEST',
          midiNote,
          time: time ?? audioContext.immediate() + IMMEDIATE_OFFSET,
          source
        })
      },

      reload: send(
        (
          {
            keyRangeActive,
            velocityRangeActive,
            keyRangeLoading,
            velocityRangeLoading
          }: Context,
          _event
        ) => {
          // intelligently(?) send a load request sending existing info
          const keyRange = keyRangeActive ?? keyRangeLoading
          const velocityRange = velocityRangeActive ?? velocityRangeLoading

          assertIsObject(keyRange)
          assertIsObject(velocityRange)

          return {
            type: 'PRESET_LOAD_REQUEST',
            keyRange,
            velocityRange
          }
        }
      ),

      sendPresetLoadSuccess: sendParent(
        (
          { id, velocityRangeActive, keyRangeActive }: Context,
          event
        ): Events.ChannelEmittedEventPresetLoadSuccess => {
          assertIsObject(keyRangeActive)
          assertIsObject(velocityRangeActive)

          const {
            presetNumber,
            bankId
          } = event as PresetMachineEmittedEventLoadSuccess

          return {
            type: 'CHANNEL_PRESET_LOAD_SUCCESS',
            presetNumber,
            bankId,
            channelNumber: id,
            keyRange: keyRangeActive,
            velocityRange: velocityRangeActive
          }
        }
      ),

      activeClear: assignImmer(ctx => {
        ctx.keyRangeActive = undefined
        ctx.velocityRangeActive = undefined
      }),

      loadingClear: assignImmer(ctx => {
        ctx.keyRangeLoading = undefined
        ctx.velocityRangeLoading = undefined
      }),

      velocityRangeLoadingAssign: assignImmer((ctx, event) => {
        ctx.velocityRangeActive = undefined
        ctx.velocityRangeLoading = (event as Events.PresetLoadRequest).velocityRange
      }),

      keyRangeLoadingAssign: assignImmer((ctx, event) => {
        ctx.keyRangeActive = undefined
        ctx.keyRangeLoading = (event as Events.PresetLoadRequest).keyRange
      }),

      presetChangeRequestSend: sendParent(
        (
          ctx: Context,
          event
        ): Events.ChannelEmittedEventPresetChangeRequest => {
          const { presetNumber, bankId } = event as Events.PresetChangeRequest
          return {
            type: 'CHANNEL_PRESET_CHANGE_REQUEST',
            presetNumber,
            bankId,
            channelNumber: ctx.id
          }
        }
      ),

      emitLoading: sendParent(
        ({
          id,
          velocityRangeLoading,
          keyRangeLoading,
          preset
        }: Context): Events.ChannelEmittedEventLoading => {
          assertIsObject(preset)
          assertIsObject(keyRangeLoading)
          assertIsObject(velocityRangeLoading)

          // TODO don't reach into the context like this
          const { presetNumber, bankId } = preset.state.context

          return {
            type: 'CHANNEL_LOADING',
            velocityRange: velocityRangeLoading,
            keyRange: keyRangeLoading,
            channelNumber: id,
            presetNumber,
            bankId
          }
        }
      )
    },
    guards: {
      isSupportedChannel: ({ id }): boolean => {
        return CHANNELS_SUPPORTED.has(id)
      },
      shouldAllowLoadRequest: (ctx, event): boolean => {
        const { keyRange, velocityRange } = event as Events.PresetLoadRequest
        const {
          velocityRangeActive,
          keyRangeActive,
          velocityRangeLoading,
          keyRangeLoading
        } = ctx
        const velocityRangeBase = velocityRangeLoading ?? velocityRangeActive
        const keyRangeBase = keyRangeLoading ?? keyRangeActive

        assertIsObject(keyRangeBase)
        assertIsObject(velocityRangeBase)

        const keyRangeIsLarger = rangeExceeds(keyRange, keyRangeBase)
        const velocityRangeIsLarger = rangeExceeds(
          velocityRange,
          velocityRangeBase
        )

        if (!keyRangeIsLarger && !velocityRangeIsLarger) {
          return false
        }

        const isLoading = ctx.velocityRangeLoading !== undefined

        // trigger load if we aren't already loading
        if (!isLoading) {
          return true
        }

        const velocityRangeLoadingIsSame = isEqual(
          ctx.velocityRangeLoading,
          velocityRange
        )

        const keyRangeLoadingIsSame = isEqual(ctx.keyRangeLoading, keyRange)

        // trigger load if the requested ranges are outside of loading
        return !velocityRangeLoadingIsSame || !keyRangeLoadingIsSame
      }
    }
  }
)

export default channelMachine
