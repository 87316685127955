import getGlobalZone from './get-global-zone'
import getZonesForMidiNote from './get-zones-for-midi-note'
import {
  VelocityRange,
  KeyRange,
  getItemsMatchingRanges
} from 'midi-city-shared-types'
import { Instrument, InstrumentZone } from 'midi-city-api'
import { getNonGlobalZones } from './get-non-global-zones'

export function getInstrumentZonesMatchingRanges(
  instrument: Instrument,
  keyRange: KeyRange,
  velocityRange: VelocityRange
): InstrumentZone[] {
  return getItemsMatchingRanges(
    getNonGlobalZones(instrument),
    keyRange,
    velocityRange
  )
}

export * from './get-non-global-zones'
export { getGlobalZone, getZonesForMidiNote }
