import { Machine, assign, spawn, sendParent } from 'xstate'
import { assign as assignImmer } from '@xstate/immer'
import { assertIsObject } from 'assertate'
import { range, every } from 'lodash-es'
import { choose } from 'xstate/lib/actions'

import type {
  InstrumentZoneContext,
  InstrumentZoneEvent,
  InstrumentZoneSchema,
  InstrumentZoneEventLoadRequest,
  InstrumentZoneEmittedEventStartScheduled,
  InstrumentZoneEmittedEventReleaseScheduled,
  InstrumentZoneEmittedEventLoaded,
  InstrumentZoneEmittedEventConnected
} from './types'

import instrumentZoneMidiNoteMachine, {
  InstrumentZoneMidiNoteEmittedEventSampleLoadRequest,
  InstrumentZoneMidiNoteEmittedEventLoaded,
  InstrumentZoneMidiNoteEmittedEventStartScheduled,
  InstrumentZoneMidiNoteEmittedEventReleaseScheduled,
  InstrumentZoneMidiNoteEmittedEventConnected,
  InstrumentZoneMidiNoteActor
} from '../instrument-zone-midi-note'

import {
  rangeIntersection,
  KEY_RANGE_FULL,
  isValidRange,
  VELOCITY_RANGE_FULL,
  VelocityRange,
  MidiNote,
  KeyRange
} from 'midi-city-shared-types'

import { SampleEmittedEventBufferLoaded } from '../sample'
import { instrumentZoneCreatePanner } from '../preset-generator-zone/utils/midi-note/instrument-zone/get-panner'
import { ignoredAction, unexpectedAction } from 'midi-city-xstate-utils'
import { presetZoneInstrumentZoneCreateFilter } from './utils/create-filter'
import {
  getPresetZoneReverbGain,
  getInstrumentZoneChorusGain
} from '../preset-generator-zone/utils'

const instrumentZoneMachine = Machine<
  InstrumentZoneContext,
  InstrumentZoneSchema,
  InstrumentZoneEvent
>(
  {
    id: 'instrument-zone',
    initial: 'initialized',
    strict: true,
    states: {
      initialized: {
        entry: ['midiNotesInit'],
        on: {
          DISPOSE_REQUEST: {
            target: 'disposed'
          },

          SAMPLE_BUFFER_LOAD_REQUEST: {
            actions: [
              sendParent(
                (
                  _ctx: InstrumentZoneContext,
                  event: InstrumentZoneMidiNoteEmittedEventSampleLoadRequest
                ) => {
                  return event
                }
              )
            ]
          },

          SAMPLE_BUFFER_LOADED: {
            actions: ['sampleBufferLoadedFwd']
          },

          INSTRUMENT_ZONE_MIDI_NOTE_START_SCHEDULED: {
            actions: [
              sendParent(
                (
                  _ctx: InstrumentZoneContext,
                  event: InstrumentZoneMidiNoteEmittedEventStartScheduled
                ): InstrumentZoneEmittedEventStartScheduled => ({
                  ...event,
                  type: 'INSTRUMENT_ZONE_START_SCHEDULED'
                })
              )
            ]
          },

          INSTRUMENT_ZONE_MIDI_NOTE_RELEASE_SCHEDULED: {
            actions: sendParent(
              (
                _ctx: InstrumentZoneContext,
                event: InstrumentZoneMidiNoteEmittedEventReleaseScheduled
              ): InstrumentZoneEmittedEventReleaseScheduled => ({
                ...event,
                type: 'INSTRUMENT_ZONE_RELEASE_SCHEDULED'
              })
            )
          },

          INSTRUMENT_ZONE_MIDI_NOTE_LOADED: {
            actions: choose<
              InstrumentZoneContext,
              InstrumentZoneMidiNoteEmittedEventLoaded
            >([
              {
                actions: [
                  assign<
                    InstrumentZoneContext,
                    InstrumentZoneMidiNoteEmittedEventLoaded
                  >({
                    keyRangeActive: ({ keyRangeLoading }) => keyRangeLoading,
                    velocityRangeActive: ({ velocityRangeLoading }) =>
                      velocityRangeLoading
                  }),

                  assignImmer(draft => {
                    delete draft.velocityRangeLoading
                    delete draft.keyRangeLoading
                  }),

                  sendParent(
                    (
                      { keyRangeActive, velocityRangeActive },
                      _event
                    ): InstrumentZoneEmittedEventLoaded => {
                      assertIsObject(keyRangeActive)
                      assertIsObject(velocityRangeActive)
                      return {
                        type: 'INSTRUMENT_ZONE_LOADED',
                        keyRange: keyRangeActive,
                        velocityRange: velocityRangeActive
                      }
                    }
                  )
                ],
                cond: 'hasLoadedRequested'
              },
              {
                actions: ignoredAction
              }
            ])
          },

          LOAD_REQUEST: {
            actions: [
              'loadRequestAssign',
              ({ midiNotes, keyRangeLoading }): void => {
                if (keyRangeLoading === undefined) {
                  // refactor this into a choose
                  return
                }

                range(keyRangeLoading.lo, keyRangeLoading.hi + 1).forEach(
                  midiNote => {
                    const midiNoteActor = midiNotes[midiNote]
                    assertIsObject(midiNoteActor)
                    midiNoteActor.send({
                      type: 'LOAD_REQUEST'
                    })
                  }
                )
              }
            ]
          },

          START_REQUEST: {
            actions: [
              ({ midiNotes }, { midiNote, velocity, time, source }): void => {
                const midiNoteActor = midiNotes[midiNote]
                assertIsObject(midiNoteActor)
                midiNoteActor.send({
                  type: 'START',
                  velocity,
                  timeContext: time,
                  source
                })
              }
            ]
          },

          RELEASE_REQUEST: {
            actions: [
              ({ midiNotes }, { midiNote, time, source }): void => {
                const midiNoteActor = midiNotes[midiNote]
                assertIsObject(midiNoteActor)
                midiNoteActor.send({
                  type: 'RELEASE',
                  timeContext: time,
                  source
                })
              }
            ]
          }
        },

        initial: 'disconnected',

        states: {
          disconnected: {
            on: {
              INSTRUMENT_ZONE_MIDI_NOTE_CONNECTED: {
                target: 'connected',
                actions: ['midiNotesConnectedNumIncrement', 'midiNoteConnect']
              },
              INSTRUMENT_ZONE_MIDI_NOTE_DISCONNECTED: {
                actions: unexpectedAction
              }
            }
          },
          connected: {
            entry: ['nodesInit', 'nodesConnect', 'reportConnected'],
            exit: ['disconnect'],
            on: {
              INSTRUMENT_ZONE_MIDI_NOTE_CONNECTED: {
                actions: ['midiNoteConnect', 'midiNotesConnectedNumIncrement']
              },
              INSTRUMENT_ZONE_MIDI_NOTE_DISCONNECTED: [
                {
                  target: 'disconnected',
                  actions: ['midiNotesConnectedNumDecrement'],
                  cond: ({ midiNotesConnectedNum }): boolean =>
                    midiNotesConnectedNum === 1
                },
                {
                  actions: 'midiNotesConnectedNumDecrement'
                }
              ]
            }
          }
        }
      },
      disposed: {
        type: 'final',
        entry: [
          ({ filter, panner, reverbGain, chorusGain }): void => {
            filter?.dispose()
            panner?.dispose()
            reverbGain?.dispose()
            chorusGain?.dispose()
          },
          ({ midiNotes }): void => {
            Object.values(midiNotes).forEach(midiNote =>
              midiNote.send({ type: 'DISPOSE_REQUEST' })
            )
          }
        ]
      }
    }
  },
  {
    actions: {
      midiNotesInit: assign({
        midiNotes: ({
          presetZoneJSON,
          instrumentZoneJSON,
          isPercussion,
          audioContext
        }) => {
          const keyRangeIntersection = rangeIntersection(
            presetZoneJSON.keyRange ?? KEY_RANGE_FULL,
            instrumentZoneJSON.keyRange ?? KEY_RANGE_FULL
          )

          const velocityRangeIntersection = rangeIntersection(
            (presetZoneJSON.velRange as VelocityRange) ?? VELOCITY_RANGE_FULL,
            (instrumentZoneJSON.velRange as VelocityRange) ??
              VELOCITY_RANGE_FULL
          )

          const midiNotes: InstrumentZoneContext['midiNotes'] = {}

          range(keyRangeIntersection.lo, keyRangeIntersection.hi + 1).forEach(
            midiNote =>
              (midiNotes[midiNote] = spawn(
                instrumentZoneMidiNoteMachine.withContext({
                  audioContext,
                  midiNote: midiNote as MidiNote,
                  isPercussion,
                  presetZoneJSON,
                  instrumentZoneJSON,
                  velocityRange: velocityRangeIntersection
                }),
                { name: `midi-note-${midiNote}` }
              ) as InstrumentZoneMidiNoteActor)
          )
          return midiNotes
        }
      }),

      midiNotesConnectedNumIncrement: assignImmer(
        ctx => (ctx.midiNotesConnectedNum += 1)
      ),

      midiNotesConnectedNumDecrement: assignImmer(
        ctx => (ctx.midiNotesConnectedNum -= 1)
      ),

      disconnect: ({ reverbGain, chorusGain, filter, panner }): void => {
        assertIsObject(reverbGain)
        assertIsObject(chorusGain)
        assertIsObject(filter)
        assertIsObject(panner)
        panner.disconnect()
        reverbGain.disconnect()
        chorusGain.disconnect()
        filter.disconnect()
      },

      reportConnected: sendParent(
        ({
          reverbGain,
          chorusGain,
          panner
        }): InstrumentZoneEmittedEventConnected => {
          assertIsObject(reverbGain)
          assertIsObject(chorusGain)
          assertIsObject(panner)

          return {
            type: 'INSTRUMENT_ZONE_CONNECTED',
            outputNode: panner,
            chorusGain,
            reverbGain
          }
        }
      ),

      midiNoteConnect: ({ filter }, event): void => {
        assertIsObject(filter)
        const {
          outputNode
        } = event as InstrumentZoneMidiNoteEmittedEventConnected
        outputNode.connect(filter)
      },

      nodesInit: assign({
        panner: ctx =>
          instrumentZoneCreatePanner(
            ctx.presetZoneJSON,
            ctx.instrumentZoneJSON,
            ctx.audioContext
          ),

        filter: ctx =>
          presetZoneInstrumentZoneCreateFilter(
            ctx.presetZoneJSON,
            ctx.instrumentZoneJSON,
            ctx.audioContext
          ),

        reverbGain: (ctx, _event) =>
          getPresetZoneReverbGain(
            ctx.presetZoneJSON,
            ctx.instrumentZoneJSON,
            ctx.audioContext
          ),

        chorusGain: ctx =>
          getInstrumentZoneChorusGain(
            ctx.presetZoneJSON,
            ctx.instrumentZoneJSON,
            ctx.audioContext
          )
      }),

      nodesConnect: ({ filter, panner, chorusGain, reverbGain }): void => {
        assertIsObject(filter)
        assertIsObject(panner)
        assertIsObject(chorusGain)
        assertIsObject(reverbGain)
        filter.connect(panner)
        panner.fan(reverbGain, chorusGain)
      },

      loadRequestAssign: assign({
        keyRangeLoading: (
          { keyRangeLoading, presetZoneJSON, instrumentZoneJSON },
          event
        ) => {
          const { keyRange } = event as InstrumentZoneEventLoadRequest
          // find all midi notes that match
          // make sure eligible at all
          const keyRangeIntersection = rangeIntersection(
            keyRange,
            rangeIntersection(
              (presetZoneJSON.keyRange as KeyRange) ?? KEY_RANGE_FULL,
              (instrumentZoneJSON.keyRange as KeyRange) ?? KEY_RANGE_FULL
            )
          )

          if (!isValidRange(keyRangeIntersection)) {
            return keyRangeLoading
          }

          return keyRangeIntersection
        },

        velocityRangeLoading: (
          { velocityRangeLoading, presetZoneJSON, instrumentZoneJSON },
          event
        ) => {
          const { velocityRange } = event as InstrumentZoneEventLoadRequest
          const velocityRangeIntersection = rangeIntersection(
            velocityRange,
            rangeIntersection(
              (presetZoneJSON.velRange as VelocityRange) ?? VELOCITY_RANGE_FULL,
              (instrumentZoneJSON.velRange as VelocityRange) ??
                VELOCITY_RANGE_FULL
            )
          )

          if (!isValidRange(velocityRangeIntersection)) {
            return velocityRangeLoading
          }

          return velocityRangeIntersection
        }
      }),

      sampleBufferLoadedFwd: (
        { instrumentZoneJSON, midiNotes },
        event
      ): void => {
        const { sampleId } = event as SampleEmittedEventBufferLoaded

        const isMatchingSample = sampleId === instrumentZoneJSON.sampleId

        if (!isMatchingSample) {
          return
        }

        Object.values(midiNotes).forEach(midiNoteActor => {
          midiNoteActor.send(event as SampleEmittedEventBufferLoaded)
        })
      }
    },

    guards: {
      hasLoadedRequested: ({ midiNotes, keyRangeLoading }): boolean => {
        if (keyRangeLoading === undefined) {
          // this happens because we don't internally track the midi note load status
          return false
        }

        const midiNotesAllLoaded = every(
          range(keyRangeLoading.lo, keyRangeLoading.hi + 1).map(midiNote =>
            midiNotes[midiNote].state.matches('loaded')
          )
        )

        return midiNotesAllLoaded
      },

      hasEmptyLoading: ({ keyRangeLoading, velocityRangeLoading }): boolean => {
        return [keyRangeLoading, velocityRangeLoading].includes(undefined)
      }
    }
  }
)

export default instrumentZoneMachine
