import { Context, Events, PartCallback } from './types'
import { ActionFunctionMap, sendParent } from 'xstate'
import { assertIsObject, assertIsNumber } from 'assertate'
import { assign as assignImmer } from '@xstate/immer'

import {
  keyRangeCreate,
  velocityRangeCreate,
  VELOCITY_SUPPORTED,
  MidiNote
} from 'midi-city-shared-types'
import {
  ObservablePart,
  warnError,
  StartTimeError
} from 'midi-city-sound-engine'
import { PPQ } from '../recording-manager'
import { choose, send } from 'xstate/lib/actions'

const actions: ActionFunctionMap<Context, Events.All> = {
  recordNoteEvent: choose<Context, Events.All>([
    {
      actions: [
        // send overlap event
        sendParent(
          (_state, event): Events.EmittedNoteOverlapped => {
            const { midiNote } = event as Events.ScheduleNoteStart
            return {
              type: 'NOTE_START_OVERLAPPED_RECORDING',
              midiNote
            }
          }
        ),
        // force a release at the same time
        send(
          (_state, event): Events.ScheduleNoteRelease => {
            const { midiNote, tick } = event as Events.ScheduleNoteStart
            return {
              type: 'SCHEDULE_NOTE_RELEASE',
              midiNote,
              tick
            }
          }
        )
      ],
      // is a start event for a midi note being recorded already
      cond: (_state, event, meta): boolean => {
        const isStart = event.type === 'SCHEDULE_NOTE_START'

        if (!isStart) {
          return false
        }

        const { midiNote } = event as Events.ScheduleNoteStart

        return meta.state?.context.midiNotesRecording?.has(midiNote) ?? false
      }
    },
    {
      actions: [
        choose([
          {
            actions: 'midiNotesRecordingAdd',
            cond: (_context, event): boolean =>
              event.type === 'SCHEDULE_NOTE_START'
          }
        ]),
        ({ part, quarterNotes }, event): void => {
          assertIsObject(part)

          const { midiNote, tick } = event as
            | Events.ScheduleNoteStart
            | Events.ScheduleNoteRelease

          const isStart = event.type === 'SCHEDULE_NOTE_START'

          const callback = {
            midiNote,
            velocity: isStart
              ? (event as Events.ScheduleNoteStart).velocity * 100
              : undefined,
            type: isStart
              ? 'CHANNEL_NOTE_START_REQUEST'
              : 'CHANNEL_NOTE_RELEASE_REQUEST',
            to: '#_parent'
          }

          // TODO this is duplicated below
          const ticksMax = quarterNotes * PPQ - 1

          if (tick > ticksMax) {
            throw new Error(
              'Note event scheduling error: the event occurs after the max tick'
            )
          }

          part.add(`${tick}i`, callback)
        }
      ]
    }
  ]),

  midiNotesRecordingInit: assignImmer(ctx => {
    ctx.midiNotesRecording = new Set()
  }),

  midiNotesRecordingAdd: assignImmer((ctx, event) => {
    const { midiNote } = event as Events.ScheduleNoteStart
    const { midiNotesRecording } = ctx
    assertIsObject(midiNotesRecording)
    midiNotesRecording.add(midiNote)
  }),

  midiNotesRecordingRemove: assignImmer((ctx, event) => {
    const { midiNote } = event as Events.ScheduleNoteRelease
    const { midiNotesRecording } = ctx
    assertIsObject(midiNotesRecording)
    midiNotesRecording.delete(midiNote)
  }),

  partCreate: assignImmer(ctx => {
    let { part, quarterNotes } = ctx
    if (part !== undefined) {
      return part
    }

    // NOTE: can we get away with not storing the audioContext?
    const { audioContext } = ctx as Context
    assertIsObject(audioContext)

    part = new ObservablePart<PartCallback>({
      context: audioContext
    })

    part.loop = true

    part.mute = true
    part.start('0:0:0')
    part.loopEnd = `${quarterNotes}n`
    ctx.part = part
  }),

  partUpdate: ({ part, track, tickMultiplier }): void => {
    assertIsObject(part)
    assertIsObject(track)

    const trackTicks = track.durationTicks * tickMultiplier

    // we need to schedule any event that occurs on the last tick one tick earlier
    // see https://github.com/Tonejs/Tone.js/issues/694
    const ticksMax = trackTicks - 1

    try {
      part.clear()

      track.notes.forEach(note => {
        const startTicks = note.ticks * tickMultiplier
        const startTime = `${startTicks}i`

        const releaseTicks = Math.min(note.ticks + note.durationTicks, ticksMax)
        const releaseTime = `${releaseTicks * tickMultiplier}i`

        const startEvent: PartCallback = {
          type: 'CHANNEL_NOTE_START_REQUEST',
          midiNote: note.midi as MidiNote,
          velocity: note.velocity * 100,
          to: '#_parent'
        }

        const releaseEvent: PartCallback = {
          type: 'CHANNEL_NOTE_RELEASE_REQUEST',
          midiNote: note.midi as MidiNote,
          to: '#_parent'
        }

        part.add(startTime, startEvent)
        part.add(releaseTime, releaseEvent)
      })

      part.add(`${ticksMax}i`, { type: 'PART_LOOP_END' })

      const { endOfTrackTicks } = track

      assertIsNumber(endOfTrackTicks)

      part.loopEnd = `${endOfTrackTicks * tickMultiplier}i`
    } catch (error) {
      if (
        error instanceof Error &&
        error.message.includes('The time must be greater than')
      ) {
        warnError(new StartTimeError(error))
        return
      }

      throw error
    }
  },

  quarterNotesUpdate: assignImmer(ctx => {
    const { track, tickMultiplier } = ctx

    assertIsObject(track)

    const { endOfTrackTicks } = track

    assertIsNumber(endOfTrackTicks)

    const quarterNotes = (endOfTrackTicks * tickMultiplier) / PPQ

    ctx.quarterNotes = quarterNotes
  }),

  quarterNotesReportUpdate: sendParent(
    ({ quarterNotes, channelNumber }): Events.EmittedDurationUpdated => ({
      type: 'TRACK_MANAGER_DURATION_UPDATED',
      channelNumber,
      quarterNotes
    })
  ),

  tickMultiplierUpdate: assignImmer((ctx, event) => {
    const { tickMultiplier } = event as Events.LoadRequest
    ctx.tickMultiplier = tickMultiplier
  }),

  trackAssign: assignImmer((ctx, event) => {
    ctx.track = (event as Events.LoadRequest).track
  }),

  sendLoadRequested: sendParent(
    ({ channelNumber }): Events.EmittedLoadRequested => {
      const keyRange = keyRangeCreate(0, 127)

      const velocityRange = velocityRangeCreate(
        VELOCITY_SUPPORTED,
        VELOCITY_SUPPORTED
      )

      return {
        type: 'TRACK_MANAGER_LOAD_REQUESTED',
        channelNumber,
        keyRange,
        velocityRange
      }
    }
  ),

  sendLoadSuccess: sendParent(
    ({ channelNumber, audioContext }): Events.EmittedLoadSuccess => ({
      type: 'TRACK_MANAGER_LOAD_SUCCESS',
      channelNumber,
      audioContext
    })
  ),

  loadSourceRequestUpdate: choose([
    {
      actions: [
        'tickMultiplierUpdate',
        'trackAssign',
        'sendLoadRequested',
        'partUpdate',
        'quarterNotesUpdate',
        'quarterNotesReportUpdate'
      ]
    }
  ]),

  start: ({ part, audioContext }): void => {
    assertIsObject(part)
    assertIsObject(audioContext)
    if (audioContext.transport.state !== 'started') {
      audioContext.transport.start()
    }
    part.mute = false
  },

  stop: ({ part, audioContext }): void => {
    assertIsObject(part)
    assertIsObject(audioContext)
    part.mute = true
  }
}

export default actions
