import { Machine, sendParent } from 'xstate'
import { assertIsObject } from 'assertate'
import { Observable } from 'rxjs'
import { pure, send, choose } from 'xstate/lib/actions'
import { map } from 'rxjs/operators'
import { NonUndefined } from 'utility-types'

import { Context, Schema, Events } from './types'
import actions from './actions'
import { unexpectedAction, ignoredAction } from 'midi-city-xstate-utils'
import { omit } from 'lodash-es'
import { MidiNoteEventSource } from 'midi-city-shared-types'
import * as guards from './guards'

export * from './types'

const machine = Machine<Context, Schema, Events.All>(
  {
    id: 'midi-track-manager',

    strict: true,

    on: {
      '*': {
        actions: [unexpectedAction]
      }
    },

    initial: 'uninitialized',

    states: {
      uninitialized: {
        entry: ['partCreate', 'midiNotesRecordingInit'],
        always: {
          target: 'initialized'
        }
      },

      initialized: {
        entry: [
          sendParent(
            ({
              part,
              channelNumber,
              trackType
            }): Events.EmittedInitialized => ({
              type: 'TRACK_MANAGER_INITIALIZED',
              part: part as NonUndefined<Context['part']>,
              trackType,
              channelNumber
            })
          ),
          'quarterNotesReportUpdate'
        ],

        invoke: {
          id: 'part-responder',
          src: ({ part }): Observable<Events.PartEventTriggered> => {
            assertIsObject(part)
            return part.subject.pipe(
              map(([time, event]) => ({
                type: 'PART_EVENT_TRIGGERED',
                timeContext: time,
                event
              }))
            )
          }
        },

        on: {
          SCHEDULE_NOTE_START: {
            actions: ['recordNoteEvent']
          },

          SCHEDULE_NOTE_RELEASE: {
            actions: ['midiNotesRecordingRemove', 'recordNoteEvent']
          },

          PART_LOOP_END: {
            actions: [
              pure(({ channelNumber, audioContext }, event) =>
                send(
                  {
                    ...event,
                    channelNumber,
                    audioContext
                  } as Events.EmittedPartLoopEnd,
                  { to: '#_parent' }
                )
              ),
              choose([
                {
                  actions: [
                    pure<Context, any>(
                      ({ midiNotesRecording, channelNumber }, { time }) => {
                        assertIsObject(midiNotesRecording)
                        return Array.from(midiNotesRecording.values()).map(
                          midiNote => {
                            return sendParent(
                              (): Events.EmittedChannelNoteRelease => ({
                                type: 'CHANNEL_NOTE_RELEASE_REQUEST',
                                midiNote,
                                channelNumber,
                                time,
                                source: MidiNoteEventSource.TrackManager
                              })
                            )
                          }
                        )
                      }
                    )
                  ],
                  cond: ({ midiNotesRecording }): boolean =>
                    midiNotesRecording !== undefined
                      ? midiNotesRecording.size > 0
                      : false
                }
              ])
            ]
          },

          PART_EVENT_TRIGGERED: {
            actions: [
              pure(({ channelNumber }, { timeContext: time, event }) => {
                return send(
                  {
                    ...omit(event, ['to']),
                    time,
                    channelNumber,
                    source: MidiNoteEventSource.Scheduled
                  },
                  { to: event.to }
                )
              })
            ]
          }
        },

        type: 'parallel',

        states: {
          playing: {
            initial: 'inactive',
            states: {
              inactive: {
                on: {
                  START_REQUEST: {
                    target: 'active'
                  }
                }
              },

              active: {
                entry: ['start'],
                exit: ['stop'],
                on: {
                  START_REQUEST: {
                    actions: ignoredAction
                  },
                  STOP_REQUEST: {
                    target: 'inactive'
                  }
                }
              }
            }
          },

          sourceFile: {
            initial: 'unloaded',

            on: {
              CHANNEL_LOADING: {
                target: '.loading',
                cond: 'isMatchingChannel'
              },

              LOAD_SOURCE_REQUEST: {
                actions: ['loadSourceRequestUpdate'],
                target: ['.loading']
              }
            },

            states: {
              unloaded: {
                on: {
                  CHANNEL_LOAD_SUCCESS: {
                    actions: ignoredAction,
                    cond: 'isMatchingChannel'
                  }
                }
              },

              loading: {
                entry: [
                  sendParent(
                    ({ channelNumber, audioContext }) =>
                      ({
                        type: 'TRACK_MANAGER_LOADING',
                        channelNumber,
                        audioContext
                      } as Events.EmittedLoading)
                  )
                ],
                on: {
                  START_REQUEST: { actions: ignoredAction },
                  STOP_REQUEST: {
                    actions: [ignoredAction]
                  },
                  CHANNEL_LOAD_SUCCESS: [
                    {
                      target: ['loaded'],
                      cond: 'isMatchingChannel'
                    }
                  ]
                }
              },

              loaded: {
                entry: ['sendLoadSuccess'],
                on: {
                  LOAD_SOURCE_REQUEST: {
                    actions: ['loadSourceRequestUpdate'],
                    target: 'loaded',
                    internal: false
                  },

                  CHANNEL_LOAD_SUCCESS: {
                    actions: [ignoredAction],
                    cond: 'isMatchingChannel'
                  },

                  CHANNEL_LOADING: {
                    target: 'loading',
                    cond: 'isMatchingChannel'
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  {
    actions,
    guards
  }
)

export { machine }
