import { assign } from '@xstate/immer'
import { ApolloQueryResult } from '@apollo/client'
import {
  AuthenticateTokenDocument,
  buildClient,
  GetCurrentUserDocument,
  GetCurrentUserQuery
} from 'midi-city-server'
import { Machine } from 'xstate'
import { assertIsObject, assertIsString } from 'assertate'

interface Context {
  token?: string
  redirectCallback?: () => void
  error?: Error
  urlBase?: string
}

const machine = Machine<Context>({
  id: 'email-verification',
  strict: true,
  initial: 'waitingForToken',

  context: {
    token: undefined,
    redirectCallback: undefined,
    urlBase: undefined
  },

  states: {
    waitingForToken: {
      on: {
        INITIALIZE: {
          actions: assign((ctx, event) => {
            ctx.token = event.token
            ctx.redirectCallback = event.redirectCallback
          }),
          target: 'fetchingCurrentUser'
        }
      }
    },

    fetchingCurrentUser: {
      invoke: {
        id: 'fetch-user',
        src: async ({
          urlBase
        }): Promise<ApolloQueryResult<GetCurrentUserQuery>> => {
          assertIsString(urlBase)
          return await buildClient(urlBase).query({
            query: GetCurrentUserDocument
          })
        },
        onDone: [
          {
            target: 'authenticatingToken',
            cond: (_ctx, event): boolean => {
              const data: ApolloQueryResult<GetCurrentUserQuery> = event.data
              return data.data.getCurrentUser === undefined
            },
            actions: (_ctx, _event): void => {
              // console.log('user=======>', { event })
            }
          },
          {
            target: 'alreadyLoggedIn'
          }
        ],
        onError: {
          target: 'failure',
          actions: (ctx, event): void => {
            ctx.error = event.data
          }
        }
      }
    },

    authenticatingToken: {
      invoke: {
        id: 'submit-token',
        src: async ({ token, urlBase }): Promise<any> => {
          assertIsString(urlBase)
          return await buildClient(urlBase).mutate({
            mutation: AuthenticateTokenDocument,
            variables: {
              token
            }
          })
        },
        onDone: {
          target: 'success',
          actions: (_ctx, _event): void => {
            // console.log({ event })
          }
        },
        onError: {
          target: 'failure',
          actions: (ctx, event): void => {
            ctx.error = event.data
          }
        }
      }
    },

    alreadyLoggedIn: {
      always: {
        target: 'success'
      }
    },

    failure: {},

    success: {
      entry: ({ redirectCallback }): void => {
        assertIsObject(redirectCallback)
        redirectCallback()
      }
    }
  }
})

export { machine }
