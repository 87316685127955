import { BANK_ID_PERCUSSION_DEFAULT, BankIdT } from 'midi-city-shared-types'

export * from './app-machine'
export * from './channel'
export * from './keyboard-key'
export * from './virtual-controller'
export * from './keyboard'
export * from './shortcut-manager'

export function getBankIdIsMelodic(bankId: BankIdT): boolean {
  return bankId !== BANK_ID_PERCUSSION_DEFAULT
}
