import { Preset } from 'midi-city-api'
import { PresetNumber } from 'midi-city-shared-types'

function getByIdAndBankId(
  presetId: PresetNumber,
  bankId: number,
  presets: Preset[]
): Preset {
  const preset = presets.find(
    (preset): boolean => preset.midiId === presetId && preset.bankId === bankId
  )
  if (preset === undefined) {
    throw new Error('missing preset')
  }
  return preset
}

export default getByIdAndBankId
