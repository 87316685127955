import { ServiceConfig } from 'xstate'
import WebMidi, { InputEventNoteon, InputEventNoteoff } from 'webmidi'
import { fromEventPattern, Observable, merge, from } from 'rxjs'
import { assertIsObject } from 'assertate'
import { ChannelT } from 'midi-city-shared-types'
import { map } from 'rxjs/operators'

import { Context, Events } from './types'
import { midisConnected } from './utils/midis-connected'
import { MidiInputNoteStart, MidiInputNoteRelease } from './types/events'
import { webMidiEnable } from './utils/web-midi-enable'

const services: Record<string, ServiceConfig<Context, Events.All>> = {
  midiInputsObserver: (): any =>
    from(midisConnected).pipe(
      map(() => ({
        type: 'MIDI_INPUTS_CHANGED',
        midiInputs: WebMidi.inputs.filter(input => input.type === 'input')
      }))
    ),

  requestMidiAccess: (): any =>
    from(webMidiEnable).pipe(
      map(error =>
        error !== undefined
          ? { type: 'MIDI_INPUTS_ERROR' }
          : { type: 'MIDI_INPUTS_ENABLED' }
      )
    ),

  midiInputObserver: ({
    inputDevice,
    midiInputSelectedId,
    midiInputs,
    channels,
    notificationManager
  }: Context): Observable<any> => {
    assertIsObject(notificationManager)
    assertIsObject(channels)
    assertIsObject(inputDevice)

    const midiInput = midiInputs.find(input => input.id === midiInputSelectedId)
    assertIsObject(midiInput)

    const noteOn$ = fromEventPattern<InputEventNoteon>(
      handler => midiInput.addListener('noteon', 'all', handler),
      _handler => midiInput.removeListener('noteon')
    ).pipe(
      map(event => {
        const { channel, note, rawVelocity } = event
        return {
          type: 'MIDI_INPUT_START',
          channelNumber: channel as ChannelT,
          noteNumber: note.number,
          velocity: rawVelocity
        } as MidiInputNoteStart
      })
    )

    const noteOff$ = fromEventPattern<InputEventNoteoff>(
      handler => midiInput.addListener('noteoff', 'all', handler),
      _handler => midiInput.removeListener('noteoff')
    ).pipe(
      map(event => {
        const { channel, note } = event
        return {
          type: 'MIDI_INPUT_RELEASE',
          channelNumber: channel as ChannelT,
          noteNumber: note.number
        } as MidiInputNoteRelease
      })
    )

    return merge(noteOn$, noteOff$)
  }
}

export { services }
