import { State, Interpreter, Machine, assign, SpawnedActorRef } from 'xstate'
import { AppMachine } from 'midi-city-app-manager'

export interface NotificationManagerContext {
  notifications: any[]
}

export interface NotificationManagerSchema {
  states: {
    uninitialized: {}
    initialized: {}
  }
}

interface NotificationManagerEventMessageAddRequest {
  type: 'ADD_REQUEST'
  identifier: MessageIdentifier
}

interface NotificationManagerEventMessagesClear {
  type: 'CLEAR_REQUEST'
}

export type NotificationManagerEvent =
  | NotificationManagerEventMessageAddRequest
  | NotificationManagerEventMessagesClear

export type NotificationManagerState = State<
  NotificationManagerContext,
  NotificationManagerEvent,
  NotificationManagerSchema
>

export type NotificationManagerInterpreter = Interpreter<
  NotificationManagerContext,
  NotificationManagerSchema,
  NotificationManagerEvent
>

export type NotificationManagerActor = SpawnedActorRef<NotificationManagerEvent>

export enum MessageIdentifier {
  BadChannel
}

export interface Message {
  identifier: MessageIdentifier
  text: string
  actionText: string
  action: AppMachine.Events.All
}

export const BAD_CHANNEL: Message = {
  identifier: MessageIdentifier.BadChannel,
  text: 'Bad MIDI Input Channel Detected',
  actionText: 'See Config',
  action: { type: 'MODAL_CHANGE', modal: 'MidiInput' }
}

const MESSAGES = {
  [MessageIdentifier.BadChannel]: BAD_CHANNEL
}

export default Machine<
  NotificationManagerContext,
  NotificationManagerSchema,
  NotificationManagerEvent
>({
  id: 'notification-manager',
  initial: 'uninitialized',
  strict: true,
  context: {
    notifications: []
  },
  states: {
    uninitialized: {
      always: {
        target: 'initialized'
      }
    },
    initialized: {
      on: {
        CLEAR_REQUEST: {
          actions: [
            assign({
              notifications: _ctx => []
            })
          ]
        },
        ADD_REQUEST: {
          actions: [
            assign({
              notifications: ({ notifications }, { identifier }) => [
                ...notifications,
                MESSAGES[identifier]
              ]
            })
          ]
        }
      }
    }
  }
})
