import { Time, Unit, context } from 'tone'
import { get } from 'lodash-es'
import { InstrumentZone, Sample } from 'midi-city-api'

const SAMPLE_DATA_POINTS = 32768

interface LoopPoints {
  loopStart: Unit.Seconds
  loopEnd: Unit.Seconds
}

export function getInstrumentZoneLoopPoints(
  instrumentZone: InstrumentZone,
  sample: Sample
): LoopPoints {
  const sampleRateContext = context.sampleRate

  const multiplier = sampleRateContext / sample.sampleRate

  const startLoopOffset = get(instrumentZone, 'startLoopAddrsOffset', 0)
  const startLoopCoarseOffset = get(
    instrumentZone,
    'startLoopAddrsCoarseOffset',
    0
  )

  const endLoopOffset = get(instrumentZone, 'endLoopAddrsOffset', 0)
  const endLoopCoarseOffset = get(instrumentZone, 'endLoopAddrsCoarseOffset', 0)

  const loopStart = Time(
    (sample.startLoop +
      startLoopOffset +
      startLoopCoarseOffset / SAMPLE_DATA_POINTS) *
      multiplier,
    'samples'
  ).toSeconds()

  const loopEnd = Time(
    (sample.endLoop +
      endLoopOffset +
      endLoopCoarseOffset / SAMPLE_DATA_POINTS) *
      multiplier,
    'samples'
  ).toSeconds()

  return { loopStart, loopEnd }
}
