import { Machine, assign, sendParent, forwardTo } from 'xstate'
import { assertIsObject } from 'assertate'
import { NonUndefined } from 'utility-types'
import { actions } from './actions'
import { services } from './services'

import { unexpectedAction, ignoredAction } from 'midi-city-xstate-utils'
import {
  MidiNoteEventSource,
  ChannelT,
  CHANNELS_SUPPORTED
} from 'midi-city-shared-types'

import { VirtualControllerInterpreter } from '../virtual-controller'

import { KeyboardMachineEmittedEventLayoutStyleChanged } from '../keyboard-machine/events'
import { Schema, Context, Events } from './types'
import { choose, pure, send } from 'xstate/lib/actions'

export * from './types'
export default Machine<Context, Schema, Events.All>(
  {
    id: 'midi-device-manager',

    strict: true,

    on: {
      DISPOSE_REQUEST: {
        target: 'disposed'
      },
      // @ts-expect-error
      'xstate.error': {
        actions: (_ctx: Context, { data }: { data: Error }): void => {
          throw data
        }
      },
      '*': {
        actions: unexpectedAction
      }
    },

    initial: 'uninitialized',

    states: {
      uninitialized: {
        on: {
          INITIALIZE: {
            target: 'initialized',
            actions: ['initialize']
          }
        }
      },

      initialized: {
        on: {
          GLOBAL_CHANNEL_LOADING: [
            {
              cond: 'isSupportedChannel',
              actions: [
                ({ virtualControllers }, event): void => {
                  assertIsObject(virtualControllers)
                  const virtualController = getMatchingVirtualController(
                    virtualControllers,
                    event
                  )

                  if (virtualController === undefined) {
                    return
                  }

                  virtualController.send({
                    type: 'CHANNEL_PRESET_LOADING',
                    keyRange: event.keyRange
                  })
                }
              ]
            },
            {
              actions: ignoredAction
            }
          ],

          GLOBAL_CHANNEL_LOADED: [
            {
              cond: 'isSupportedChannel',
              actions: [
                ({ virtualControllers }, event): void => {
                  assertIsObject(virtualControllers)
                  const virtualController = getMatchingVirtualController(
                    virtualControllers,
                    event
                  )

                  assertIsObject(virtualController)

                  virtualController.send({
                    type: 'CHANNEL_PRESET_LOADED',
                    keyRange: event.keyRange
                  })
                }
              ]
            },
            {
              actions: ignoredAction
            }
          ],

          GLOBAL_CHANNEL_NOTE_START_SCHEDULED: {
            actions: [
              ({ virtualControllers }, event): void => {
                assertIsObject(virtualControllers)

                const virtualController = getMatchingVirtualController(
                  virtualControllers,
                  event
                )
                assertIsObject(virtualController)

                virtualController.send({
                  type: 'NOTE_START_SCHEDULED',
                  midiNote: event.midiNote,
                  source: event.source,
                  timeContext: event.timeContextScheduled
                })
              }
            ]
          },

          CHANNEL_NOTE_RELEASE_REQUEST: {
            actions: forwardTo('#_parent')
          },

          CHANNEL_NOTE_START_REQUEST: {
            actions: forwardTo('#_parent')
          },

          CHANNEL_PRESET_CHANGE_REQUEST: {
            actions: forwardTo('#_parent')
          },

          CHANNEL_INITIALIZED: {
            actions: ignoredAction
          },

          TRACK_MANAGER_INITIALIZED: [
            {
              actions: [
                'setChannelPart',
                'virtualControllerInit',
                'layoutManagerSendVirtualController',
                'shortcutManagerSendVirtualControllerKeyboard'
              ],
              cond: 'isSupportedChannel'
            },
            {
              actions: ignoredAction
            }
          ],

          TRACK_MANAGER_DURATION_UPDATED: [
            {
              actions: ({ virtualControllers }, event): void => {
                const { channelNumber } = event
                const virtualController = virtualControllers?.get(channelNumber)
                assertIsObject(virtualController)
                virtualController.send(event)
              },
              cond: 'isSupportedChannel'
            },
            {
              actions: ignoredAction
            }
          ],

          TRACK_MANAGER_LOAD_REQUESTED: {
            actions: ['scheduleClear']
          },

          TRACK_MANAGER_LOAD_SUCCESS: {
            actions: [ignoredAction]
          },

          TRACKS_MANAGER_STARTED: {
            actions: ({ virtualControllers }): void => {
              assertIsObject(virtualControllers)
              virtualControllers.forEach(virtualController => {
                virtualController.send({ type: 'TRACK_STARTED' })
              })
            }
          },

          TRACKS_MANAGER_STOPPED: {
            actions: ({ virtualControllers }): void => {
              assertIsObject(virtualControllers)
              virtualControllers.forEach(virtualController => {
                virtualController.send({ type: 'TRACK_STOPPED' })
              })
            }
          },

          GLOBAL_CHANNEL_NOTE_RELEASE_SCHEDULED: {
            actions: [
              ({ virtualControllers }, event): void => {
                const {
                  channelNumber,
                  midiNote,
                  source,
                  timeContextScheduled
                } = event

                assertIsObject(virtualControllers)
                const virtualController = virtualControllers.get(channelNumber)

                assertIsObject(virtualController)

                virtualController.send({
                  type: 'NOTE_RELEASE_SCHEDULED',
                  midiNote,
                  source,
                  timeContext: timeContextScheduled
                })
              }
            ]
          },

          MIDI_INPUT_START: {
            actions: [
              'inputChannelLatestUpdate',
              choose([
                {
                  cond: 'isSupportedChannel',
                  actions: [
                    (
                      { inputDevice },
                      { channelNumber, noteNumber, velocity }
                    ): void => {
                      assertIsObject(inputDevice)
                      inputDevice.send({
                        type: 'MIDI_DEVICE_NOTE_START_REQUEST',
                        channelNumber,
                        note: noteNumber,
                        velocity,
                        source: MidiNoteEventSource.Hardware
                      })
                    }
                  ]
                },
                {
                  actions: 'unsupportedChannelReport'
                }
              ])
            ]
          },

          MIDI_INPUT_RELEASE: {
            actions: [
              'inputChannelLatestUpdate',
              choose([
                {
                  cond: 'isSupportedChannel',
                  actions: [
                    ({ inputDevice }, { channelNumber, noteNumber }): void => {
                      assertIsObject(inputDevice)
                      inputDevice.send({
                        type: 'MIDI_DEVICE_NOTE_RELEASE_REQUEST',
                        channelNumber,
                        note: noteNumber,
                        source: MidiNoteEventSource.Hardware
                      })
                    }
                  ]
                },
                {
                  actions: 'unsupportedChannelReport'
                }
              ])
            ]
          },

          CHANNEL_NOTE_RECORDING_STARTED: {
            actions: pure(({ virtualControllers }, event) => {
              assertIsObject(virtualControllers)
              const virtualController = getMatchingVirtualController(
                virtualControllers,
                event
              )

              assertIsObject(virtualController)

              const { channelNumber } = event

              return send(event, { to: channelNumber.toString() })
            })
          },

          CHANNEL_NOTE_RECORDING_RELEASED: {
            actions: pure(({ virtualControllers }, event) => {
              assertIsObject(virtualControllers)
              const virtualController = getMatchingVirtualController(
                virtualControllers,
                event
              )

              assertIsObject(virtualController)

              const { channelNumber } = event

              return send(event, { to: channelNumber.toString() })
            })
          },

          KEYBOARD_LAYOUT_STYLE_CHANGED: {
            actions: [
              sendParent(
                (
                  _ctx: Context,
                  event: KeyboardMachineEmittedEventLayoutStyleChanged
                ) => event
              )
            ]
          }
        },

        initial: 'idle',

        states: {
          idle: {
            always: {
              target: 'requestingMIDIAccess'
            }
          },

          requestingMIDIAccess: {
            invoke: {
              id: 'requesting-midi-access',
              src: 'requestMidiAccess'
            },

            on: {
              MIDI_INPUTS_ENABLED: {
                target: 'monitoringInputs'
              },
              MIDI_INPUTS_ERROR: {
                target: 'error'
              }
            }
          },

          monitoringInputs: {
            invoke: {
              id: 'monitoring-inputs',
              src: 'midiInputsObserver'
            },

            on: {
              MIDI_INPUTS_CHANGED: {
                actions: ['midiInputsUpdate', 'midiInputSelectedSetDefault'],
                target: ['.resetting']
              }
            },

            initial: 'resetting',

            states: {
              idle: {},

              resetting: {
                after: {
                  // 0 timeout used so that the observable in monitoringInput
                  // is unsubscribed, otherwise it is never unsubscribed
                  0: [
                    {
                      target: 'monitoringInput',
                      cond: 'hasSelectedInput'
                    },
                    { target: 'idle' }
                  ]
                }
              },

              monitoringInput: {
                invoke: {
                  id: 'monitoring-input',
                  src: 'midiInputObserver'
                },
                on: {
                  MIDI_INPUTS_SELECTED_CHANGE_REQUEST: {
                    actions: [
                      assign({
                        // TODO verify this is a valid id
                        midiInputSelectedId: (_ctx, { id }) => id
                      })
                    ],
                    target: 'resetting'
                  }
                }
              }
            }
          },

          error: {}
        }
      },

      disposed: {
        type: 'final',
        entry: (ctx): void => {
          ctx.virtualControllers?.forEach(virtualController =>
            virtualController.send({ type: 'DISPOSE_REQUEST' })
          )
        }
      }
    }
  },
  {
    actions,
    services,
    guards: {
      isSupportedChannel: (_ctx, event): boolean => {
        // event is being converted into Schema for some reason
        // @ts-expect-error
        const { channelNumber } = event as
          | Events.MidiInputNoteStart
          | Events.MidiInputNoteRelease
        return CHANNELS_SUPPORTED.has(channelNumber)
      },

      hasSelectedInput: (ctx: Context): boolean =>
        ctx.midiInputSelectedId !== undefined
    }
  }
)

function getMatchingVirtualController<T extends { channelNumber: ChannelT }>(
  virtualControllers: NonUndefined<Context['virtualControllers']>,
  event: T
): VirtualControllerInterpreter | undefined {
  const virtualController = virtualControllers.get(event.channelNumber)
  return virtualController as VirtualControllerInterpreter | undefined
}
