import { assign, spawn, forwardTo } from 'xstate'
import { assign as assignImmer } from '@xstate/immer'
import { startTransaction } from '@sentry/browser'
import { assertIsString, assertIsBoolean, assertIsObject } from 'assertate'
import deepFreeze from 'deep-freeze-es6'
// import { ApolloQueryResult } from '@apollo/client'

import layoutManagerMachine from '../layout-manager'
import { AppMachineConfig, AppMachineContext } from './types'
import { getApiResponse, ApiResponse } from 'midi-city-api'

import { unexpectedAction } from 'midi-city-xstate-utils'
import notificationManager from '../notification-manager'
import { TRANSACTION_INIT_ID } from '.'
// import { GetCurrentUserQuery } from 'midi-city-server'

export const LOG_PREFIX = '# APP MACHINE: '

const states: AppMachineConfig['states'] = {
  uninitialized: {
    entry: assign({
      transactionInit: _ctx => startTransaction({ name: TRANSACTION_INIT_ID }),
      layoutManager: _ctx =>
        spawn(layoutManagerMachine, { name: 'layout-manager' }),
      notificationManager: _ctx => spawn(notificationManager)
    }),

    on: {
      INITIALIZE: {
        target: 'initializing',
        actions: [
          assignImmer((ctx, { urlBase, recordingAvailable }) => {
            ctx.channels = new Map()
            ctx.urlBase = urlBase
            ctx.recordingAvailable = recordingAvailable
          }),
          'channelsInit'
        ]
      }
    }
  },

  initializing: {
    initial: 'fetchingApi',

    entry: [
      'isMobileAssign',
      'midiDeviceManagerCreate',
      'shortcutManagerAssign',
      'midiDeviceManagerInit',
      'channelsInit',
      ({ isMobile, layoutManager }): void => {
        assertIsBoolean(isMobile)
        assertIsObject(layoutManager)
        layoutManager.send({ type: 'IS_MOBILE_UPDATE', value: isMobile })
      }
    ],
    on: {
      CHANNEL_INITIALIZED: [
        {
          actions: [
            (): never => {
              throw new Error('stop')
            },
            'channelInitializedForwardTracksManager',
            'channelInitializedForwardDeviceManager'
          ]
        }
      ]
    },

    states: {
      // TODO a lot of these can be done in parallel
      fetchingApi: {
        invoke: {
          id: 'fetchApi',
          src: async ({ urlBase }): Promise<ApiResponse> => {
            assertIsString(urlBase)
            return await getApiResponse(urlBase)
          },
          onDone: {
            target: 'initializingEngine',
            actions: assign({
              // deep freeze gives perf. benefit to immer
              api: (_ctx, { data }) => {
                return deepFreeze(data)
              }
              // currentUser: (_ctx, { data }) =>
              //   (data[1] as ApolloQueryResult<GetCurrentUserQuery>).data
              //     .getCurrentUser
            })
          }
        }
      },

      initializingEngine: {
        entry: ['globalAssign', 'globalSendInitialize'],
        on: {
          GLOBAL_INITIALIZED: {
            target: 'postEngineInitialized'
          },

          GLOBAL_FAILED: {
            actions: [unexpectedAction]
          }
        }
      },

      postEngineInitialized: {
        entry: ['recordingManagerSpawn', 'recordingManagerInit'],
        always: [
          {
            target: '#app-manager.ready.unmutingAudio',
            cond: ({ isMobile }): boolean => isMobile === true
          },
          {
            target: '#app-manager.ready.idle'
          }
        ]
      }
    }
  },

  ready: {
    entry: [
      ({ transactionInit }: AppMachineContext): void => {
        transactionInit?.finish()
      }
    ],

    on: {
      CHANNELS_LOAD_REQUEST: {
        actions: ['channelsSendLoad']
      },

      GLOBAL_CHANNEL_NOTE_START_SCHEDULED: {
        actions: [
          forwardTo('recording-manager'),
          forwardTo('midi-device-manager')
        ]
      },

      GLOBAL_CHANNEL_NOTE_RELEASE_SCHEDULED: {
        actions: [
          forwardTo('recording-manager'),
          forwardTo('midi-device-manager')
        ]
      },

      GLOBAL_CHANNEL_LOADED: {
        actions: [
          forwardTo('recording-manager'),
          forwardTo('midi-device-manager')
        ]
      },

      GLOBAL_CHANNEL_LOADING: {
        actions: [
          'channelSetLoading',
          forwardTo('recording-manager'),
          forwardTo('midi-device-manager')
        ]
      },

      KEYBOARD_LAYOUT_STYLE_CHANGED: {
        actions: [forwardTo('layout-manager')]
      },

      CHANNEL_NOTE_START_REQUEST: {
        actions: forwardTo('sound-engine')
      },

      CHANNEL_NOTE_RELEASE_REQUEST: {
        actions: [forwardTo('sound-engine')]
      },

      CHANNEL_RELEASE_ALL_REQUEST: {
        actions: [forwardTo('sound-engine')]
      },

      CHANNEL_PRESET_CHANGE_REQUEST: {
        actions: [forwardTo('sound-engine')]
      },

      MIDIS_FETCHED: {
        actions: 'recordingManagerLoadDefault'
      },

      TRACKS_MANAGER_STARTED: {
        actions: [forwardTo('midi-device-manager')]
      },

      TRACKS_MANAGER_STOPPED: {
        actions: [forwardTo('midi-device-manager')]
      },

      TRACK_MANAGER_LOAD_SUCCESS: {
        actions: [forwardTo('midi-device-manager')]
      },

      TRACK_MANAGER_LOAD_REQUESTED: {
        actions: [forwardTo('midi-device-manager')]
      },

      CHANNEL_NOTE_RECORDING_STARTED: {
        actions: [forwardTo('midi-device-manager')]
      },

      CHANNEL_NOTE_RECORDING_RELEASED: {
        actions: [forwardTo('midi-device-manager')]
      },

      MENU_CHANGE: {
        actions: assignImmer((ctx, event) => {
          ctx.menuOpen = event.menu
        })
      }
    },

    initial: 'idle',

    states: {
      idle: {},
      unmutingAudio: {
        invoke: {
          src: async (): Promise<void> => {
            // TODO write or use unmute-ios-audio types
            const unmuteAudio = await import('unmute-ios-audio')
            unmuteAudio.default()
          },
          onDone: 'idle'
        }
      }
    }
  },

  disposed: {
    type: 'final',
    entry: (ctx): void => {
      ctx.recordingManager?.send({ type: 'DISPOSE_REQUEST' })

      ctx.midiDeviceManager?.send({ type: 'DISPOSE_REQUEST' })

      ctx.global?.send({ type: 'DISPOSE_REQUEST' })

      ctx.layoutManager?.send({ type: 'DISPOSE_REQUEST' })
    }
  }
}

export default states
