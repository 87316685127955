import { LayoutRectangle } from 'midi-city-shared-types'
import { KeyboardKeyState } from '../../keyboard-key-machine'
import { getKeyLayout } from '../../keyboard-machine/utils'

export function getKeyboardKeyDimensions(
  state: KeyboardKeyState
): LayoutRectangle {
  const { numKeys, keyboardLayoutStyle, keyboardLayout } = state.context
  const number = state.context.number

  return getKeyLayout(number, numKeys, keyboardLayout, keyboardLayoutStyle)
}

export function getKeyboardKeyShortcut(
  state: KeyboardKeyState
): string | undefined {
  const shortcut = state.context.shortcut
  return shortcut?.label
}
