import {
  Player,
  intervalToFrequencyRatio,
  Time,
  Context as AudioContext
} from 'tone'
import { InstrumentZone, Sample, PresetGeneratorMap } from 'midi-city-api'
import getPresetGeneratorMidiNoteSampleDetune from '../get-detune'
import { MidiNote } from 'midi-city-shared-types'
import { getInstrumentZoneLoopPoints } from '../../../../instrument-zone/utils/get-loop-points'

export function instrumentZoneMidiNotePlayerCreate(
  buffer: AudioBuffer,
  instrumentZone: InstrumentZone,
  presetZone: PresetGeneratorMap,
  midiNote: MidiNote,
  sample: Sample,
  isPercussion: boolean,
  context: AudioContext
): Player {
  const detune = getPresetGeneratorMidiNoteSampleDetune(
    presetZone,
    instrumentZone,
    midiNote,
    sample,
    isPercussion
  )

  const { loopStart, loopEnd } = getInstrumentZoneLoopPoints(
    instrumentZone,
    sample
  )

  const playbackRate = intervalToFrequencyRatio(detune / 100)

  const player = new Player({
    url: buffer,
    playbackRate,
    context,
    autostart: false
  })

  if (!player.buffer.loaded) {
    throw new Error('Attempted to Create Midi Note Player With Unloaded Buffer')
  }

  if (instrumentZone.sampleMode === 'loop') {
    // it appears that some browsers can do some bad rounding and choose a loop
    // point past the max
    // an additional small value is added because there some sort of precision lost
    // see https://sentry.io/organizations/astral-digital/issues/1720867013/?project=1524574
    const bufferSeconds = Time(buffer.length, 'samples').toSeconds() - 0.000001
    const loopEndMax = bufferSeconds
    const loopEndActual = Math.min(loopEnd, loopEndMax)

    // somehow some loopStarts can end up being after the end of the sample
    const loopStartActual = Math.min(loopStart, loopEndActual)

    player.loopStart = loopStartActual
    player.loopEnd = loopEndActual
    player.loop = true
  }

  return player
}
