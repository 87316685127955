import { AmplitudeEnvelope, Context as AudioContext } from 'tone'

import { InstrumentZone, PresetGeneratorMap } from 'midi-city-api'

import { createEnvelope } from '../../../../../instrument-zone'
import stackValues from '../../../../../stack-values'

export function presetZoneCreateEnvelope(
  presetZone: PresetGeneratorMap,
  zone: InstrumentZone,
  context: AudioContext
): AmplitudeEnvelope {
  const attackVolEnv = stackValues('attackVolEnv', presetZone, zone)
  const decayVolEnv = stackValues('decayVolEnv', presetZone, zone)
  const sustainVolEnv = stackValues('sustainVolEnv', presetZone, zone)
  const releaseVolEnv = stackValues('releaseVolEnv', presetZone, zone)

  return createEnvelope({
    attackVolEnv,
    decayVolEnv,
    sustainVolEnv,
    releaseVolEnv,
    context
  })
}
