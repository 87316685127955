import { times } from 'lodash-es'
import { assertIsObject, assertIsNumber } from 'assertate'
import { logAction, logState, unexpectedAction } from 'midi-city-xstate-utils'
import { Machine, spawn, assign, sendParent } from 'xstate'

import { KeyboardKeyNumber } from 'midi-city-shared-types'

import keyboardKeyMachine, {
  KeyboardKeyMachineActor
} from '../keyboard-key-machine'

import keyboardGestureManagerMachine, {
  KeyboardGestureManagerMachineActor
} from '../keyboard-gesture-manager-machine'

import * as selectors from '../selectors/keyboard'
import {
  KeyboardMachineContext,
  KeyboardMachineSchema,
  KeyboardMachineState
} from './types'
import * as events from './events'
import { getKeyboardWidth } from './utils'

export const KEYBOARD_MACHINE_SHORTCUT_UPDATE_DEBOUNCE_TIME = 100
export * from './types'

const keyboardMachine = Machine<
  KeyboardMachineContext,
  KeyboardMachineSchema,
  events.KeyboardMachineEvent
>(
  {
    id: 'keyboard-machine',
    initial: 'uninitialized',
    strict: true,
    context: {
      keys: [],
      scrollX: 0
    },
    on: {
      '*': {
        actions: [unexpectedAction]
      }
    },

    states: {
      uninitialized: {
        on: {
          INITIALIZE: {
            actions: [
              assign({
                shortcutManager: (_ctx, event) => event.shortcutManager,
                layoutStyle: (_ctx, event) => event.layoutStyle,
                numKeys: (_ctx, event) => event.numKeys,
                layout: (_ctx, event) => event.layout,
                position: (_ctx, event) => event.position,
                keyVisibleStartInitial: (_ctx, event) =>
                  event.keyVisibleStartInitial
              })
            ],
            target: 'initializing'
          }
        }
      },

      initializing: {
        entry: [
          'layoutsUpdate',
          'keysInit',
          assign({
            scrollX: context => {
              const statePartial = { context }
              const scrollXInitial = selectors.getKeyboardScrollXInitial(
                statePartial as KeyboardMachineState
              )
              return scrollXInitial
            }
          })
        ],
        always: {
          target: 'initialized'
        }
      },

      initialized: {
        initial: 'idle',
        states: {
          idle: {
            entry: ['shortcutManagerUpdateRequest']
          },
          pendingShortcutUpdate: {
            entry: [logState],
            after: { [KEYBOARD_MACHINE_SHORTCUT_UPDATE_DEBOUNCE_TIME]: 'idle' }
          }
        },
        on: {
          KEYBOARD_KEY_ON: {
            actions: sendParent(
              (
                _ctx: KeyboardMachineContext,
                event: events.KeyboardMachineEventKeyOn
              ) => ({
                type: 'KEYBOARD_KEY_ON',
                keyNumber: event.keyNumber
              })
            )
          },

          KEYBOARD_KEY_OFF: {
            actions: sendParent(
              (
                _ctx: KeyboardMachineContext,
                event: events.KeyboardMachineEventKeyOff
              ) => ({
                type: 'KEYBOARD_KEY_OFF',
                keyNumber: event.keyNumber
              })
            )
          },

          KEYBOARD_KEY_ON_REQUEST: {
            actions: (
              _ctx: KeyboardMachineContext,
              { keyNumber },
              meta
            ): void => {
              const { state } = meta as { state: KeyboardMachineState }
              const key = selectors.getKeyboardKey(keyNumber, state)
              key.send({ type: 'KEYBOARD_KEY_ON' })
            }
          },

          KEYBOARD_KEY_OFF_REQUEST: {
            actions: (ctx: KeyboardMachineContext, { keyNumber }): void => {
              const key = ctx.keys.find(
                keyRef => keyRef.state.context.number === keyNumber
              )
              assertIsObject(key)
              key.send({ type: 'KEYBOARD_KEY_OFF' })
            }
          },

          KEYBOARD_SCROLL_X_UPDATE: {
            actions: [
              assign({
                scrollX: (_ctx, { value }) => value
              }),
              logAction
            ],
            target: '.pendingShortcutUpdate',
            internal: false
          },

          LAYOUT_UPDATE_REQUEST: {
            actions: [
              assign({
                layout: (_ctx, { value }) => value
              }),
              'layoutsUpdate',
              ({ keys, keysWrapperLayout }, _event): void => {
                assertIsObject(keysWrapperLayout)
                keys.forEach(key =>
                  key.send({
                    type: 'KEYBOARD_LAYOUT_CHANGED',
                    value: keysWrapperLayout
                  })
                )
              },
              'shortcutManagerUpdateRequest'
            ]
          },

          LAYOUT_STYLE_UPDATE_REQUEST: {
            actions: [
              logAction,
              assign({
                layoutStyle: (_ctx, { value }) => value
              }),
              'layoutsUpdate',
              assign({
                scrollX: ({ scrollX, contentLayout }) => {
                  assertIsObject(contentLayout)
                  // TODO this is a quickfix for when a layout that is wider
                  // like Single, changes to a layout that's thinner, like Double
                  // and the scrollX is in a place that won't exist in the new
                  // layout. `100` is a number that worked
                  return Math.min(scrollX, contentLayout.width - 100)
                }
              }),
              ({ keys }, event): void =>
                keys.forEach(key =>
                  key.send({
                    type: 'KEYBOARD_LAYOUT_STYLE_CHANGED',
                    value: event.value
                  })
                ),
              'shortcutManagerUpdateRequest',
              sendParent(
                (): events.KeyboardMachineEmittedEventLayoutStyleChanged => ({
                  type: 'KEYBOARD_LAYOUT_STYLE_CHANGED'
                })
              )
            ]
          },

          FORCE_KEY_ON: {
            actions: [logAction, 'keyFwdForceOn']
          },

          FORCE_KEY_OFF: {
            actions: [logAction, 'keyFwdForceOff']
          }
        }
      }
    }
  },
  {
    actions: {
      layoutsUpdate: assign({
        keysWrapperLayout: ({ layout }) => {
          assertIsObject(layout)
          return {
            ...layout,
            height: layout.height - 48
          }
        },
        contentLayout: ({ numKeys, layout, layoutStyle }) => {
          assertIsObject(layout)
          assertIsNumber(numKeys)
          assertIsNumber(layoutStyle)
          return {
            ...layout,
            height: layout.height - 48,
            width: getKeyboardWidth(numKeys, layout, layoutStyle)
          }
        }
      }),

      shortcutManagerUpdateRequest: ({ shortcutManager }): void => {
        assertIsObject(shortcutManager)
        shortcutManager.send({
          type: 'SHORTCUT_MANAGER_UPDATE_REQUEST'
        })
      },

      keyFwdForceOn: (_ctx, event, meta): void => {
        const { state } = meta as { state: KeyboardMachineState }
        const { keyNumber } = event as events.KeyboardMachineEventForceKeyOn
        const key = selectors.getKeyboardKey(keyNumber, state)
        key.send({ type: 'FORCE_ON' })
      },

      keyFwdForceOff: (_ctx, event, meta): void => {
        const { state } = meta as { state: KeyboardMachineState }
        const { keyNumber } = event as events.KeyboardMachineEventForceKeyOff
        const key = selectors.getKeyboardKey(keyNumber, state)
        key.send({ type: 'FORCE_OFF' })
      },

      keysInit: assign({
        gestureManager: () =>
          spawn(
            keyboardGestureManagerMachine.withContext({})
          ) as KeyboardGestureManagerMachineActor,
        keys: (
          { numKeys, layoutStyle, keysWrapperLayout }: KeyboardMachineContext,
          _event
        ) => {
          assertIsNumber(numKeys)
          assertIsNumber(layoutStyle)
          assertIsObject(keysWrapperLayout)
          return times(numKeys).map(index => {
            return spawn(
              keyboardKeyMachine.withContext({
                number: index as KeyboardKeyNumber,
                numKeys,
                keyboardLayoutStyle: layoutStyle,
                keyboardLayout: keysWrapperLayout
              })
            ) as KeyboardKeyMachineActor
          })
        }
      })
    }
  }
)

export default keyboardMachine
