export function timeCentsToSeconds(value: number | null): number {
  const zeroVal = -32768
  value = value !== null ? value : zeroVal
  return Math.pow(2, value / 1200)
}

// some code taken from https://github.com/JorenSix/TarsosDSP/blob/master/src/core/be/tarsos/dsp/util/PitchConverter.java
const REF_FREQ = 8.17579892

export function centsToFreq(value: number): number {
  return REF_FREQ * timeCentsToSeconds(value)
}
