import { Instrument, InstrumentZone } from 'midi-city-api'
import getGlobalZone from './get-global-zone'
import { without } from 'lodash-es'
import { Brand } from 'utility-types'

type NonGlobalZones = Brand<InstrumentZone[], 'NonGlobalZones'>

export function getNonGlobalZones(instrument: Instrument): NonGlobalZones {
  return without(instrument.zones, getGlobalZone(instrument)) as NonGlobalZones
}
