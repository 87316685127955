import { Machine, sendParent } from 'xstate'

import { VELOCITY_SUPPORTED } from 'midi-city-shared-types'

import { Context, Schema, Events } from './types'

import { Events as AudioEnvironmentEvents } from 'midi-city-sound-engine/src/machines/audio-environment/types'
import { unexpectedAction } from 'midi-city-xstate-utils'

export * from './types'

const machine = Machine<Context, Schema, Events.All>(
  {
    id: 'midi-device-machine',

    initial: 'uninitialized',

    strict: true,

    on: {
      '*': {
        actions: unexpectedAction
      }
    },

    states: {
      uninitialized: {
        always: {
          target: 'initialized'
        }
      },

      initialized: {
        on: {
          MIDI_DEVICE_CHANGE_PRESET: {
            actions: ['presetChangeFwd']
          },

          MIDI_DEVICE_NOTE_START_REQUEST: {
            actions: ['noteStartReqFwd']
          },

          MIDI_DEVICE_NOTE_RELEASE_REQUEST: {
            actions: ['noteReleaseReqFwd']
          }
        }
      }
    }
  },
  {
    actions: {
      noteStartReqFwd: sendParent(
        ({ source }, event): AudioEnvironmentEvents.ChannelNoteStart => {
          const {
            note,
            channelNumber,
            velocity
          } = event as Events.NoteStartRequest

          return {
            type: 'CHANNEL_NOTE_START_REQUEST',
            channelNumber,
            midiNote: note,
            velocity: velocity ?? VELOCITY_SUPPORTED,
            source
          }
        }
      ),

      noteReleaseReqFwd: sendParent(
        ({ source }, event): AudioEnvironmentEvents.ChannelNoteRelease => {
          const { note, channelNumber } = event as Events.NoteReleaseRequest

          return {
            type: 'CHANNEL_NOTE_RELEASE_REQUEST',
            channelNumber,
            midiNote: note,
            source
          }
        }
      ),

      presetChangeFwd: sendParent(
        (_ctx, event): AudioEnvironmentEvents.ChannelPresetChangeRequest => {
          const {
            presetNumber,
            channelNumber,
            bankId
          } = event as Events.PresetChange

          return {
            type: 'CHANNEL_PRESET_CHANGE_REQUEST',
            channelNumber,
            presetNumber,
            bankId
          }
        }
      )
    }
  }
)

export { machine }
