import moize from 'moize'

import { KeyboardKeyNumber, KeyboardPosition } from 'midi-city-shared-types'

import {
  getKeyRow,
  getKeyRowSlot,
  PhysicalKeyboardKeyMapping,
  getKeyboardPhysicalKeyRows,
  KeyboardLayoutMapping,
  KeyboardLayoutStyle
} from './index'

const ROW_0_OFFSET_MAP = new Map<number, KeyboardLayoutStyle>([
  [0, 0],
  [1, 1],
  [2, 1]
])

export const getKeyboardKeyShortcut = (
  keyboardPosition: KeyboardPosition,
  keyNumber: KeyboardKeyNumber,
  visibleKeyboardLayoutMapping: KeyboardLayoutMapping,
  numKeys: number,
  keyVisibleStart: KeyboardKeyNumber
): PhysicalKeyboardKeyMapping | undefined => {
  if (
    visibleKeyboardLayoutMapping[0] === KeyboardLayoutStyle.Quad &&
    keyboardPosition !== 0
  ) {
    return undefined
  }

  const keyboardLayout = visibleKeyboardLayoutMapping[keyboardPosition]
  const rowInternal = getKeyRow(keyNumber, keyboardLayout)

  const rowSlot = getKeyRowSlot(keyNumber, numKeys, keyboardLayout)

  const slotOffsetStart = getKeyRowSlot(
    keyVisibleStart,
    numKeys,
    keyboardLayout
  )

  const row0Offset = ROW_0_OFFSET_MAP.get(keyboardLayout)

  if (row0Offset === undefined) {
    return undefined
  }

  const slotOffsetRow = rowInternal === 0 ? row0Offset : 0

  const rowKeyBank = getRowKeyBank(
    rowInternal,
    keyboardPosition,
    visibleKeyboardLayoutMapping
  )

  const bankIndex = rowSlot - slotOffsetStart + slotOffsetRow

  if (rowKeyBank !== undefined && bankIndex >= 0) {
    return rowKeyBank[bankIndex]
  } else {
    return undefined
  }
}

const getRowKeyBank = moize(
  (
    row: number,
    keyboardPosition: KeyboardPosition,
    visibleKeyboardLayoutMapping: KeyboardLayoutMapping
  ): PhysicalKeyboardKeyMapping[] => {
    const physicalRows = getKeyboardPhysicalKeyRows(
      keyboardPosition,
      visibleKeyboardLayoutMapping
    )

    if (physicalRows.length === 0) {
      return []
    }

    const keyboardLayout = visibleKeyboardLayoutMapping[keyboardPosition]

    return keyboardLayout === KeyboardLayoutStyle.Quad
      ? [...physicalRows[row], ...physicalRows[row + 2]]
      : physicalRows[row]
  }
)

export default getKeyboardKeyShortcut
