import {
  PhysicalKeyboardKeyMapping,
  getPhysicalRowsToBeUsed,
  KEYBOARD_LAYOUT_PHYSICAL_ROW_COUNT,
  KeyboardLayoutMapping
} from './index'
import { KeyboardLayoutStyle, KeyboardPosition } from 'midi-city-shared-types'

const getKeyboardRowsAlreadyUsedCount = (
  keyboardPosition: KeyboardPosition,
  visibleKeyboardLayoutMapping: KeyboardLayoutMapping
): number => {
  const rowsToBeUsed = getPhysicalRowsToBeUsed(visibleKeyboardLayoutMapping)
  const keyboardLayout = visibleKeyboardLayoutMapping[keyboardPosition]
  const layoutsVisible = Object.values(visibleKeyboardLayoutMapping)

  if (
    keyboardLayout !== KeyboardLayoutStyle.Quad &&
    layoutsVisible.includes(KeyboardLayoutStyle.Quad)
  ) {
    return rowsToBeUsed.length
  } else if (
    layoutsVisible.length === 1 ||
    keyboardLayout === KeyboardLayoutStyle.Quad ||
    keyboardPosition === 0
  ) {
    return 0
  } else {
    return getKeyboardPhysicalKeyRows(
      0 as KeyboardPosition,
      visibleKeyboardLayoutMapping
    ).length
  }
}

const getKeyLayoutRows = (
  keyLayout: KeyboardLayoutStyle,
  rowsToBeUsed: PhysicalKeyboardKeyMapping[][],
  rowsAlreadyUsed: number
): PhysicalKeyboardKeyMapping[][] => {
  const physicalRowsNeededKeyboard = KEYBOARD_LAYOUT_PHYSICAL_ROW_COUNT.get(
    keyLayout
  )

  if (physicalRowsNeededKeyboard === undefined) {
    throw new Error('mapping error')
  }

  const rowStart = rowsAlreadyUsed

  return rowsToBeUsed.slice(rowStart, rowStart + physicalRowsNeededKeyboard)
}

const getKeyboardPhysicalKeyRows = (
  keyboardPosition: KeyboardPosition,
  visibleKeyboardLayoutMapping: KeyboardLayoutMapping
): PhysicalKeyboardKeyMapping[][] => {
  const rowsToBeUsed = getPhysicalRowsToBeUsed(visibleKeyboardLayoutMapping)
  const rowsAlreadyUsedCount = getKeyboardRowsAlreadyUsedCount(
    keyboardPosition,
    visibleKeyboardLayoutMapping
  )

  if (rowsAlreadyUsedCount >= rowsToBeUsed.length) {
    return []
  }

  return getKeyLayoutRows(
    visibleKeyboardLayoutMapping[keyboardPosition],
    rowsToBeUsed,
    rowsAlreadyUsedCount
  )
}

export default getKeyboardPhysicalKeyRows
